import React from 'react';

const Magic = () => (
  <svg
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      id="magic_36pt_a"
      gradientTransform="matrix(.99997313 -.00733032 .00733032 .99997313 .2057 .1312)"
      gradientUnits="userSpaceOnUse"
      x1="6.4908"
      x2="29.231"
      y1="5.591"
      y2="30.8465"
    >
      <stop offset="0" stopColor="#fff" stopOpacity="0" />
      <stop offset="1" stopColor="#fff" stopOpacity=".2" />
    </linearGradient>
    <circle cx="18" cy="18" fill="#8e041a" r="18" />
    <circle cx="18" cy="18" fill="url(#magic_36pt_a)" r="17" />
    <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="m10.9441 23.061 12.3778-12.3389a.6912.6912 0 0 1 .9957.02l1.4579 1.5767a.6913.6913 0 0 1 -.0162.9556l-12.3071 12.4377a.6913.6913 0 0 1 -1.0021-.02l-1.5286-1.6751a.6914.6914 0 0 1 .0226-.956z" />
      <path d="m19.8478 14.1852 2.4702 2.5673" />
      <path d="m12.22 12.434a1.27 1.27 0 0 1 -1.129 1.4646" />
      <path d="m12.5553 15.0265a1.2693 1.2693 0 0 1 1.1281-1.4635" />
      <path d="m12.5554 15.0275a1.27 1.27 0 0 0 -1.4646-1.1289" />
      <path d="m12.22 12.4352a1.269 1.269 0 0 0 1.4633 1.1279" />
      <rect height=".875" rx=".4375" width=".875" x="17.0375" y="10.2256" />
      <rect height=".875" rx=".4375" width=".875" x="22.9344" y="21.4485" />
    </g>
  </svg>
);

export default Magic;
