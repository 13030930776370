import { Footer } from '@vivino/js-web-common';
import React from 'react';
import Page from 'vivino-js/utilities/Page/Page';

interface AppProps {
  countryCode: string;
  stateCode?: string;
  isVCCountry: boolean;
  translations: Record<string, string>;
}

export default ({ countryCode, stateCode, isVCCountry, translations }: AppProps) => {
  return (
    <Page translations={translations}>
      <Footer countryCode={countryCode} stateCode={stateCode} isVCCountry={isVCCountry} />
    </Page>
  );
};
