import { ActionButton, onLoginRequired } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { track } from 'vivino-js/analytics';
import { createLike, destroyLike } from 'vivino-js/api/likes';
import { isSignedIn } from 'vivino-js/helpers/user';
import { LikeOffIcon, LikeOnIcon } from 'vivino-ui/icons/LikeIcons/20';

import CommunityReviewsTrackingContext from 'vivino-ui/components/CommunityReviews/CommunityReviewsTrackingContext';

import styles from './likeButton.scss';

const LikeButton = ({ activity, className }) => {
  const trackedFrom = useContext(CommunityReviewsTrackingContext);
  const [likeId, setLikeId] = useState(activity?.user_context?.like_id || null);
  const [likeCount, setLikeCount] = useState(activity?.statistics?.likes_count);
  const isLiked = !!likeId;

  const onLike = async () => {
    if (!isSignedIn()) {
      onLoginRequired();
      return;
    }

    const activityId = activity.id;

    if (isLiked) {
      await destroyLike({ activityId, likeId });
      track({
        event: 'User review - unlike comment',
        props: { trackedFrom },
      });

      setLikeId(null);
      setLikeCount(likeCount - 1);
    } else {
      const { like } = await createLike({ activityId });

      track({
        event: 'User review - like comment',
        props: { trackedFrom },
      });

      setLikeId(like.id);
      setLikeCount(likeCount + 1);
    }
  };

  return (
    <ActionButton
      className={cx(className, styles.likeButton, { [styles.active]: isLiked })}
      ariaLabel={isLiked ? 'Unlike review' : 'Like review'}
      ariaPressed={isLiked}
      onClick={onLike}
    >
      <div className={styles.icon}>
        <LikeOnIcon className={cx(styles.liked, { [styles.active]: isLiked })} />
        <LikeOffIcon className={cx(styles.unliked, { [styles.active]: !isLiked })} />
      </div>
      <div className={styles.likeCount}>{likeCount}</div>
    </ActionButton>
  );
};

LikeButton.propTypes = {
  className: PropTypes.string,
  activity: PropTypes.object,
  onLike: PropTypes.func,
};

export default LikeButton;
