export function ceilToNearestPrecision({ value, precision }) {
  return precision * Math.ceil(value / precision);
}

export function percentage({ top, bottom }) {
  return Math.round((top / bottom) * 100);
}

export function parseIntFromString(stringValue, defaultResult = null) {
  if (!stringValue) {
    return defaultResult;
  }

  const parsedNumber = parseInt(stringValue);

  if (isNaN(parsedNumber)) {
    return defaultResult;
  }

  return parsedNumber;
}
