import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { CHECKOUT_TRACKING_EVENTS, CHECKOUT_TRACKING_KEYS } from 'vivino-checkout/helpers/tracking';
import { track } from 'vivino-js/analytics';
import { TrackingContext, TrackingProvider } from 'vivino-js/context/TrackingContext';

const getDefaultTrackingProps = () => {
  const purchaseOrderCount = window.__PRELOADED_STATE__?.purchaseOrderCount;
  const ageVerification = window.__PRELOADED_STATE__?.ageVerification || {};
  const alreadyVerified = ageVerification.eid_verified;
  const canCheckout = ageVerification.can_checkout;
  const identityProvider = ageVerification.identity_provider;
  const shippingCountry = window.__PRELOADED_STATE__?.cart?.shipping_country;

  const searchParams = new URLSearchParams(window.location.search);
  const ageVerified = searchParams.get('age_verified');
  const successfullyVerified = ageVerified === 'true';

  const props = {};

  if (purchaseOrderCount !== undefined) {
    props[CHECKOUT_TRACKING_KEYS.IS_EXISTING_CUSTOMER] = purchaseOrderCount > 0;
  }

  if (alreadyVerified !== undefined) {
    props[CHECKOUT_TRACKING_KEYS.ALREADY_VERIFIED] = alreadyVerified;
  }

  if (canCheckout !== undefined) {
    props[CHECKOUT_TRACKING_KEYS.CAN_CHECKOUT] = canCheckout;
  }

  if (ageVerified !== null) {
    props[CHECKOUT_TRACKING_KEYS.SUCCESSFULLY_VERIFIED] = successfullyVerified;
  }

  if (identityProvider !== undefined) {
    props[CHECKOUT_TRACKING_KEYS.IDENTITY_PROVIDER] = identityProvider;
  }

  if (shippingCountry !== undefined) {
    props[CHECKOUT_TRACKING_KEYS.SHIPPING_COUNTRY] = shippingCountry;
  }

  return props;
};

export const CheckoutTrackingContext = TrackingContext;

const getScreenFromStep = (step) => `Checkout - ${step}`;

export const trackCheckout = ({ step, event, props = {} }) => {
  track({
    screen: getScreenFromStep(step),
    event,
    props: {
      ...getDefaultTrackingProps(),
      ...props,
    },
  });
};

export const CheckoutTrackingProvider = ({ children, step = '' }) => {
  return (
    <TrackingProvider
      screen={getScreenFromStep(step)}
      defaultTrackingProps={getDefaultTrackingProps()}
      trackCheckout={({ event, props }) => trackCheckout({ step, event, props })}
    >
      {children}
    </TrackingProvider>
  );
};

CheckoutTrackingProvider.propTypes = {
  children: PropTypes.node,
  step: PropTypes.string,
};

export const trackCheckoutStepShow = (stepTrackingName, props) => {
  track({
    screen: getScreenFromStep(stepTrackingName),
    event: CHECKOUT_TRACKING_EVENTS.SHOW,
    props: {
      ...getDefaultTrackingProps(),
      ...props,
    },
  });
};

export const useCheckoutTrackShowStep = (props) => {
  const { trackCheckout } = useContext(CheckoutTrackingContext);

  useEffect(() => {
    trackCheckout({
      event: CHECKOUT_TRACKING_EVENTS.SHOW,
      props,
    });
  }, []);
};
