import { individualWineStyleAwardsUrl, toplistUrl } from 'vivino-js/vivinoUrls';

export const HighlightTypes = {
  AwardWinning: 'award_winning',
  BestUserRated: 'best_user_rated',
  CheapestAvailable: 'cheapest_available',
  CriticFavorite: 'critic_favorite',
  LatestAvailable: 'latest_available',
  MostUserRated: 'most_user_rated',
  OldestAvailable: 'oldest_available',
  PriceDiscounted: 'price_discounted',
  PriceQpr: 'price_qpr',
  TopListed: 'top_listed',
  TopRanked: 'top_ranked',
  WsaWinning: 'wsa_winning',
};

export const SuperCardsHighlightTypes = {
  MLRecommendation: 'ml_recommendation',
  FavoriteRegionalStyle: 'favorite_regional_style',
  BestFromYourWishList: 'best_from_wishlist',
};

export const PRICE_DISCOUNTED_TYPE = HighlightTypes.PriceDiscounted;
export const CRITIC_FAVORITE_TYPE = HighlightTypes.CriticFavorite;

export const getFilteredHighlights = ({ highlights, filters = [] }) =>
  (highlights || []).filter((highlight) => !filters.includes(highlight.highlight_type));

export const getHighlightUrl = (highlight) => {
  if (highlight?.highlight_type === HighlightTypes.TopListed) {
    return toplistUrl(highlight);
  }

  if (highlight?.highlight_type === HighlightTypes.WsaWinning) {
    return individualWineStyleAwardsUrl({
      wsaSeoName: highlight?.metadata?.ws_award?.seo_name,
    });
  }

  return null;
};
