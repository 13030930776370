import { ComponentSize } from '@webtypes/common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import WineInfoLocation from 'vivino-ui/components/WineInfoLocation';
import WineInfoVintage from 'vivino-ui/components/WineInfoVintage';

import styles from './wineInfo.scss';

const WineInfo = ({
  vintage,
  vintageSize = ComponentSize.Base,
  locationSize = ComponentSize.Base,
  isMasterWineCard = false,
}) => (
  <div className={cx(styles.wineInfo)}>
    <WineInfoVintage
      vintage={vintage}
      size={vintageSize}
      className={styles.vintage}
      isMasterWineCard={isMasterWineCard}
    />
    <WineInfoLocation vintage={vintage} size={locationSize} />
  </div>
);

WineInfo.propTypes = {
  vintage: PropTypes.object,
  vintageSize: PropTypes.string,
  locationSize: PropTypes.string,
  isMasterWineCard: PropTypes.bool,
};

export default WineInfo;
