import { AutocompleteCloseReason, Box, Paper } from '@mui/material';
import { Autocomplete, TextField } from '@vivino/js-react-common-ui';
import { Chevron, ChevronDirections, HighlightedText } from '@vivino/js-web-common';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { AddressAutocompleteValue, AutocompleteFinder } from 'vivino-js/api/address';
import t from 'vivino-js/translationString';

import styles from './addressFinder.scss';

interface AddressFinderPresentationalProps {
  inputValue: string;
  showInstruction?: boolean;
  value: AddressAutocompleteValue;
  options?: AddressAutocompleteValue[];
  autocompleteFinder: AutocompleteFinder;
  inputRef?: React.MutableRefObject<HTMLElement> | null;
  onChange: (event: ChangeEvent, value: AddressAutocompleteValue, reason?: string) => void;
  onInputChange: (event: ChangeEvent, value: string, reason: string) => void;
  inputProps?: Record<string, unknown>;
}

const TRANSLATION_PATH = 'components.shared.address_finder';

const TRANSLATIONS = {
  keepTypingForMoreResults: `${TRANSLATION_PATH}.keep_typing_for_more_results`,
  noMatchesFound: `${TRANSLATION_PATH}.no_matches_found`,
  searchDeliveryAddress: `${TRANSLATION_PATH}.search_delivery_address`,
  typeYourPostcode: `${TRANSLATION_PATH}.type_your_postcode_street_or_address`,
  ourAddressFinder: `${TRANSLATION_PATH}.our_address_finder_helps`,
  enterAddress: `${TRANSLATION_PATH}.enter_the_address_yourself`,
};

const AddressFinder = ({
  value,
  options,
  inputValue,
  inputProps,
  autocompleteFinder,
  onChange,
  onInputChange,
}: AddressFinderPresentationalProps) => {
  const inputRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (value && !value?.address_id) {
      // address_id indicates that the address is complete.
      // In case the address is not complete we refocus the input to trigger reopening the popup
      inputRef.current?.focus();
    }
  }, [value?.display_text]);

  const noOptionsText = t(
    inputValue ? TRANSLATIONS.noMatchesFound : TRANSLATIONS.keepTypingForMoreResults
  );

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = (_event: ChangeEvent, reason: AutocompleteCloseReason) => {
    /*
     * Close popup for "escape", "selectOption", "removeOption", "blur",
     * but not for "toggleInput" (drilldown)
     * see onClose section in https://mui.com/api/autocomplete/
     */
    if ('toggleInput' !== reason) {
      setIsOpen(false);
    }
  };

  const handleOptionSelect = (event: any) => {
    if (value?.address_id && event.key === 'Enter') {
      setIsOpen(false);
    }
  };

  const footer = (autocompleteFinder) => {
    if (autocompleteFinder?.service_logo) {
      return (
        <div className={styles.poweredByContainer}>
          <img
            src={autocompleteFinder.service_logo}
            alt={autocompleteFinder.service}
            className={styles.poweredByImage}
          />
        </div>
      );
    }

    if (autocompleteFinder?.service) {
      return (
        <div className={styles.poweredByContainer}>
          <div>{autocompleteFinder.service}</div>
        </div>
      );
    }

    return null;
  };

  if (!autocompleteFinder) {
    return (
      <TextField
        fullWidth
        size="medium"
        variant="filled"
        inputRef={inputRef}
        label={inputProps?.label}
        id={String(inputProps?.id)}
        {...inputProps}
      />
    );
  }

  return (
    <>
      <Autocomplete
        openOnFocus
        disablePortal
        disableCloseOnSelect
        options={options}
        // @ts-ignore ignore   since type definition is wrong
        value={value}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        inputValue={inputValue}
        filterOptions={(x) => x}
        onInputChange={onInputChange}
        // @ts-ignore ignore since type definition is wrong
        noOptionsText={noOptionsText}
        // @ts-ignore ignore since type definition is wrong
        onChange={onChange}
        onKeyDown={handleOptionSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="medium"
            variant="filled"
            inputRef={inputRef}
            label={inputProps?.label}
            inputProps={{
              ...params.inputProps,
              autoComplete: inputProps?.autoComplete,
            }}
            {...inputProps}
          />
        )}
        getOptionLabel={(option: any) => option.display_text || ''}
        renderOption={(props, option) => (
          <Box
            justifyContent={'space-between'}
            component="li"
            {...props}
            onClick={(e) => {
              if (option?.address_id) {
                setIsOpen(false);
              }
              props.onClick(e);
            }}
          >
            <div className={styles.option}>
              <HighlightedText
                fullText={option.display_text}
                highlight={inputValue}
                highlightClassName={styles.highlight}
                dataTestId={option.display_text}
              />
              {!option?.address_id && <Chevron direction={ChevronDirections.RIGHT} />}
            </div>
          </Box>
        )}
        PaperComponent={(props) => (
          <Paper {...props}>
            {props.children}
            <Box paddingRight={1} paddingBottom={1}>
              {footer(autocompleteFinder)}
            </Box>
          </Paper>
        )}
      />
    </>
  );
};

export default AddressFinder;
