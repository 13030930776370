import { Button, ButtonTheme } from '@vivino/js-react-common-ui';
import { useI18N, vivinoPremiumGiftBuyUrl } from '@vivino/js-web-common';
import React from 'react';

import { usePremiumContext } from '../../PremiumContext/PremiumContext';

export enum UserAction {
  GIFT_MEMBERSHIP = 'Gift membership',
  EXPAND_FAQ = 'Expand FAQ',
  BUY_ANOTHER_GIFT = 'Buy another gift',
  CONTINUE = 'Continue',
  REDEEM_CODE = 'Redeem code',
  JOIN_PREMIUM = 'Join premium',
  SHOW_GIFT = 'Show gift page',
}

const actionUrls = {
  [UserAction.GIFT_MEMBERSHIP]: vivinoPremiumGiftBuyUrl(),
  [UserAction.SHOW_GIFT]: '/premium/gift',
  [UserAction.REDEEM_CODE]: '/premium/code',
  [UserAction.JOIN_PREMIUM]: '/premium/signup',
};

const actionTexts = {
  [UserAction.GIFT_MEMBERSHIP]: 'components.premium_page.cta.gift_a_membership',
  [UserAction.REDEEM_CODE]: 'components.premium_page.cta.redeem_code',
  [UserAction.JOIN_PREMIUM]: 'components.premium_page.cta.join_premium',
  [UserAction.SHOW_GIFT]: 'components.premium_page.cta.gift_premium',
};

export interface CTAButtonProps {
  action: UserAction;
  className?: string;
  onClick?: () => void;
  textButton?: boolean;
  loading?: boolean;
}

const CTAButton = ({ action, className, onClick, textButton = false, loading }: CTAButtonProps) => {
  const { t } = useI18N();
  const { trackAction } = usePremiumContext();
  const url = actionUrls[action];

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    trackAction(action);
  };

  return (
    <Button
      href={url}
      size="large"
      working={loading}
      className={className}
      onClick={handleOnClick}
      dataTestId={t(actionTexts[action])}
      theme={textButton ? ButtonTheme.PlainQuiet : ButtonTheme.Default}
    >
      {t(actionTexts[action])}
    </Button>
  );
};

export default CTAButton;
