export {
  VIEW_PAGE,
  WINE_PREFERENCE_SCREEN_NAME,
  Events,
  trackEvent,
  track,
  trackMasterWinePageView,
  trackVintageOrOffersPageView,
  trackAddToCart,
  trackInitiateCheckout,
  trackPurchase,
  trackPersonalizedUpsellFallBackEvent,
  getTrackingScreen,
  TRACKING_SCREENS,
  trackMagicLinkLogin,
} from '@vivino/js-web-common';
