import { Currency } from '@webtypes/goApi';

export enum CurrencyCodes {
  BRITISH_POUND = 'GBP',
  DANISH_KRONE = 'DKK',
  JAPANESE_YEN = 'JPY',
  US_DOLLAR = 'USD',
}

const ZERO_DECIMAL_CURRENCY_CODES = [CurrencyCodes.JAPANESE_YEN];

/**
 * Helps us abide to a stripe specific requirement
 * https://stripe.com/docs/currencies#zero-decimal
 */
export const isZeroDecimalCurrency = (currency: Currency) => {
  const upperCaseCurrency = currency?.code?.toUpperCase();

  if (!upperCaseCurrency) {
    return false;
  }

  return ZERO_DECIMAL_CURRENCY_CODES.some((currency) => currency === upperCaseCurrency);
};
