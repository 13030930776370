import PropTypes from 'prop-types';

export const currency = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
});

export const country = PropTypes.shape({
  code: PropTypes.string,
  currency: currency,
  most_used_grapes: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  native_name: PropTypes.string,
  seo_name: PropTypes.string,
  regions_count: PropTypes.number,
  users_count: PropTypes.number,
  wines_count: PropTypes.number,
  wineries_count: PropTypes.number,
});

export const price = PropTypes.shape({
  amount: PropTypes.number,
  discounted_from: PropTypes.number,
  currency,
});

export const statistics = PropTypes.shape({
  ratings_count: PropTypes.number.isRequired,
  ratings_average: PropTypes.number.isRequired,
});

export const image = PropTypes.shape({
  location: PropTypes.string,
  variations: PropTypes.shape({
    large: PropTypes.string,
    medium: PropTypes.string,
    small_square: PropTypes.string,
  }),
});

export const user = PropTypes.shape({
  id: PropTypes.number,
  seo_name: PropTypes.string,
  alias: PropTypes.string,
  is_featured: PropTypes.bool,
  visibility: PropTypes.string,
  image,
  statistics: PropTypes.shape({
    followers_count: PropTypes.number,
    followings_count: PropTypes.number,
    ratings_count: PropTypes.number,
    ratings_sum: PropTypes.number,
    reviews_count: PropTypes.number,
  }),
  background_image: PropTypes.object,
});

export const countryManager = PropTypes.shape({
  user: user,
  short_description: PropTypes.string,
  long_description: PropTypes.string,
});

export const region = PropTypes.shape({
  backgound_image: image,
  class: PropTypes.shape({}),
  country,
  id: PropTypes.number,
  name: PropTypes.string,
  name_en: PropTypes.string,
  seo_name: PropTypes.string,
});

export const style = PropTypes.shape({
  id: PropTypes.number,
  seo_name: PropTypes.string,
  regional_name: PropTypes.string,
  varietal_name: PropTypes.string,
  name: PropTypes.string,
  image: image,
  background_image: image,
  description: PropTypes.string,
  blurb: PropTypes.any,
  body: PropTypes.number,
  body_description: PropTypes.string,
  acidity: PropTypes.number,
  acidity_description: PropTypes.string,
  country,
  wine_type_id: PropTypes.number,
  food: PropTypes.arrayOf(PropTypes.shape({})),
  grapes: PropTypes.arrayOf(PropTypes.shape({})),
  region: region,
});

export const wine = PropTypes.shape({
  alcohol: PropTypes.number,
  description: PropTypes.string,
  foods: PropTypes.arrayOf(PropTypes.shape({})),
  grapes: PropTypes.arrayOf(PropTypes.shape({})),
  hidden: PropTypes.bool,
  id: PropTypes.number,
  is_first_wine: PropTypes.bool,
  is_natural: PropTypes.bool,
  name: PropTypes.string,
  non_vintage: PropTypes.bool,
  rank: PropTypes.any, // what data type is rank?
  region: PropTypes.shape({}),
  review_status: PropTypes.number,
  seo_name: PropTypes.string,
  statistics: statistics,
  style: style,
  sweetness_id: PropTypes.number,
  type_id: PropTypes.number,
  year: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  vintage_mask_raw: PropTypes.any, //what is dis?
});

export const vintage = PropTypes.shape({
  id: PropTypes.number.isRequired,
  has_valid_ratings: PropTypes.bool,
  statistics,
  wine,
  image,
});

export const winery = PropTypes.shape({
  address: PropTypes.shape({}),
  background_image: image,
  business_name: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  facebook: PropTypes.string,
  first_wines: PropTypes.arrayOf(vintage),
  id: PropTypes.number,
  image,
  instagram: PropTypes.string,
  is_claimed: PropTypes.bool,
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  name: PropTypes.string,
  phone: PropTypes.string,
  non_vintage: PropTypes.bool,
  region: region,
  review_status: PropTypes.string,
  seo_name: PropTypes.string,
  statistics: PropTypes.shape({}),
  status: PropTypes.number,
  twitter: PropTypes.string,
  website: PropTypes.string,
  wine_maker: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  winemaker: PropTypes.string,
  winery_group: PropTypes.shape({}),
});

export const bottleType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  short_name: PropTypes.string,
  short_name_plural: PropTypes.string,
  volume_ml: PropTypes.number,
});

export const volumes = PropTypes.arrayOf(bottleType);

export const paymentMethod = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  capabilities: PropTypes.array,
});

export const shippingOptions = PropTypes.shape({
  gift_message: PropTypes.bool,
  ice_packs: PropTypes.shape({
    support: PropTypes.number,
    prices: PropTypes.array,
  }),
  instructions: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    support: PropTypes.number,
  }),
  preferred_delivery_date_options: PropTypes.shape({
    cut_off_time: PropTypes.string,
    lead_time: PropTypes.number,
    support: PropTypes.number,
    window: PropTypes.number,
  }),
});

export const merchant = PropTypes.shape({
  id: PropTypes.number.isRequired,
  tos_url: PropTypes.string,
  impressum_url: PropTypes.string,
  shipping_estimate: PropTypes.string,
  name: PropTypes.string,
  seo_name: PropTypes.string,
  legal_name: PropTypes.string,
  description: PropTypes.string,
  country,
  shipping_options: shippingOptions,
  state: PropTypes.string,
  status: PropTypes.number,
  image,
  payment_options: PropTypes.shape({
    methods: PropTypes.arrayOf(paymentMethod),
  }),
  hidden: PropTypes.bool,
});

export const highlight = PropTypes.shape({
  message: PropTypes.string,
  highlight_type: PropTypes.string,
  icon: PropTypes.string,
  vintage_id: PropTypes.number,
  // because of values like "N.V."
  vintage_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const priceRange = PropTypes.shape({
  currency,
  defaults: PropTypes.shape({
    maximum: PropTypes.number.isRequired,
    minimum: PropTypes.number.isRequired,
  }),
  price_range: PropTypes.shape({
    maximum: PropTypes.number.isRequired,
    minimum: PropTypes.number.isRequired,
  }),
});

export const FieldTypes = {
  Email: 'email',
  Numerical: 'numerical',
  List: 'list',
  String: 'string',
  Phone: 'phone',
};

export const FieldKeys = {
  Address: 'address',
  AddressBuilding: 'address_building',
  AddressFull: 'address_full',
  Addition: 'addition',
  Apartment: 'apartment',
  City: 'city',
  Company: 'company',
  Country: 'country',
  County: 'county',
  Email: 'email',
  BlockFloor: 'block_floor',
  District: 'district',
  Fraction: 'fraction',
  FullName: 'full_name',
  Neighborhood: 'neighborhood',
  Phone: 'phone',
  Province: 'province',
  RequiresInvoice: 'requires_invoice',
  SocialSecurityNumber: 'social_security_number',
  State: 'state',
  Street: 'street',
  Street1: 'street_1',
  Street2: 'street_2',
  Street1Ie: 'street_1_ie',
  Street2Ie: 'street_2_ie',
  StreetNumber: 'street_number',
  StreetNameBlock: 'streetname_block',
  Suburb: 'suburb',
  SuburbIe: 'suburb_ie',
  Town: 'town',
  VatNumber: 'vat_number',
  VatCode: 'vat_code',
  Zip: 'zip',
  ZipIe: 'zip_ie',
};

export const AddressFormTypes = {
  Billing: 'billing',
  Shipping: 'shipping',
};

export const addressFormatValidationError = PropTypes.shape({
  field: PropTypes.oneOf(Object.values(FieldKeys)),
  message: PropTypes.string,
});

export const fieldType = PropTypes.oneOf(Object.values(FieldTypes));

export const fieldKey = PropTypes.oneOf(Object.values(FieldKeys));

export const addressFormType = PropTypes.oneOf(Object.values(AddressFormTypes));

export const listEntries = PropTypes.shape({ entries: PropTypes.array });

export const merchantShippingLocations = PropTypes.shape({
  country: listEntries,
  state: listEntries,
});

export const externalPrefills = PropTypes.shape({
  apple_pay: PropTypes.arrayOf(PropTypes.string),
  google_pay: PropTypes.arrayOf(PropTypes.string),
  gps_fill_in: PropTypes.arrayOf(PropTypes.string),
  web_stripe: PropTypes.arrayOf(PropTypes.string),
});

export const field = PropTypes.shape({
  type: fieldType.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  must_validate: PropTypes.bool.isRequired,

  // Next values are not required as they depend upon which type the field is
  list: listEntries,
  enable_by_fields_and_values: PropTypes.arrayOf(
    PropTypes.shape({
      field: fieldKey,
      values: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  max_length: PropTypes.number,
  mask: PropTypes.string,
  mask_regex: PropTypes.string,
  prefill_fields: PropTypes.arrayOf(fieldKey),
  external_prefills: externalPrefills,
});

/**
 * ApplePay & GooglePay are not returned by the API but have been placed here
 * as a convenient ID for internal client side handling
 */
export const PaymentOptionsPropType = {
  Stripe: 'Stripe',
  Stripe_CreditCard: 'card_payments',
  Stripe_iDeal: 'ideal_payments',
  Stripe_Giropay: 'giropay_payments',
  Stripe_Bancontact: 'bancontact_payments',
  Paypal: 'Paypal',
  ApplePay: 'ApplePay',
  GooglePay: 'GooglePay',
  ZeroPayment: 'zero_payment',
};

export const VivinoPropTypes = {
  vintage,
  wine,
};

/**
 * Custom validator that makes it required to pass only one of specified props
 * Usage:
 * requireOneOf({
 *   propA: PropTypes.bool,
 *   propB: PropTypes.string,
 * })
 * See https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
 * on how to write custom validators
 */
export const requireOneOf = (propTypes) => {
  const propNames = Object.keys(propTypes);

  const checkOnlyOneRequiredProp = (propType) => {
    return (props, propName, componentName) => {
      const specifiedProps = propNames.reduce((memo, name) => {
        if (Object.prototype.hasOwnProperty.call(props, name)) {
          memo.push(name);
        }
        return memo;
      }, []);

      const count = specifiedProps.length;
      if (count === 0) {
        const concatNames = propNames.join(', ');
        return new Error(`Missing required prop. Should have either one of: ${concatNames}`);
      } else if (count > 1) {
        const concatNames = specifiedProps.join(', ');
        return new Error(
          `Too many required props specified (${concatNames}). Only one can be specified.`
        );
      }

      return PropTypes.checkPropTypes({ [propName]: propType }, props, propName, componentName);
    };
  };

  return Object.entries(propTypes).reduce((memo, [propName, propType]) => {
    memo[propName] = checkOnlyOneRequiredProp(propType);
    return memo;
  }, {});
};

export const purchaseOrderChargeType = PropTypes.shape({
  address_zip_check: PropTypes.string,
  brand: PropTypes.string,
  cvc_check: PropTypes.string,
  exp_month: PropTypes.number,
  exp_year: PropTypes.number,
  last4: PropTypes.string,
  object: PropTypes.string,
  risk_level: PropTypes.string,
  risk_score: PropTypes.number,
  tokenization_method: PropTypes.string,
});
