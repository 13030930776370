import { apiPost } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export const EVENT_SIGNUP_PAGE = 'signup_page';
export const EVENT_SIGNUP_MODULE = 'signup_module';

export const createOfferSubscription = ({ email, event }) =>
  apiPost({
    url: generateQueryUrl({
      baseUrl: '/api/offer_subscription',
      params: [{ value: event, name: 'event' }],
    }),
    body: { email },
  });
