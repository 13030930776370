import { CANCELLED } from '@vivino/js-web-common';
import { apiGet, apiPatch, apiPost, apiPut } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export function createPurchaseOrderByCartId({ cartId, purchaseOrder, userDetails }) {
  const url = `/api/carts/${cartId}/purchase_order`;
  return apiPost({
    url,
    body: {
      purchase_order: purchaseOrder,
      user_details: userDetails,
    },
  });
}

export function cancelPurchaseOrder({ accessToken, purchaseOrderId, cancellationTypeId }) {
  const url = `/api/purchase_orders/${purchaseOrderId}`;
  return apiPut({
    url,
    body: {
      access_token: accessToken,
      cancellation_type_id: cancellationTypeId,
      status: CANCELLED,
    },
  });
}

export function updatePurchaseOrderShippingAddress({ purchaseOrderId, shippingAddress }) {
  const url = `/api/purchase_orders/${purchaseOrderId}/shipping_address`;
  return apiPatch({
    url,
    body: {
      shipping_address: shippingAddress,
    },
  });
}

export function getPurchaseOrder({ purchaseOrderId }) {
  const url = `/api/purchase_orders/${purchaseOrderId}`;
  return apiGet({ url });
}

export function getPurchaseOrderStatusFromCartId({ cartId }) {
  const url = `/api/checkout/carts/${cartId}/purchase_order_status/`;
  return apiGet({ url });
}

export function getPurchaseOrders({ page = 1, userID = null } = {}) {
  const params = [{ name: 'page', value: page }];

  if (userID) {
    params.push({ name: 'user_id', value: userID });
  }

  const url = generateQueryUrl({
    baseUrl: '/api/purchase_orders',
    params,
  });
  return apiGet({ url });
}
