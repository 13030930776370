import PropTypes from 'prop-types';
import React from 'react';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';

// Wraps the CartItemSourceContext around an element if there is a specific
// source for it and overrides any that comes from higher in the dom tree
const WrapCartItemSourceContext = ({ cartItemSource, children }) =>
  cartItemSource ? (
    <CartItemSourceContext.Provider value={cartItemSource}>
      {children}
    </CartItemSourceContext.Provider>
  ) : (
    children
  );

WrapCartItemSourceContext.propTypes = {
  cartItemSource: PropTypes.string,
  children: PropTypes.node,
};

export default WrapCartItemSourceContext;
