import { LOAD_MORE_COMMENTS_COUNT } from './commentsConstants';

export const CommentsActionTypes = {
  INIT: 'init',
  ADD: 'add',
  DELETE: 'delete',
  UPDATE: 'update',
  FETCH_MORE: 'fetch_more',
  RENDER_MORE: 'render_more',
};

const commentsReducer = (state, action) => {
  switch (action.type) {
    case CommentsActionTypes.INIT:
      return {
        fetchedComments: action.comments,
        visibleCommentsCount: Math.min(action.comments.length, LOAD_MORE_COMMENTS_COUNT),
      };
    case CommentsActionTypes.ADD:
      return {
        fetchedComments: [action.comment, ...state.fetchedComments],
        visibleCommentsCount: state.visibleCommentsCount + 1,
      };
    case CommentsActionTypes.DELETE:
      return {
        fetchedComments: state.fetchedComments.filter((comment) => comment.id !== action.commentId),
        visibleCommentsCount: state.visibleCommentsCount - 1,
      };
    case CommentsActionTypes.UPDATE:
      return {
        fetchedComments: state.fetchedComments.map((comment) => {
          if (comment.id === action.comment.id) {
            return {
              ...comment,
              tagged_text: action.comment.tagged_text,
            };
          }
          return comment;
        }),
        visibleCommentsCount: state.visibleCommentsCount,
      };
    // fetch from server and render
    case CommentsActionTypes.FETCH_MORE: {
      const fetchedComments = [...state.fetchedComments, ...action.comments];
      return {
        fetchedComments,
        visibleCommentsCount: Math.min(fetchedComments.length, action.count),
      };
    }
    // data is already fetched but not all are rendered. So render more from them.
    case CommentsActionTypes.RENDER_MORE:
      return {
        fetchedComments: state.fetchedComments,
        visibleCommentsCount: Math.min(state.fetchedComments.length, action.count),
      };
  }
};

export default commentsReducer;
