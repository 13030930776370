import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';

import styles from './communityReview.scss';
import skeletonStyles from './communityReviewSkeleton.scss';

export const CommunityReviewSkeleton = ({
  isUserActionEnabled = false,
  size = ComponentSizes.Base,
  className,
}) => {
  return (
    <div
      className={cx(
        {
          [styles.large]: size === ComponentSizes.Large,
        },
        skeletonStyles.reviewSkeleton,
        className
      )}
      data-testid="reviewCardSkeleton"
    >
      <div className={styles.reviewContent}>
        <div className={cx(styles.reviewText, skeletonStyles.fullWidthBlock)} />
      </div>
      <div className={styles.reviewInfo}>
        <div className={styles.avatarAndText}>
          <div className={cx(styles.avatar, skeletonStyles.circle)} />
          <div className={skeletonStyles.rectangle} />
        </div>
        {isUserActionEnabled && (
          <div className={styles.userActions}>
            <div className={skeletonStyles.rectangleSmall} />
          </div>
        )}
      </div>
    </div>
  );
};

CommunityReviewSkeleton.propTypes = {
  isUserActionEnabled: PropTypes.bool,
  size: CommonPropTypes.componentSize,
  className: PropTypes.string,
};
