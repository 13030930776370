export const getCampaignId = () => {
  return window.__PRELOADED_STATE__.campaignId || '';
};

export interface CampaignTrackingProps {
  id: number;
  slug: string;
  title: string;
  type: string;
}

export interface MerchandizingCoupon {
  couponCode: string;
  bodySuccess: string;
  bodyCheckoutApply: string;
  bodyReminder: string;
  checkoutCtaCopy: string;
  couponExpiryDate: string;
  isApplied?: boolean;
  campaignId: number;
  campaignSlug: string;
  campaignTitle: string;
  campaignType: string;
}

export const getPreloadedCampaignTrackingInfo = (): CampaignTrackingProps =>
  window.__PRELOADED_STATE__?.campaignTrackingInfo;

/*
 * The query params below are not supported on the explorer page. A Show All button would land the user
 * to an explorer page with different results than the ones in the wine band. If these unsopported params
 * are present in the query, return true and that will disable the Show All button in merchandizing bands.
 */
export const hasUnsupportedExplorerPageFilters = (exploreQuery = '') => {
  return exploreQuery.includes('merchant_ids') || exploreQuery.includes('vintage_ids');
};
