export type FormSize = 'sizeSmall' | 'sizeMedium' | 'sizeLarge';

export enum ComponentSize {
  Base = 'base',
  Large = 'large',
}

export enum PriceType {
  VC = 'vc',
  XDO = 'xdo',
}

export type TranslationParams = {
  key: string;
  params?: {};
};

export type TranslationFn = (
  key: TranslationParams['key'],
  params?: TranslationParams['params']
) => string | any[];
