import {
  Currency,
  DASH,
  FREE,
  INCLUDED,
  getCurrentLocaleWithRegion,
  t,
} from '@vivino/js-web-common';

const TRANSLATIONS = {
  free: 'components.shared.price.free',
  included: 'components.shared.price.included',
  pricePerLiter: 'components.shared.price.price_per_liter',
  save: 'components.shared.price.save',
  save_zh: 'components.shared.price.save_zh',
};

interface FormatNumberProps {
  amount: number;
  locale?: string;
  currencyCode?: string;
}

export function formatNumber({
  amount,
  locale = getCurrentLocaleWithRegion(),
  currencyCode = undefined,
}: FormatNumberProps) {
  if (!Number.isFinite(amount)) {
    return '0';
  }

  const fractionDigits = Number.isInteger(amount) ? 0 : 2;

  if (currencyCode) {
    return Intl.NumberFormat(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      style: 'currency',
      currency: currencyCode,
    }).format(amount);
  }

  return Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);
}

interface PriceToStringProps {
  currency: Currency;
  locale?: string;
  amount: number;
  isNegative?: boolean;
  showZeroAs?: string;
  prependPlus?: boolean;
}

export function priceToString({
  currency,
  locale = getCurrentLocaleWithRegion(),
  amount,
  isNegative = false,
  showZeroAs = undefined,
  prependPlus = false,
}: PriceToStringProps) {
  if (amount === null || amount === undefined) {
    return '';
  }

  if (amount === 0) {
    if (showZeroAs === FREE) {
      return t(TRANSLATIONS.free);
    } else if (showZeroAs === INCLUDED) {
      return t(TRANSLATIONS.included);
    } else if (showZeroAs === DASH) {
      return '-';
    }
    return formatNumber({ amount: 0, locale, currencyCode: currency.code });
  }

  return (
    (isNegative ? '-' : '') +
    (!isNegative && prependPlus ? '+' : '') +
    formatNumber({ amount, locale, currencyCode: currency.code })
  );
}
