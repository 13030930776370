import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { MouseButtons } from 'vivino-js/helpers/events';
import { KeyCode } from 'vivino-js/helpers/keyCodes';

import styles from './backdrop.scss';

export const BackdropColors = {
  None: 'none',
  Basic: 'basic',
};

const Backdrop = ({ onClose, color = BackdropColors.None, usePortal = false, className }) => {
  const backdropElement = useRef();

  useEffect(() => {
    const closeOnEscape = (e) => {
      if (e.keyCode === KeyCode.Esc) {
        onClose();
      }
    };

    document.addEventListener('keydown', closeOnEscape);
    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, []);

  const handleClick = (e) => {
    if (e.target === backdropElement.current && e.button === MouseButtons.LEFT) {
      e.stopPropagation();
      onClose();
    }
  };

  const content = (
    <div
      ref={backdropElement}
      onMouseDown={handleClick}
      onTouchEnd={handleClick}
      className={cx(styles.backdrop, styles[color], className)}
    />
  );

  return usePortal ? ReactDOM.createPortal(content, document.body) : content;
};

Backdrop.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  usePortal: PropTypes.bool,
};

export default Backdrop;
