import PropTypes from 'prop-types';
import { useEffect } from 'react';

import styles from './disableScrolling.scss';

const DisableScrolling = ({ children }) => {
  useEffect(() => {
    // If disable scrolling is already in the body,
    // It is the first component that adds it
    // that is responsible for removing it
    if (!document.body.classList.contains(styles.disableScrolling)) {
      document.body.classList.add(styles.disableScrolling);

      return () => {
        document.body.classList.remove(styles.disableScrolling);
      };
    }
  }, []);

  return children;
};

DisableScrolling.propTypes = {
  children: PropTypes.object.isRequired,
};

export default DisableScrolling;
