import {
  PlusPlan,
  PlusSubscription,
  RubyLibCartFull,
  SubscribeRequest,
  SubscribeResponse,
  apiGet,
  apiPost,
  isMatch,
} from '@vivino/js-web-common';

interface CreateSubscriptionReturnType {
  response: SubscribeResponse;
}

export const getPlusPlans = (): Promise<{ plans: PlusPlan[] }> => {
  return apiGet({
    url: '/api/vivino_plus/plans',
  }) as Promise<{ plans: PlusPlan[] }>;
};

export const getAvailablePlansForCart = async (cart: RubyLibCartFull) => {
  /*
   * We do not want to show the Vivino Plus banner in flows for carts created with merchants from other countries.
   * To avoid that, clients should filter the received plans and use only the ones which currency matches the cart’s currency.
   */

  let availablePlans: PlusPlan[];

  const cartCurrencyCode = cart.currency.code;

  try {
    const { plans } = await getPlusPlans();
    availablePlans = plans.filter((plan) => isMatch(plan.currency_code, cartCurrencyCode));
  } catch (e) {
    // do nothing
  }

  return { plans: availablePlans };
};

export const getSubscriptions = async (): Promise<{ subscriptions: PlusSubscription[] }> =>
  apiGet({ url: '/api/vivino_plus/subscriptions' });

export const createSubscription = async ({
  planId,
  body,
}: {
  planId: number;
  body: SubscribeRequest;
}): Promise<CreateSubscriptionReturnType> => {
  const url = `/api/vivino_plus/plans/${planId}/subscriptions`;
  const response = await apiPost({ url, body });
  return { response };
};
