import { AddressFormat, FullAddress, Prefill } from '@vivino/js-web-common';
import { AddressFormType } from '@webtypes/address';
import { Reducer } from 'react';
import {
  AddressFormatByCountry,
  CurrentAddress,
  ErrorsByFormType,
} from 'vivino-js/context/AddressContext/AddressContext';

import { AddressError } from 'vivino-ui/components/AddressForm/components/AddressFormatField';

export enum AddressActionTypes {
  UPDATE = 'update',
  UPDATE_CURRENT_ADDRESS_FIELDS = 'updateCurrentAddressFields',
  UPDATE_ERROR_FIELDS = 'updateErrorFields',
}

export type UpdateAddressReducerAction = {
  type: AddressActionTypes.UPDATE;
  countryCode?: string;
  currentAddress: CurrentAddress;
  addressFormat: AddressFormat | {};
  addressUserPrefill: Record<AddressFormType, Prefill> | {};
  errorsByFormType?: Record<AddressFormType, AddressError> | {};
};

export type UpdateCurrentAddressReducerAction = {
  type: AddressActionTypes.UPDATE_CURRENT_ADDRESS_FIELDS;
  addressFormType: AddressFormType;
  updateFields: FullAddress;
};

export type UpdateErrorAddressReducerAction = {
  type: AddressActionTypes.UPDATE_ERROR_FIELDS;
  addressFormType: AddressFormType;
  updateFields: Record<string, AddressError> | {};
};

export type AddressReducerAction =
  | UpdateAddressReducerAction
  | UpdateCurrentAddressReducerAction
  | UpdateErrorAddressReducerAction;

interface StateInterface {
  addressFormatByCountry: AddressFormatByCountry;
  addressUserPrefillByCountry: Record<string, Record<AddressFormType, FullAddress>> | {};
  currentAddress: CurrentAddress;
  errorsByFormType: ErrorsByFormType;
}

const addressReducer: Reducer<StateInterface, AddressReducerAction> = (state, action) => {
  switch (action.type) {
    case AddressActionTypes.UPDATE: {
      const countryCode = action.countryCode?.toLowerCase();
      return {
        addressFormatByCountry: {
          ...state.addressFormatByCountry,
          ...(countryCode &&
            action.addressFormat && {
              [countryCode]: action.addressFormat,
            }),
        },
        addressUserPrefillByCountry: {
          ...state.addressUserPrefillByCountry,
          ...(countryCode &&
            action.addressUserPrefill && {
              [countryCode]: action.addressUserPrefill,
            }),
        },
        currentAddress: {
          ...state.currentAddress,
          ...action.currentAddress,
        },
        errorsByFormType: {
          ...state.errorsByFormType,
          ...action.errorsByFormType,
        },
      };
    }
    case AddressActionTypes.UPDATE_CURRENT_ADDRESS_FIELDS: {
      return {
        addressFormatByCountry: { ...state.addressFormatByCountry },
        addressUserPrefillByCountry: { ...state.addressUserPrefillByCountry },
        currentAddress: {
          ...state.currentAddress,
          [action.addressFormType]: {
            ...state.currentAddress[action.addressFormType],
            ...action.updateFields,
          },
        },
        errorsByFormType: { ...state.errorsByFormType },
      };
    }
    case AddressActionTypes.UPDATE_ERROR_FIELDS: {
      return {
        addressFormatByCountry: { ...state.addressFormatByCountry },
        addressUserPrefillByCountry: { ...state.addressUserPrefillByCountry },
        currentAddress: { ...state.currentAddress },
        errorsByFormType: {
          ...state.errorsByFormType,
          [action.addressFormType]: {
            ...state.errorsByFormType[action.addressFormType],
            ...action.updateFields,
          },
        },
      };
    }
  }
};

export default addressReducer;
