import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import t from 'vivino-js/translationString';

const TRANSLATIONS = {
  now: 'components.shared.time_ago.now',
  minutes: 'components.shared.time_ago.minutes_ago',
  hours: 'components.shared.time_ago.hours_ago',
  days: 'components.shared.time_ago.days_ago',
  months: 'components.shared.time_ago.months_ago',
  years: 'components.shared.time_ago.years_ago',
};

const TimeAgo = ({ date }) => {
  const now = new Date().toISOString();
  const isoDate = dayjs(date).toISOString();
  const todaysDate = dayjs(now);
  const parsedDate = dayjs(isoDate);

  const diffYears = todaysDate.diff(parsedDate, 'year');
  const diffMonths = todaysDate.diff(parsedDate, 'month');
  const diffDays = todaysDate.diff(parsedDate, 'day');
  const diffHours = todaysDate.diff(parsedDate, 'hour');
  const diffMinutes = todaysDate.diff(parsedDate, 'minute');
  const diffSeconds = todaysDate.diff(parsedDate, 'second');

  if (diffSeconds < 60) {
    return t(TRANSLATIONS.now);
  } else if (diffYears > 1) {
    return t(TRANSLATIONS.years, { count: diffYears });
  } else if (diffMonths > 1) {
    return t(TRANSLATIONS.months, { count: diffMonths });
  } else if (diffDays > 1) {
    return t(TRANSLATIONS.days, { count: diffDays });
  } else if (diffHours > 1) {
    return t(TRANSLATIONS.hours, { count: diffHours });
  } else {
    return t(TRANSLATIONS.minutes, { count: diffMinutes });
  }
};

TimeAgo.propTypes = {
  date: PropTypes.string.isRequired,
};

export default TimeAgo;
