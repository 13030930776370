import { Chevron, ChevronDirections } from '@vivino/js-web-common';
import cx from 'classnames';
import React, { Children, ReactNode, useEffect, useRef, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import styles from './slider.scss';

const NEXT = 'NEXT';
const PREVIOUS = 'PREVIOUS';

const withTransition = (node) => (
  <ReactCSSTransitionGroup
    transitionName="control-transition"
    transitionEnter
    transitionEnterTimeout={300}
    transitionLeave
    transitionLeaveTimeout={300}
  >
    {node}
  </ReactCSSTransitionGroup>
);

type SliderPageSize = 1 | 2 | 3 | 4 | 5 | 6;

interface SliderProps {
  children: ReactNode;
  showDots?: boolean;
  pageSize?: SliderPageSize;
  dotsClass?: string;
  controlsPositionTop?: string;
  previousControlClass?: string;
  previousControl?: ReactNode;
  nextControlClass?: string;
  nextControl?: ReactNode;
  slideIndex?: number;
  grid?: boolean;
  showControlsOnAllDevices?: boolean;
}

const Slider = ({
  children,
  showDots = false,
  pageSize = 5,
  dotsClass,
  controlsPositionTop,
  previousControlClass,
  previousControl,
  nextControlClass,
  nextControl,
  slideIndex = 1,
  grid = false,
  showControlsOnAllDevices = false,
}: SliderProps) => {
  const getDefaultState = () => {
    return {
      slideIndex: 1,
      offset: 0,
      furthestNavigatedIndex: pageSize - 1,
      canMoveNext: Children.count(children) > pageSize,
      dotsCount: Math.ceil(Children.count(children) / pageSize),
      canMovePrevious: false,
      hasScrolledMobile: false,
    };
  };

  const [state, setState] = useState(getDefaultState());
  const partialUpdateState = (updateProps) =>
    setState((prevState) => ({ ...prevState, ...updateProps }));

  const viewportEl = useRef<HTMLDivElement>(null);

  const handleWindowResize = () => {
    // prevent window resizing creating inconsistent slider positioning
    setState(getDefaultState());
    if (viewportEl?.current) {
      viewportEl.current.scrollLeft = 0;
    }
  };

  const handleMobileScrollOnce = () => {
    partialUpdateState({ hasScrolledMobile: true });
    if (viewportEl?.current) {
      viewportEl.current.removeEventListener('scroll', handleMobileScrollOnce);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    if (viewportEl?.current) {
      viewportEl.current.addEventListener('scroll', handleMobileScrollOnce);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (viewportEl?.current) {
        viewportEl.current.removeEventListener('scroll', handleMobileScrollOnce);
      }
    };
  }, []);

  const mountedRef = useRef(false);
  const prevSlideIndexRef = useRef(slideIndex);

  // equivalent of componentDidUpdate
  // see https://dev.to/savagepixie/how-to-mimic-componentdidupdate-with-react-hooks-3j8c
  useEffect(() => {
    if (mountedRef.current) {
      if (prevSlideIndexRef.current) {
        if (slideIndex > prevSlideIndexRef.current) {
          move(NEXT);
        } else if (slideIndex < prevSlideIndexRef.current) {
          move(PREVIOUS);
        }
      }
    } else {
      mountedRef.current = true;
    }
    if (prevSlideIndexRef) {
      prevSlideIndexRef.current = slideIndex;
    }
  }, [slideIndex]);

  if (Children.count(children) === 0) {
    return null;
  }

  const move = (direction) => {
    const offsetPerWholePage = 100; // percentage
    const offsetPerItem = offsetPerWholePage / pageSize;

    const totalAvialableOffset = Children.count(children) * offsetPerItem - offsetPerWholePage;

    const nextOffset = Math.min(
      offsetPerWholePage,
      Math.max(0, totalAvialableOffset - state.offset)
    );
    let newOffset;
    let newfurthestNavigatedIndex;
    let currentPage;

    if (direction === NEXT) {
      currentPage = state.slideIndex + 1;
      newOffset = state.offset + nextOffset;
      newfurthestNavigatedIndex = Math.min(
        state.furthestNavigatedIndex + pageSize,
        Children.count(children) - 1
      );
    }
    if (direction === PREVIOUS) {
      currentPage = state.slideIndex - 1;
      newOffset = Math.max(0, state.offset - offsetPerWholePage);
      newfurthestNavigatedIndex = state.furthestNavigatedIndex;
    }

    partialUpdateState({
      slideIndex: currentPage,
      offset: newOffset,
      furthestNavigatedIndex: newfurthestNavigatedIndex,
      canMoveNext: newOffset < totalAvialableOffset,
      canMovePrevious: newOffset > 0,
    });
  };

  const handleClickPrevious = () => {
    move(PREVIOUS);
  };

  const handleClickNext = () => {
    move(NEXT);
  };

  const renderPreviousControl = () => {
    return state.canMovePrevious ? (
      <div
        style={controlsPositionTop ? { top: controlsPositionTop } : null}
        className={cx(styles.control, styles.left, previousControlClass)}
        onClick={handleClickPrevious}
        role="button"
        tabIndex={0}
        aria-label="previous slide"
      >
        {previousControl ? previousControl : <Chevron direction={ChevronDirections.LEFT} />}
      </div>
    ) : null;
  };

  const renderNextControl = () => {
    return state.canMoveNext ? (
      <div
        style={controlsPositionTop ? { top: controlsPositionTop } : null}
        className={cx(styles.control, styles.right, nextControlClass)}
        onClick={handleClickNext}
        role="button"
        tabIndex={0}
        aria-label="next slide"
      >
        {nextControl ? nextControl : <Chevron direction={ChevronDirections.RIGHT} />}
      </div>
    ) : null;
  };

  const renderDots = () => {
    if (!showDots) {
      return null;
    }

    return (
      <div className={cx(styles.dots, dotsClass)} data-testid="sliderDots">
        {Array.from({ length: state.dotsCount }).map((_, index) => (
          <div
            key={index}
            className={cx(styles.dot, {
              [styles.active]: index === state.slideIndex - 1,
            })}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={cx(styles.slider, {
        [styles.showControls]: showControlsOnAllDevices,
        [styles.grid]: grid,
      })}
    >
      {withTransition(renderNextControl())}
      <div className={cx(styles.viewPort)} ref={viewportEl}>
        <div
          className={styles.track}
          style={{
            transform: `translate3d(-${state.offset}%, 0, 0)`,
          }}
        >
          {Children.map(children, (child: React.ReactElement) =>
            React.cloneElement(child, {
              className: cx(child.props.className, {
                [styles.item]: !grid,
                [styles[`show${pageSize}`]]: !grid,
                [styles.grid]: grid,
              }),
            })
          )}
        </div>
        {renderDots()}
      </div>
      {withTransition(renderPreviousControl())}
    </div>
  );
};

export default Slider;
