import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { node } from 'vivino-js/commonPropTypes';

import styles from './anchor.scss';

const Anchor = (props) => {
  const { children, className, ...restProps } = props;

  return (
    <a className={cx(styles.anchor, className)} {...restProps}>
      {children}
    </a>
  );
};

Anchor.propTypes = {
  href: PropTypes.string,
  children: node,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Anchor;
