import {
  Button,
  ButtonTheme,
  CrossSmallIcon,
  Sentinel,
  ThemeProvider,
  Typography,
  TypographyType,
} from '@vivino/js-react-common-ui';
import { useI18N } from '@vivino/js-web-common';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Cross from '@components/icons/Cross/Cross';

import DisableScrolling from 'vivino-js/components/DisableScrolling/DisableScrolling.presentational';

import styles from './styles/baseModal.module.scss';
import crossTransitions from './styles/crossTransitions.module.scss';
import transitions from './styles/transitions.module.scss';
import windowTransitions from './styles/windowTransitions.module.scss';

export const POSITION_TOP = 'POSITION_TOP';
export const PADDING_NONE = 'PADDING_NONE';

const TRANSLATIONS = {
  close: 'components.shared.modals.base_modal.close',
};

const LEFT_MOUSE_KEY = 0;

export const CLOSE_TRIGGERS = {
  X_BUTTON: 'x_button',
  BACKDROP: 'tap_outside',
  ESC_KEY: 'esc_key',
} as const;

type CLOSE_TRIGGERS_VALUES = typeof CLOSE_TRIGGERS[keyof typeof CLOSE_TRIGGERS];

export enum ModalSize {
  Standard = 'Standard',
  Small = 'Small',
  LightBox = 'LightBox',
}

type BaseModalProps = PropsWithChildren<{
  headline?: string;
  className?: string;
  closeBtnClassName?: string;
  show: boolean;
  onClose?: ({ trigger }: { trigger: CLOSE_TRIGGERS_VALUES }) => void;
  padding?: 'PADDING_NONE';
  position?: 'POSITION_TOP';

  // Only applies for lightbox
  // This is for infinite scrolling
  onIntersect?: () => void;
  // This is a number that increases to let the modal scroll to top when a filter is changed
  scrollToTopUpdater?: number;

  // These sizes are specified by design
  // And based on the sizes of the grid
  // Standard is what corresponds to 6 columns on Desktop and 10 on Tablet
  // Small is what corresponds to 6 columns on Desktop and 6 on Tablet
  // LightBox is Standard size but extends to the bottom with props for infinite scrolling
  // Both are fullscreen on Mobile
  size?: ModalSize;
}>;

export const BaseModal = ({
  headline,
  onClose,
  onIntersect,
  children,
  padding,
  position,
  className,
  closeBtnClassName,
  show,
  scrollToTopUpdater,
  size = ModalSize.Standard,
}: BaseModalProps) => {
  const { t } = useI18N();
  const backdropEl = useRef();
  const windowRef = useRef<HTMLDivElement | null>(null);
  const [showWindow, setShowWindow] = useState(false);
  const isLightBox = size === 'LightBox';

  useEffect(() => {
    // window css transition will not work with show
    // since show is used to mount the component
    setShowWindow(show);
  }, [show]);

  useEffect(() => {
    const closeOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose?.({ trigger: CLOSE_TRIGGERS.ESC_KEY });
      }
    };

    document.addEventListener('keydown', closeOnEscape);
    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, []);

  useEffect(() => {
    if (windowRef && windowRef.current) {
      windowRef.current.scrollTop = 0;
    }
  }, [scrollToTopUpdater]);

  const handleBackdropClick = (e) => {
    if (e.target === backdropEl.current && e.button === LEFT_MOUSE_KEY) {
      e.stopPropagation();
      onClose?.({ trigger: CLOSE_TRIGGERS.BACKDROP });
    }
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose?.({ trigger: CLOSE_TRIGGERS.X_BUTTON });
  };

  const optionalClasses = {
    [styles.positionTop]: position === POSITION_TOP,
    [styles.themeNoPadding]: padding === PADDING_NONE,
    [styles.small]: size === 'Small',
  };

  const element = globalThis.document?.getElementById('baseModal');

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(
    <CSSTransition in={show} timeout={300} classNames={{ ...transitions }} unmountOnExit>
      <DisableScrolling>
        <div
          className={cx(styles.backdrop, { [styles.lightBox]: isLightBox })}
          onMouseDown={handleBackdropClick}
          onTouchEnd={handleBackdropClick}
          ref={backdropEl}
          data-testid="baseModalBackdrop"
        >
          {isLightBox && onClose && (
            <CSSTransition in={showWindow} timeout={600} classNames={{ ...crossTransitions }}>
              <div className={styles.closeContainer} data-testid="baseModalContainer">
                <Button
                  iconButton
                  theme={ButtonTheme.Plain}
                  href="#"
                  onClick={handleCloseClick}
                  className={cx(styles.close, closeBtnClassName)}
                  aria-label={t(TRANSLATIONS.close)}
                  data-testid="baseModalCloseButton"
                >
                  <Cross />
                </Button>
              </div>
            </CSSTransition>
          )}
          <CSSTransition in={showWindow} timeout={500} classNames={{ ...windowTransitions }}>
            <div className={cx(styles.window, optionalClasses, className)} ref={windowRef}>
              {!isLightBox &&
                onClose &&
                (headline !== undefined ? (
                  <div className={styles.headlineContainer} data-testid="baseModalHeadline">
                    <Typography type={TypographyType.HeadlineSmall}>{headline}</Typography>
                    <Button
                      iconButton
                      theme={ButtonTheme.Plain}
                      href="#"
                      onClick={handleCloseClick}
                      className={closeBtnClassName}
                      aria-label={t(TRANSLATIONS.close)}
                      data-testid="baseModalCloseButton"
                    >
                      <CrossSmallIcon />
                    </Button>
                  </div>
                ) : (
                  <div className={styles.closeContainer} data-testid="baseModalContainer">
                    <Button
                      iconButton
                      theme={ButtonTheme.Plain}
                      href="#"
                      onClick={handleCloseClick}
                      className={cx(styles.close, closeBtnClassName)}
                      aria-label={t(TRANSLATIONS.close)}
                      data-testid="baseModalCloseButton"
                    >
                      <Cross />
                    </Button>
                  </div>
                ))}
              {children}
              {isLightBox && windowRef?.current && (
                <ThemeProvider>
                  <Sentinel
                    onIntersect={onIntersect}
                    options={{
                      root: windowRef.current,
                      rootMargin: '600px 0px',
                      threshold: 0.1,
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </CSSTransition>
        </div>
      </DisableScrolling>
    </CSSTransition>,
    element
  );
};
