export interface UserReview {
  numberOfStars: number;
  title: string;
  content: string;
  author: string;
  country_code: string;
  country_name: string;
}

const NUMBER_OF_STARS = 5;

const TRANSLATIONS_PATH_REVIEW_1 = 'components.premium_page.great_way_to_learn';
const TRANSLATIONS_PATH_REVIEW_2 = 'components.premium_page.great_for_wine_lovers';
const TRANSLATIONS_PATH_REVIEW_3 = 'components.premium_page.my_trusted_advisor';

export const review1: UserReview = {
  numberOfStars: NUMBER_OF_STARS,
  title: `${TRANSLATIONS_PATH_REVIEW_1}.great_way_to_learn`,
  content: `${TRANSLATIONS_PATH_REVIEW_1}.youll_be_amazed_at_what_you_will_learn`,
  author: 'padreN',
  country_code: 'gb',
  country_name: 'United Kingdom',
};

export const review2: UserReview = {
  numberOfStars: NUMBER_OF_STARS,
  title: `${TRANSLATIONS_PATH_REVIEW_2}.great_for_wine_lovers`,
  content: `${TRANSLATIONS_PATH_REVIEW_2}.this_is_a_phenomenal_app`,
  author: 'Caladell',
  country_code: 'us',
  country_name: 'United States',
};

export const review3: UserReview = {
  numberOfStars: NUMBER_OF_STARS,
  title: `${TRANSLATIONS_PATH_REVIEW_3}.my_trusted_advisor`,
  content: `${TRANSLATIONS_PATH_REVIEW_3}.i_like_to_explore_new_wines`,
  author: 'Snöggi Vindur',
  country_code: 'is',
  country_name: 'Iceland',
};
