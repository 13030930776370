import { ActionButton } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CommentIcon from 'vivino-ui/icons/Comment/20';

import styles from './commentsButton.scss';

const CommentsButton = ({ className, onClick, commentsCount }) => {
  const [isCommentExpanded, setIsCommentExpanded] = useState(false);

  const handleClick = (...args) => {
    setIsCommentExpanded((prevState) => !prevState);
    onClick(...args);
  };

  return (
    <ActionButton
      className={cx(className, styles.commentsButton, { [styles.active]: isCommentExpanded })}
      ariaLabel="expand comments"
      onClick={handleClick}
    >
      <CommentIcon />
      <div className={styles.commentsCount}>{commentsCount}</div>
    </ActionButton>
  );
};

CommentsButton.propTypes = {
  className: PropTypes.string,
  commentsCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default CommentsButton;
