import { FieldTypes } from 'vivino-js/apiPropTypes';
import { getCurrentCountryCode, isJP } from 'vivino-js/helpers/countryHelpers';

export const getValueFromKeyInListOfObjects = (key, listOfObjects) => {
  const foundObject = listOfObjects.find((object) => object[key] !== undefined);

  return foundObject?.[key];
};

/**
 * Builds the address summary line object based on the given configuration
 * AddressFormat https://vivino.slab.com/posts/address-format-jl3t64zz
 *
 * address: current address (one of AddressFormType)
 * displayFields: AddressFormat / display_fields / AddressFormType
 * fieldsConfig: AddressFormat / fields
 *
 * @returns {array}
 */
export const buildSummaryAddressLineElements = ({ address, displayFields, fieldsConfig }) => {
  const addressLineElements = displayFields.reduce((accumulator, fieldKey) => {
    let addressFieldValue = address[fieldKey];
    const fieldKeyConf = fieldsConfig[fieldKey];

    if (addressFieldValue && !fieldKeyConf.hide_in_summary) {
      if (isJP(getCurrentCountryCode()) && fieldKey === 'zip') {
        // it is probably only Japan which prefixes zip code with symbol,
        // so do it in client rather than backend
        addressFieldValue = `〒${addressFieldValue}`;
      }

      switch (fieldKeyConf.type) {
        case FieldTypes.List:
          accumulator.push({
            addressFieldValue: getValueFromKeyInListOfObjects(
              addressFieldValue,
              fieldKeyConf.list.entries
            ),
            fieldKey,
          });
          break;
        default:
          accumulator.push({ addressFieldValue, fieldKey });
      }
    }
    return accumulator;
  }, []);

  return addressLineElements;
};

/**
 * Builds the address summary array based on the given configuration
 *
 * address: CurrentAddressContext.currentAddress[addressFormType]
 * addressFormat: AddressFormatsContext.addressFormatByCountry[country]
 * addressFormType: AddressFormType
 *
 * @returns {array}
 */
export const buildSummaryAddressLines = ({ address, addressFormat, addressFormType }) => {
  const displayFields = addressFormat?.display_fields?.[addressFormType];

  if (!displayFields) {
    return [];
  }

  const fieldsConfig = addressFormat?.fields;

  return displayFields.groups.reduce((accumulator, group) => {
    const addressLine = buildSummaryAddressLineElements({
      address,
      displayFields: group.fields,
      fieldsConfig,
    });

    if (addressLine.length > 0) {
      accumulator.push(addressLine);
    }

    return accumulator;
  }, []);
};
