import PropTypes from 'prop-types';
import React from 'react';

const LikeOnIcon = ({ className }) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enableBackground="new 0 0 20 20"
    xmlSpace="preserve"
    width="20px"
    height="20px"
    className={className}
  >
    <path
      fill="#1e1e1e"
      d="M4.2,6.5H2C1.7,6.5,1.5,6.7,1.5,7v8.6c0,0.3,0.2,0.5,0.5,0.5h2.2c0.5,0,1-0.4,1-1V7.5C5.2,7,4.7,6.5,4.2,6.5z
			"
    />
    <path
      fill="#1e1e1e"
      d="M18.5,8.4c0-1.5-1.3-1.9-2-1.9h-4.6c-0.1,0-0.3-0.1-0.4-0.2c0,0,0,0,0,0l1.7-3c0.2-0.5,0.2-1.7-1-2.5
			c-1.3-0.8-2.4,0-2.8,0.5L5.7,6.6C5.6,6.6,5.6,6.7,5.6,6.8v6.5c0,0,0,0,0,0c0.1,2.4,3.5,3,3.7,3.1l6.4,0c0.7-0.1,1.9-0.6,1.9-2
			c0-0.6-0.2-1-0.4-1.3c0.7-0.3,1.3-0.8,1.3-1.7c0-0.7-0.3-1.2-0.7-1.5C18.2,9.6,18.5,9.1,18.5,8.4z"
    />
  </svg>
);

LikeOnIcon.propTypes = {
  className: PropTypes.string, // WARNING: do not use classname to change icon size, use different sized icon for purpose
};

export default LikeOnIcon;
