import { Button, ButtonTheme } from '@vivino/js-react-common-ui';
import { WineTypes } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { getCartItemSource, getCartItemSourceProps } from 'vivino-js/helpers/cartItemSources';
import { RatingsRange } from 'vivino-js/helpers/vivinoRating';
import t from 'vivino-js/translationString';
import { explorerUrl } from 'vivino-js/vivinoUrls';

import RangeSlider from 'vivino-js/components/RangeSlider';

import ExploreWidgetSkeleton from './ExploreWidget.skeleton';
import WineSelector from './WineSelector';
import styles from './exploreWidget.scss';

const TRANSLATIONS_PATH = 'components.explorer.explore_widget';
const TRANSLATIONS = {
  priceRange: `${TRANSLATIONS_PATH}.price_range`,
  rating: `${TRANSLATIONS_PATH}.rating`,
  showWines: `${TRANSLATIONS_PATH}.show_wines`,
};

const USD_PRICE_RANGE_FALLBACK = {
  currency: { code: 'USD', name: 'US Dollars', prefix: '$', suffix: null },
  price_range: { minimum: 0, maximum: 500 },
  defaults: { minimum: 10, maximum: 40 },
};

const ExploreWidget = ({
  headerText,
  wineTypeId = WineTypes.Red,
  priceRange = USD_PRICE_RANGE_FALLBACK,
  minRating = RatingsRange.DEFAULT,
  ...rest
}) => {
  const [wineTypeIdState, setWineTypeIdState] = useState(wineTypeId);
  const [minRatingState, setMinRatingState] = useState(minRating);
  const [priceRangeState, setPriceRangeState] = useState(priceRange?.defaults || {});

  const updatePriceRange = ([minimum, maximum]) => {
    setPriceRangeState({ minimum, maximum });
  };

  const cartItemSourceType = useContext(CartItemSourceContext);
  const cartItemSourceData = getCartItemSource(cartItemSourceType);
  const cartItemSourceProps = getCartItemSourceProps(cartItemSourceData);

  if (!priceRange.defaults) {
    return <ExploreWidgetSkeleton />;
  }

  return (
    <div className={styles.exploreWidget}>
      {headerText && <div className={styles.header}>{headerText}</div>}
      <div className={styles.main}>
        <div className={styles.selectors}>
          <div className={cx(styles.selector, styles.wineSelector)}>
            <WineSelector wineTypeId={wineTypeIdState} updateWineTypeId={setWineTypeIdState} />
          </div>
          <div className={cx(styles.selector)}>
            <div className={styles.selectorHeader}>{t(TRANSLATIONS.priceRange)}</div>
            <RangeSlider
              range={priceRange.price_range}
              defaultMax={priceRange.defaults?.maximum}
              initialValues={priceRange.defaults}
              labelPrefix={priceRange.currency?.prefix}
              labelSuffix={priceRange.currency?.suffix}
              onAfterChange={updatePriceRange}
            />
          </div>
          <div className={cx(styles.selector)}>
            <div className={styles.selectorHeader}>{t(TRANSLATIONS.rating)}</div>
            <RangeSlider
              isSimple
              range={{ minimum: RatingsRange.MIN, maximum: RatingsRange.MAX }}
              defaultMax={RatingsRange.MAX}
              initialValues={{ minimum: minRating, maximum: RatingsRange.MAX }}
              onAfterChange={setMinRatingState}
            />
          </div>
        </div>
        <div className={styles.submit}>
          <Button
            href={explorerUrl({
              ...rest,
              minRating: minRatingState,
              wineTypeIds: [wineTypeIdState],
              priceRangeMin: priceRangeState.minimum,
              priceRangeMax: priceRangeState.maximum,
            })}
            theme={ButtonTheme.Accent}
            {...cartItemSourceProps}
          >
            {t(TRANSLATIONS.showWines)}
          </Button>
        </div>
      </div>
    </div>
  );
};

ExploreWidget.propTypes = {
  headerText: PropTypes.string,
  wineTypeId: PropTypes.number,
  priceRange: PropTypes.object,
  minRating: PropTypes.number,
};

export default ExploreWidget;
