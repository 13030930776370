import cx from 'classnames';
import React from 'react';

import styles from './truck.module.scss';

interface TruckProps {
  className?: string;
}

const Truck = ({ className }: TruckProps) => (
  <div className={cx(styles.container, className)}></div>
);

export default Truck;
