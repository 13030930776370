import React from 'react';

const YourStyle = () => (
  <svg
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      id="your_style_36pt_a"
      gradientUnits="userSpaceOnUse"
      x1="6.828"
      x2="29.5682"
      y1="5.5922"
      y2="30.8478"
    >
      <stop offset="0" stopColor="#fff" stopOpacity="0" />
      <stop offset="1" stopColor="#fff" stopOpacity=".2" />
    </linearGradient>
    <circle cx="18" cy="18" fill="#046057" r="18" />
    <circle cx="18" cy="18" fill="url(#your_style_36pt_a)" r="17" />
    <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="m27.0911 12.9221c-1.3067-1.3077-2.6135 1.085-2.6135 1.085s-1.3067-2.3927-2.6134-1.085c-1.9217 1.9232 2.6533 4.8591 2.6533 4.8591s4.7156-2.7155 2.5736-4.8591z" />
      <path d="m15.1955 15.8327a10.5851 10.5851 0 0 1 4.936-5.9269" />
      <path d="m18.5385 16.6038a3.7624 3.7624 0 0 0 -2.4-2.8949" />
      <circle cx="16.5609" cy="21.8299" r="2.0672" />
      <circle cx="12.4662" cy="21.2593" r="2.0672" />
      <circle cx="15.0061" cy="17.9857" r="2.0672" />
      <circle cx="10.844" cy="17.1853" r="2.0672" />
      <circle cx="14.0119" cy="25.1201" r="2.0672" />
      <circle cx="19.4325" cy="18.6164" r="2.0672" />
      <path d="m16.3639 9.0445a3.2819 3.2819 0 0 1 1.0438 2.77 3.2708 3.2708 0 0 1 -3.889-3.5645 3.2634 3.2634 0 0 1 2.8452.7945z" />
    </g>
  </svg>
);

export default YourStyle;
