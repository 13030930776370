import { Anchor, AnchorStyle, getWindow } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { track } from 'vivino-js/analytics';
import { deleteReview } from 'vivino-js/api/reviews';
import t from 'vivino-js/translationString';

import CommunityReviewsTrackingContext from 'vivino-ui/components/CommunityReviews/CommunityReviewsTrackingContext';

import styles from './moreActionsMenu.scss';

const TRANSLATIONS_PATH = 'components.shared.community_reviews';
const TRANSLATIONS = {
  editReview: `${TRANSLATIONS_PATH}.edit`,
  deleteReview: `${TRANSLATIONS_PATH}.delete`,
  confirmDeleteReview: `${TRANSLATIONS_PATH}.confirm_delete_review`,
};

const MoreActionsMenu = ({ review, onOpenReviewEditor, onReviewDeleted, onMenuItemSelected }) => {
  const trackedFrom = useContext(CommunityReviewsTrackingContext);

  const actions = [
    {
      label: t(TRANSLATIONS.editReview),
      onSelect: onOpenReviewEditor,
    },
    {
      label: t(TRANSLATIONS.deleteReview),
      onSelect: async () => {
        if (getWindow().confirm(t(TRANSLATIONS.confirmDeleteReview))) {
          const reviewId = review.id;
          await deleteReview({ reviewId });
          track({
            event: 'User review - delete review',
            props: { trackedFrom },
          });
          onReviewDeleted(reviewId);
        }
      },
    },
  ];

  const getMenuItemClickHandler = (action) => {
    return () => {
      action.onSelect();
      onMenuItemSelected();
    };
  };

  return (
    <ul className={styles.menuList}>
      {actions.map((action, index) => {
        return (
          <li key={index}>
            <Anchor
              onClick={getMenuItemClickHandler(action)}
              anchorStyle={AnchorStyle.Base}
              className={styles.menuItem}
            >
              {action.label}
            </Anchor>
          </li>
        );
      })}
    </ul>
  );
};

MoreActionsMenu.propTypes = {
  review: PropTypes.object.isRequired,
  onOpenReviewEditor: PropTypes.func.isRequired,
  onReviewDeleted: PropTypes.func.isRequired,
  onMenuItemSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MoreActionsMenu;
