import { PriceType } from '@webtypes/common';
import { BasicFood, BasicWineType, FullMerchant } from '@webtypes/goApi';
import { AnyCountry, AnyGrape, AnyStyle, RubyLibExploreVintages } from '@webtypes/rubyLibApi';
import { apiGet } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export const ORDER_DESC = 'desc';
export const ORDER_ASC = 'asc';

export const ORDER_BY_DISCOUNT = 'discount_percent';
export const ORDER_BY_RATINGS_COUNT = 'ratings_count';
export const ORDER_BY_PRICE = 'price';
export const ORDER_BY_RATING = 'ratings_average';
export const ORDER_BY_BEST_PICKS = 'best_picks';
export const ORDER_BY_VFM_SCORES = 'vfm_scores';

export const DEFAULT_ORDER_BY = ORDER_BY_BEST_PICKS;

export const ExplorerTopListFilters = {
  WSA: 'wsa',
};

export const MerchantType = {
  Vc: 'vc',
  Ppc: 'ppc',
  Xdo: 'xdo',
  All: 'all',
};

export type TypedMerchantType = 'vc' | 'ppc' | 'xdo' | 'all'; // kept old declaration for retro-compatibility
export enum GrapeFilter {
  GRAPE_FILTER_ANY = 'any',
  GRAPE_FILTER_BLEND = 'blend',
  GRAPE_FILTER_BLEND_INCLUSIVE = 'blend-inclusive',
  GRAPE_FILTER_BLEND_POSSIBLE = 'blend-possible',
  GRAPE_FILTER_VARIETAL = 'varietal',
}
export type Order = 'desc' | 'asc';
export type OrderBy =
  | 'price'
  | 'best_picks'
  | 'vfm_scores'
  | 'ratings_count'
  | 'ratings_average'
  | 'discount_percent';
export type TopListFilter = 'vc' | 'wsa' | 'none';

export interface Ordering {
  order: Order;
  orderBy: OrderBy;
}

export interface BaseFetchExploreVintagesParameters {
  countryCode?: string;
  countryState?: string;
  countryCodes?: string[];
  currencyCode?: string;
  discountPrices?: boolean;
  foodIds?: number[];
  grapeFilter?: GrapeFilter;
  grapeIds?: number[];
  maxRating?: number;
  merchantId?: number;
  merchantIds?: number[];
  merchantType?: TypedMerchantType;
  minRating?: number;
  minRatingsCount?: number;
  order?: Order;
  orderBy?: OrderBy;
  priceRangeMax?: number;
  priceRangeMin?: number;
  priceType?: PriceType;
  priceTypes?: PriceType[];
  regionIds?: number[];
  wineStyleIds?: number[];
  wineTypeIds?: number[];
  wineryIds?: number[];
  wineYears?: number[];
  vintageIds?: number[];
  wsaYear?: number;
  topListFilter?: TopListFilter;
  ratingPerWine?: boolean;
}

export interface FetchExploreVintagesParameters extends BaseFetchExploreVintagesParameters {
  vcOnly?: boolean;
  excludingVintageId?: number[];
  page?: number;
  perPage?: number;
}

export interface FetchUwppExploreVintagesParameters extends BaseFetchExploreVintagesParameters {
  uwppExclude?: boolean;
  uwppSort?: boolean;
}

const buildBaseExploreVintageParams = ({
  countryCode = undefined, // set in rails
  countryCodes = [],
  currencyCode,
  discountPrices,
  foodIds = [],
  grapeFilter = undefined,
  grapeIds,
  maxRating,
  merchantId,
  merchantType = undefined,
  minRating,
  minRatingsCount,
  order = undefined,
  orderBy = undefined,
  priceType = undefined,
  priceTypes = [],
  priceRangeMax,
  priceRangeMin,
  regionIds = [],
  wineStyleIds = [],
  wineTypeIds = [],
  wineryIds = [],
  wineYears = [],
  vintageIds = [],
  wsaYear = undefined,
  topListFilter = undefined,
  countryState,
}: BaseFetchExploreVintagesParameters) => [
  { name: 'country_code', value: countryCode },
  { name: 'country_codes', value: countryCodes },
  { name: 'currency_code', value: currencyCode },
  { name: 'discount_prices', value: discountPrices },
  { name: 'food_ids', value: foodIds },
  { name: 'grape_ids', value: grapeIds },
  { name: 'grape_filter', value: grapeFilter },
  { name: 'max_rating', value: maxRating },
  { name: 'merchant_id', value: merchantId },
  { name: 'merchant_type', value: merchantType },
  { name: 'min_rating', value: minRating },
  { name: 'min_ratings_count', value: minRatingsCount },
  { name: 'order_by', value: orderBy },
  { name: 'order', value: order },
  { name: 'price_range_max', value: priceRangeMax },
  { name: 'price_range_min', value: priceRangeMin },
  { name: 'price_types', value: priceTypes },
  { name: 'price_type', value: priceType },
  { name: 'region_ids', value: regionIds },
  { name: 'wine_style_ids', value: wineStyleIds },
  { name: 'wine_type_ids', value: wineTypeIds },
  { name: 'winery_ids', value: wineryIds },
  { name: 'vintage_ids', value: vintageIds },
  { name: 'wine_years', value: wineYears },
  { name: 'wsa_year', value: wsaYear },
  { name: 'top_list_filter', value: topListFilter },
  { name: 'state', value: countryState },
];

export function fetchExplorePriceRange() {
  return apiGet({ url: '/api/explore/price_range' });
}

interface SelectedFilters {
  items: AnyGrape[] | AnyStyle[] | BasicWineType[] | AnyCountry[] | BasicFood[] | FullMerchant;
  type: string;
}
export interface ExploreVintagesResult {
  e: string;
  selected_filters: SelectedFilters;
  explore_vintage: RubyLibExploreVintages;
}

export function fetchExploreVintages(
  {
    page = 1,
    perPage = undefined,
    vcOnly = false,
    merchantIds = [],
    excludingVintageId = undefined,
    ...rest
  }: FetchExploreVintagesParameters,
  options = {}
): Promise<ExploreVintagesResult> {
  // Why don't we consolidate with explorerUrl() from vivinoUrls?
  const params = [
    ...buildBaseExploreVintageParams(rest),
    { name: 'page', value: page },
    { name: 'per_page', value: perPage },
    { name: 'excluding_vintage_id', value: excludingVintageId },
  ];
  if (vcOnly) {
    params.push({ name: 'vc_only', value: true });
  }
  if (merchantIds.length > 0) {
    params.push({ name: 'merchant_ids', value: merchantIds });
  }
  if (rest.ratingPerWine) {
    params.push({ name: 'rating_per_wine', value: true });
  }
  const url = generateQueryUrl({
    baseUrl: '/api/explore/explore',
    params,
  });

  return apiGet({ url, options });
}

export function fetchUwppExploreVintages(
  { uwppExclude = true, uwppSort = true, merchantIds, ...rest }: FetchUwppExploreVintagesParameters,
  options?: any
) {
  const params = [
    ...buildBaseExploreVintageParams(rest),
    { name: 'uwpp_exclude', value: uwppExclude },
    { name: 'uwpp_sort', value: uwppSort },
  ];
  if (rest?.ratingPerWine) {
    params.push({ name: 'rating_per_wine', value: true });
  }
  if (merchantIds?.length > 0) {
    params.push({ name: 'merchant_ids', value: merchantIds });
  }

  const url = generateQueryUrl({
    baseUrl: '/api/explore/explore_uwpp',
    params,
  });
  return apiGet({ url, options });
}

export function fetchExploreVintagesByQueryString(
  exploreQueryString,
  options = {}
): Promise<ExploreVintagesResult> {
  return apiGet({
    url: `/api/explore/explore?${exploreQueryString}`,
    options,
  });
}

export function calculateNumberOfActiveFilters(
  countryCodes: string[],
  foodIds: number[],
  grapeIds: number[],
  regionIds: number[],
  wineStyleIds: number[],
  wineTypeIds: number[],
  minRating: number,
  discountPrices: boolean,
  topListFilter: string
) {
  const priceRangeFilter = 1; //price range is always active
  const filterArrays = [countryCodes, foodIds, grapeIds, regionIds, wineStyleIds, wineTypeIds];
  const singularFilters = [discountPrices, topListFilter];
  const minRatingFilter = minRating > 1 ? 1 : 0;
  const activeArrayFilters = filterArrays.reduce((sum, array) => sum + array?.length, 0);
  const activeSingularFilters = singularFilters.filter((singularFilter) => !!singularFilter);
  return priceRangeFilter + activeArrayFilters + minRatingFilter + activeSingularFilters.length;
}
