import { Alert, AlertTheme, Snackbar, useBreakpoint } from '@vivino/js-react-common-ui';
import React from 'react';

interface CouponReminderPresentationalProps {
  isReminderVisible: boolean;
  reminderCopy: string;
  actionCopy?: string;
  onCtaClick?: () => void;
  onClose: () => void;
  className?: string;
}

interface ReminderProps {
  reminderCopy: string;
  onClose: () => void;
  subdued: boolean;
  className?: string;
  action?: string;
  onClick?: () => void;
}

const Reminder = ({ reminderCopy, onClose, onClick, ...rest }: ReminderProps) => {
  return (
    <Alert
      fullWidth
      theme={AlertTheme.Positive}
      onClose={onClose}
      ButtonProps={{ onClick }}
      {...rest}
    >
      {reminderCopy}
    </Alert>
  );
};

const CouponReminderPresentational = ({
  isReminderVisible,
  reminderCopy,
  actionCopy,
  onCtaClick,
  onClose,
  className,
}: CouponReminderPresentationalProps) => {
  const { isDesktop } = useBreakpoint();

  let reminderProps: ReminderProps = {
    reminderCopy,
    onClose,
    subdued: isDesktop,
  };

  if (actionCopy && onCtaClick) {
    reminderProps = {
      ...reminderProps,
      action: actionCopy,
      onClick: onCtaClick,
    };
  }

  return (
    <>
      {isDesktop && isReminderVisible ? (
        <Reminder {...reminderProps} className={className} />
      ) : (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isReminderVisible}>
          <Reminder {...reminderProps} />
        </Snackbar>
      )}
    </>
  );
};

export default CouponReminderPresentational;
