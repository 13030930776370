import { useEffect, useRef, useState } from 'react';
import uuidv4 from 'uuid/v4';
import { getCurrentEnv } from 'vivino-js/helpers/environmentHelper';

const webSocketOrigin = (environment: string) => {
  if (environment === 'production') {
    return 'wss://api.vivino.com';
  }

  return 'wss://api.testing.vivino.com';
};

type webSocketCallback = (data: { request_id: string; payload: unknown }) => unknown;

interface UseWebSocketArgs {
  path: string;
  callback?: webSocketCallback;
  shouldReconnectOnSend?: boolean;
}

interface webSocketDetail {
  webSocket: WebSocket;
  callbacks: webSocketCallback[];
  activeClientIds: { [key: string]: boolean };
}

// To have as few WebSocket connections as possible we use a map to keep track of the open connections
// as well as the callbacks for that url
let webSocketDetailsByUrl = {} as { [key: string]: webSocketDetail };

// This function is mainly used for testing purposes to allow resetting the connection between tests
export const resetAllWebSocketDetails = () => (webSocketDetailsByUrl = {});

export const useWebSocket = ({
  path,
  callback,
  shouldReconnectOnSend = true,
}: UseWebSocketArgs) => {
  const webSocket = useRef<WebSocket | null>(null);
  const clientId = useRef(uuidv4());
  const [reconnectCount, setReconnectCount] = useState(0);

  useEffect(() => {
    const url = `${webSocketOrigin(getCurrentEnv())}${path}`;
    let webSocketDetail = webSocketDetailsByUrl[url];

    if (webSocketDetail) {
      // If the WebSocket is already established we push the callback to the callbacks for that url.
      webSocketDetail.callbacks.push(callback);
      webSocketDetail.activeClientIds[clientId.current] = true;
    } else {
      // Otherwise establish the connection and add the callback and connection to the global maps
      const newWebSocket = new WebSocket(url);
      webSocketDetailsByUrl[url] = {
        webSocket: newWebSocket,
        callbacks: [callback],
        activeClientIds: { [clientId.current]: true },
      };

      webSocketDetail = webSocketDetailsByUrl[url];

      // Then set the onmessage handler to call all callbacks for the url
      newWebSocket.onmessage = (message: WebSocketEventMap['message']) => {
        webSocketDetail.callbacks.forEach((callback) => callback(JSON.parse(message.data)));
      };

      // When the connection closes delete the callbacks and websocket connection for that url
      newWebSocket.onclose = () => {
        if (webSocketDetailsByUrl[url]) {
          delete webSocketDetailsByUrl[url];
        }
      };
    }

    webSocket.current = webSocketDetail.webSocket;

    return () => {
      delete webSocketDetail.activeClientIds[clientId.current];
      if (Object.keys(webSocketDetail.activeClientIds).length === 0) {
        webSocketDetail.webSocket.close();
      }
    };
  }, [reconnectCount, path]);

  const sendPayload = ({ requestId, payload }: { requestId: string; payload: Object }): void => {
    const hasClosed = webSocket.current?.readyState === WebSocket.CLOSED;
    const isOpen = webSocket.current?.readyState === WebSocket.OPEN;

    if (shouldReconnectOnSend && hasClosed) {
      reconnect();
      return;
    }

    if (!isOpen) {
      return;
    }

    const requestMsg = {
      id: requestId,
      payload,
    };

    webSocket.current.send(JSON.stringify(requestMsg));
  };

  const reconnect = () => {
    setReconnectCount((prev) => prev + 1);
  };

  return { sendPayload };
};
