import PropTypes from 'prop-types';
import React from 'react';

const LikeOffIcon = ({ className }) => (
  <svg className={className} width="20px" height="20px" viewBox="0 0 20 20">
    <path
      fill="none"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M2,15.6h2.2c0.3,0,0.5-0.2,0.5-0.5V7.5C4.7,7.2,4.4,7,4.2,7H2"
    />
    <path
      fill="none"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M6.1,6.8l3.7-5.3c0,0,0.9-1.2,2.1-0.4s0.8,1.9,0.8,1.9L11.1,6c0,0-0.2,0.4,0.1,0.6c0.3,0.2,0.7,0.3,0.7,0.3
			h4.7c0,0,1.5,0.1,1.5,1.4s-1.3,1.6-1.3,1.6s1.3,0.1,1.3,1.4s-2.1,1.5-2.1,1.5s1.2,0.1,1.2,1.4s-1.5,1.5-1.5,1.5H9.3
			c0,0-3.1-0.5-3.2-2.6"
    />
  </svg>
);

LikeOffIcon.propTypes = {
  className: PropTypes.string, // WARNING: do not use classname to change icon size, use different sized icon for purpose
};

export default LikeOffIcon;
