export enum Environment {
  Production = 'production', // www.vivino.com
  Stable = 'stable', // stable.vivino.com
  Testing = 'testing', // testing.vivino.com
  Development = 'development', // localhost
  Test = 'test', // env for unit tests
}

export const isNextJS = (): boolean => !!process.env.NEXT_PUBLIC_RUBY_WEB_HOST;

/*
 * getEnvironment()
 * reason for globalThis.__PRELOADED_ENV__ returned by ternary operator is because there is no webpack process env defined
 * for testing environment on ruby-web so in case this shared helper is used in ruby-web and we return process.env.NODE_ENV
 * it will actually return production env instead of testing, hence we use globalThis.__PRELOADED_ENV__ instead
 */
export const getEnvironment = (): Environment =>
  ((isNextJS()
    ? process.env.NEXT_PUBLIC_ENVIRONMENT
    : globalThis.__PRELOADED_ENV__) as Environment) || Environment.Development;

export const isProduction = (): boolean => getEnvironment() === Environment.Production;

export const isTesting = (): boolean => getEnvironment() === Environment.Testing;

export const isDevelopment = (): boolean => getEnvironment() === Environment.Development;

export const isTest = (): boolean => getEnvironment() === Environment.Test;

const PR_DEPLOY_REGEXP = /^pr\d+-(ruby|js)-web\.testing\.vivino\.com$/i;

export const isPrDeploy = (): boolean =>
  isNextJS()
    ? process.env.NEXT_PUBLIC_PR_DEPLOY === 'true'
    : isTesting() && PR_DEPLOY_REGEXP.test(window?.location?.hostname);
