import React from 'react';

const Comment = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enableBackground="new 0 0 20 20"
    xmlSpace="preserve"
    width="20px"
    height="20px"
  >
    <path
      fill="none"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M10,2c4.4,0,8,3.6,8,8c0,2.1-0.8,4-2.1,5.4L18,18l-4.4-0.9C12.5,17.7,11.3,18,10,18c-4.4,0-8-3.6-8-8
      S5.6,2,10,2z"
    />
  </svg>
);

export default Comment;
