// Similarly to Array.join, join React components with a delimiter in between
// Ref. https://stackoverflow.com/questions/34034038/how-to-render-react-components-by-using-map-and-join
const JoinWithDelimiter = (items = [], delimiter = ', ') => {
  return items.reduce(
    (memo, item, index) => (index > 0 ? memo.concat(delimiter, item) : memo.concat(item)),
    []
  );
};

export default JoinWithDelimiter;
