import PropTypes from 'prop-types';
import React from 'react';

const More = ({ className }) => (
  <svg data-name="navigation" width="24" height="24" viewBox="0 0 24 24" className={className}>
    <g>
      <path d="M12,14.25c2.8955,0,2.9-4.5,0-4.5-2.8955,0-2.9,4.5,0,4.5Z" fill="#a8a5a3" />
      <path d="M4.5,14.25c2.8955,0,2.9-4.5,0-4.5-2.8955,0-2.9,4.5,0,4.5Z" fill="#a8a5a3" />
      <path d="M19.5,14.25c2.8955,0,2.9-4.5,0-4.5-2.8955,0-2.9,4.5,0,4.5Z" fill="#a8a5a3" />
    </g>
  </svg>
);

More.propTypes = {
  className: PropTypes.string,
};

export default More;
