import { isDefaultVintageYear } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import t from 'vivino-js/translationString';

import styles from './reviewedVintageYear.scss';

const TRANSLATIONS = {
  wineVintage: 'components.shared.review_card.wine_vintage',
};

const ReviewedVintageYear = ({ review, vintageYear, className }) => {
  const reviewedVintageYear = review.vintage?.year;

  if (
    !reviewedVintageYear ||
    isDefaultVintageYear(reviewedVintageYear) ||
    String(vintageYear) === String(reviewedVintageYear)
  ) {
    return null;
  }

  return (
    <span className={cx(styles.vintageText, className)}>
      <span>
        {t(TRANSLATIONS.wineVintage, {
          year: reviewedVintageYear,
        })}
      </span>
    </span>
  );
};

ReviewedVintageYear.propTypes = {
  review: PropTypes.object.isRequired,
  vintageYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

export default ReviewedVintageYear;
