import {
  SignInActions,
  USER_DEFAULT_IMAGE,
  USER_SMALLER_DEFAULT_IMAGE,
  getAnonymousTrackingId,
  getAvatarUrl,
  getCampaignUserId,
  getCurrentUser,
  getImageUrl,
  getPreferredUserId,
  getUniqueTrackingId,
  isCurrentUser,
  isPrivateUser,
  isSignedIn,
  isUserFromCampaignOrSignedIn,
  processSignInCallbacks,
  promptSignIn,
  sanitizedUserName,
  setPromptFunction,
} from '@vivino/js-web-common';

export {
  SignInActions,
  USER_DEFAULT_IMAGE,
  USER_SMALLER_DEFAULT_IMAGE,
  getAnonymousTrackingId,
  getAvatarUrl,
  getCampaignUserId,
  getCurrentUser,
  getImageUrl,
  getPreferredUserId,
  getUniqueTrackingId,
  isCurrentUser,
  isPrivateUser,
  isSignedIn,
  isUserFromCampaignOrSignedIn,
  processSignInCallbacks,
  promptSignIn,
  sanitizedUserName,
  setPromptFunction,
};
