import { ComponentSize } from '@webtypes/common';
import cx from 'classnames';
import React from 'react';

import * as images from './images';
import styles from './superWineCardSection.scss';

export interface SuperWineCardOptions {
  message?: string;
  backgroundImage?: string;
}

interface SuperWineCardSectionProps {
  className?: string;
  size?: ComponentSize;
  withinWineCard?: boolean;
  superWineCardOptions: SuperWineCardOptions;
}

const SuperWineCardSection = ({
  className,
  size = ComponentSize.Base,
  withinWineCard = false,
  superWineCardOptions,
}: SuperWineCardSectionProps) => {
  const backgroundImage = superWineCardOptions.backgroundImage
    ? `url(${superWineCardOptions.backgroundImage})`
    : `url(${images.vivinoGrapes})`;

  const imageStyle = {
    backgroundImage,
  };

  return (
    <div className={cx(styles.superWineCardSection, styles[size], className)}>
      <div className={styles.superWineCardSectionBackground} style={imageStyle}></div>
      <div className={cx(styles.superWineCardSectionContent)}>
        <div className={cx(styles.superWineCardSectionContentText)}>
          <div className={styles.message}>{superWineCardOptions.message}</div>
        </div>
      </div>
      {withinWineCard && <div className={styles.bottomMask}></div>}
    </div>
  );
};

export default SuperWineCardSection;
