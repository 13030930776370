import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const Sentinel = ({ id, options, onIntersect, onIntersectLeave }) => {
  const targetRef = useRef();

  const handleIntersection = (entries) => {
    if (entries[0].isIntersecting) {
      return onIntersect?.();
    }

    onIntersectLeave?.();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, options);
    const target = targetRef.current;
    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, []);

  // IE requires height to make scroll work
  const style = id
    ? {
        display: 'inline-block',
        height: '200px',
      }
    : undefined;
  return (
    <span
      id={id} // needed for scrolling to this element via anchor tag
      style={style}
      data-testid="sentinel"
      ref={targetRef}
    />
  );
};

Sentinel.propTypes = {
  id: PropTypes.string,
  options: PropTypes.object,
  onIntersect: PropTypes.func.isRequired,
  onIntersectLeave: PropTypes.func,
};

export default Sentinel;
