import { Anchor } from '@vivino/js-web-common';
import cx from 'classnames';
import React from 'react';
import { isPrivateUser } from 'vivino-js/helpers/user';
import { userReviewUrl } from 'vivino-js/vivinoUrls';

import styles from './reviewAnchor.scss';

interface ReviewAnchorProps {
  children?: React.ReactNode;
  review: { id: number; user: { id: number } };
  className?: string;
}

const ReviewAnchor = ({ children, review, className }: ReviewAnchorProps) => {
  const { id, user } = review;
  if (!id || isPrivateUser(user)) {
    return <span className={className}>{children}</span>;
  }

  return (
    <Anchor href={userReviewUrl({ user, reviewId: id })} className={cx(styles.anchor, className)}>
      {children}
    </Anchor>
  );
};

export default ReviewAnchor;
