import { Badge, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { useI18N } from '@vivino/js-web-common';
import cx from 'classnames';
import React from 'react';

import Truck from '@components/icons/Truck';

import styles from './activeSubscriberBanner.scss';

const TRANSLATION_PATH = 'components.vivino_plus';
const TRANSLATIONS = {
  premium: 'common.premium',
  youHaveFreeShippingIncluded: `${TRANSLATION_PATH}.you_have_free_shipping_included`,
};

interface ActiveSubscriberBannerProps {
  className?: string;
}

const ActiveSubscriberBanner = ({ className }: ActiveSubscriberBannerProps) => {
  const { t } = useI18N();

  return (
    <div
      className={cx(styles.activeSubscriberBanner, className)}
      data-testid="vivinoPlusActiveSubscriberBanner"
    >
      <div className={styles.leftColumn}>
        <div className={styles.badge}>
          <Badge label={t(TRANSLATIONS.premium)} variant="outlined" size="medium" />
        </div>
        <div className={styles.freeShipping}>
          <Typography type={TypographyType.LabelLarge}>
            {t(TRANSLATIONS.youHaveFreeShippingIncluded)}
          </Typography>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <Truck />
      </div>
    </div>
  );
};

export default ActiveSubscriberBanner;
