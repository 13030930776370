import PropTypes from 'prop-types';
import React from 'react';

const GiroPayIcon = ({ className }) => (
  <svg
    height="32"
    viewBox="0 0 61 32"
    width="61"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <path d="m0 0h61v32h-61z" fill="#fff" />
      <path
        d="m53.8959687 4h-46.79193735c-2.26694437.00039178-4.10325473 1.85133734-4.10403135 4.13715424v15.72588746c.00077662 2.2850333 1.83708698 4.1361747 4.10403135 4.1369583h46.79193735c2.2667502-.0007836 4.1032547-1.851925 4.1040313-4.1369583v-15.72588746c-.0007766-2.2858169-1.8372811-4.13676246-4.1040313-4.13715424z"
        fill="#0f3365"
      />
      <path
        d="m32 6h-24.63251925c-1.30726877.00229744-2.36493781 1.03672078-2.36748075 2.3150559v15.3698882c.00254294 1.2779522 1.06021198 2.312567 2.36748075 2.3150559h24.63251925z"
        fill="#fffffe"
      />
      <path
        d="m49.9046407 12.64297 1.2835327 4.0527111h.0228227l1.2263785-4.0527111h2.0626254l-2.189028 5.7819572c-.5840269 1.5604847-1.0305325 3.0750728-2.921695 3.0750728-.4355819 0-.8592647-.0477243-1.2835328-.1433761l.1381066-1.6331882c.2744575.096261.5266775.1313943.8140094.1313943.5034648 0 .8932062-.3101068 1.0412611-.8584291l-2.4166698-6.3534309zm-12.2563721-.14297c1.6502563 0 2.5547763 1.6331882 2.5547763 3.134779 0 1.8358643-.9509456 3.3616219-2.7270194 3.3616219-.7217433 0-1.5010311-.273755-1.8907725-1.0127716h-.0230177v3.3729946h-2.0622353v-8.7136539h1.9020863v1.1206083h.0230177c.4123691-.8702078 1.2835328-1.2635783 2.2231646-1.2635783zm6.5746916 0c1.5240488 0 2.841523.6791073 2.841523 2.5744748v2.3124986c0 .4890223.0234079.9774353.1148937 1.4660514h-1.8909675c-.0111188-.332852-.0462306-.6551436-.0462306-.9770291h-.0224326c-.4695233.774556-1.0424314 1.1208114-1.8903823 1.1208114-1.1803429 0-2.2344783-.6078254-2.2344783-1.9552768 0-1.8951643 1.9822583-2.1224135 3.0824292-2.1224135.332392 0 .6987256.0361487.9622594.0842792-.0113138-.8466503-.6189437-1.0730871-1.3640949-1.0730871-.6641989 0-1.3285929.1313943-1.9020863.4648555l-.0339414-1.4540696c.7332522-.3105129 1.500836-.4410948 2.3835086-.4410948zm.0456454 3.5642981c-.6991157 0-1.2490061.2264368-1.2490061.8224835 0 .4285037.3667236.6799197.8477558.6799197.7798729 0 1.2722189-.7400321 1.2722189-1.4668638-.2635338-.0117787-.5613993-.0355394-.8709686-.0355394zm-7.4450751-1.9193312c-.7566601 0-1.2612952.6914954-1.2612952 1.6210033 0 .9063564.5734933 1.5854638 1.2612952 1.5854638.7787025 0 1.248616-.6791074 1.248616-1.6687276 0-.8228896-.4468957-1.5377395-1.248616-1.5377395z"
        fill="#fffffe"
      />
      <path
        d="m10.7088143 12.8490976c.8873702 0 1.6022507.4811805 2.0404413 1.2141755h.0225669v-1.0767511h1.8793331v5.2709268c0 2.4515803-1.1987935 3.2425512-3.6195363 3.2425512-.991178 0-1.8096701-.2067222-2.29397564-.377917l.10400402-1.6272293c.63383587.3094 1.16406022.4925023 2.02866722.4925023 1.1989898 0 1.8442073-.5612145 1.8442073-1.7299072v-.3215027h-.0233519c-.4952946.6998101-1.1868233 1.0197512-2.0166969 1.0197512-1.64856198 0-2.6744734-1.2364288-2.6744734-3.0016681 0-1.7761708.84164768-3.1049316 2.7088143-3.1049316zm16.798809 0c1.9821597 0 3.4923767 1.1454633 3.4923767 3.128161 0 1.9707902-1.510217 3.1162535-3.4923767 3.1162535-1.9825523 0-3.4927692-1.1454633-3.4927692-3.1162535 0-1.9826977 1.5102169-3.128161 3.4927692-3.128161zm-4.2755467 0c.2417603 0 .4952946.0343561.7029102.0681266l-.104004 1.7187805c-.2301825-.068517-.4609537-.068517-.702714-.068517-.9682186 0-1.510217.6990293-1.510217 1.8681124v2.5200972h-2.0747822v-5.9691753h1.8905185v1.09959h.0227631c.3575384-.7558339.8763811-1.2370144 1.7755254-1.2370144zm-5.0744154.1374244v5.9691753h-2.0747822v-5.9691753zm9.3499621 1.3063122c-.9568371 0-1.3487164.7905805-1.3487164 1.6844244 0 .8823269.3918793 1.6725169 1.3487164 1.6725169.9566408 0 1.3487163-.79019 1.3487163-1.6725169 0-.8938439-.3920755-1.6844244-1.3487163-1.6844244zm-16.1877363.1374244c-.772377 0-1.1758342.6759951-1.1758342 1.4892193 0 .9278096.5533799 1.4554489 1.1758342 1.4554489.7374475 0 1.3255608-.6186048 1.3255608-1.5356782 0-.6648684-.3920756-1.40899-1.3255608-1.40899zm6.8377742-3.9302586v1.5124488h-2.0747822v-1.5124488z"
        fill="#d8232a"
      />
    </g>
  </svg>
);

GiroPayIcon.propTypes = {
  className: PropTypes.string,
};

export default GiroPayIcon;
