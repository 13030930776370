import { apiGet } from '@vivino/js-web-common';

interface GetCurrenciesReturnType {
  currencies: Record<
    string,
    {
      name: string;
      prefix?: string;
      suffix?: string;
    }
  >;
}

export const getCurrencies = async (): Promise<GetCurrenciesReturnType> => {
  return await apiGet<GetCurrenciesReturnType>({
    url: '/api/currencies',
  });
};
