import PropTypes from 'prop-types';
import React from 'react';

const CreditCardIcon = ({ className }) => (
  <svg
    height="32"
    viewBox="0 0 48 32"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <rect fill="#e4e0da" height="30" rx="4" width="45" y="1" />
      <path
        d="m38 22c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-4c-.8284271 0-1.5-.6715729-1.5-1.5s.6715729-1.5 1.5-1.5zm-9 0c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-4c-.8284271 0-1.5-.6715729-1.5-1.5s.6715729-1.5 1.5-1.5zm-9 0c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-4c-.8284271 0-1.5-.6715729-1.5-1.5s.6715729-1.5 1.5-1.5zm-9 0c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-4c-.82842712 0-1.5-.6715729-1.5-1.5s.67157288-1.5 1.5-1.5z"
        fill="#a8a5a3"
      />
      <rect fill="#f79e1b" height="8" rx="1.417497" width="10" x="5" y="7" />
    </g>
  </svg>
);

CreditCardIcon.propTypes = {
  className: PropTypes.string,
};

export default CreditCardIcon;
