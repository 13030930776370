import {
  ALLOWED_METHODS,
  BAD_GATEWAY,
  BAD_REQUEST,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_PATCH,
  METHOD_POST,
  METHOD_PUT,
  NOT_FOUND,
  NO_CONTENT,
  SERVICE_UNAVAILABLE,
  UNAUTHORIZED,
  UNPROCESSABLE_ENTITY,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPostForm,
  apiPut,
} from '@vivino/js-web-common';

export {
  METHOD_GET,
  METHOD_PATCH,
  METHOD_POST,
  METHOD_PUT,
  METHOD_DELETE,
  apiPut,
  apiPatch,
  apiPost,
  apiPostForm,
  apiDelete,
  apiGet,
  ALLOWED_METHODS,
  SERVICE_UNAVAILABLE,
  BAD_GATEWAY,
  INTERNAL_SERVER_ERROR,
  UNPROCESSABLE_ENTITY,
  NOT_FOUND,
  FORBIDDEN,
  UNAUTHORIZED,
  BAD_REQUEST,
  NO_CONTENT,
};
