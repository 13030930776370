import { getCurrentLocale } from '@vivino/js-web-common';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import uuidv4 from 'uuid/v4';
import { Address, AddressAutocompleteValue, fetchAddress } from 'vivino-js/api/address';
import { AddressContext } from 'vivino-js/context/AddressContext/AddressContext';
import generateQueryUrl from 'vivino-js/generateQueryUrl';
import { useWebSocket } from 'vivino-js/webSocketClient';

import AddressFinderPresentational from './AddressFinder.presentational';

interface AddressFinderProps {
  countryCode: string;
  showInstruction?: boolean;
  addressFormType?: string;
  inputProps?: Record<string, any>;
  onAddressSelected?: (address: Address) => void;
}

const AddressFinder = ({
  countryCode,
  inputProps,
  addressFormType,
  onAddressSelected,
  showInstruction,
}: AddressFinderProps) => {
  const { addressFormatByCountry, updateCurrentAddressFields } = useContext(AddressContext);

  const [value, setValue] = useState<AddressAutocompleteValue | null>(null);
  const [inputValue, setInputValue] = useState(inputProps?.value || '');
  const [options, setOptions] = useState<AddressAutocompleteValue[]>([]);
  const socketId = useRef<string>(uuidv4());

  const autocompleteFinder = addressFormatByCountry?.[countryCode]?.autocomplete_finder;

  const path = generateQueryUrl({
    baseUrl: autocompleteFinder?.autocomplete_ws || '',
    params: [
      { name: 'country_code', value: countryCode },
      { name: 'language', value: getCurrentLocale() },
    ],
  });

  const autoCompleteCallback = (data: {
    request_id: string;
    payload: AddressAutocompleteValue[];
  }) => {
    if (socketId.current === data.request_id) {
      setOptions(data.payload || []);
    }
  };

  const addressSocket = useWebSocket({
    path,
    shouldReconnectOnSend: true,
    callback: autoCompleteCallback,
  });

  useEffect(() => {
    if (inputValue) {
      const payload = { value: inputValue };
      addressSocket?.sendPayload({ requestId: socketId.current, payload });
    }
  }, [inputValue]);

  useEffect(() => {
    setInputValue(inputProps?.value || '');
  }, [inputProps?.value]);

  // Triggers when an option is selected
  const handleChange = async (_event: ChangeEvent, value: AddressAutocompleteValue) => {
    if (value?.address_id) {
      setValue(value);
      const { address } = await fetchAddress({
        countryCode: countryCode,
        addressId: value.address_id,
      });
      onAddressSelected?.(address);
      updateCurrentAddressFields({ addressFormType, updateFields: address });
    } else if (value?.address_type) {
      setValue(value);
      const payload = { value: value.display_text };
      addressSocket.sendPayload({ requestId: socketId.current, payload });
    } else if (value?.drilldown_id) {
      const payload = {
        value: inputValue,
        drilldown_id: value.drilldown_id,
      };
      addressSocket.sendPayload({ requestId: socketId.current, payload });
    }
  };

  const handleInputChange = (_event, newInputValue, reason) => {
    /*
     * Update inputValue only if it was user input by users (non-programmatic changes)
     * or updated value starts with previous input value
     * see onInputChange section in https://mui.com/api/autocomplete/
     */
    const isNewInputStartsWithPreviousInput =
      !!inputValue && newInputValue?.toLowerCase()?.startsWith(inputValue.toLowerCase());
    if (reason === 'input' || isNewInputStartsWithPreviousInput) {
      setInputValue(newInputValue);
    } else if (reason === 'clear') {
      setInputValue('');
    }
  };

  return (
    <AddressFinderPresentational
      value={value}
      options={options}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      showInstruction={showInstruction}
      autocompleteFinder={autocompleteFinder}
      inputProps={inputProps}
    />
  );
};

export default AddressFinder;
