import React from 'react';

const Wishlisted = () => (
  <svg
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      id="wishlisted_36pt_a"
      gradientUnits="userSpaceOnUse"
      x1="6.828"
      x2="29.5682"
      y1="5.5922"
      y2="30.8478"
    >
      <stop offset="0" stopColor="#fff" stopOpacity="0" />
      <stop offset="1" stopColor="#fff" stopOpacity=".2" />
    </linearGradient>
    <circle cx="18" cy="18" fill="#ff7a90" r="18" />
    <circle cx="18" cy="18" fill="url(#wishlisted_36pt_a)" r="17" />
    <path
      d="m23.9063 25.875v-14.0935a1.6119 1.6119 0 0 0 -1.5529-1.6565h-8.7067a1.6119 1.6119 0 0 0 -1.5529 1.6565v14.0935l5.9062-4.725z"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Wishlisted;
