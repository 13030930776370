export function truncate({ text, length }: { text: string; length: number }): string {
  if (!text) {
    return text;
  }
  const slicedTex = text.slice(0, length);
  if (slicedTex.length < length) {
    return slicedTex;
  }
  return `${slicedTex}...`;
}

export function isMatch(expected: string, actual: string): boolean {
  if (typeof actual !== 'string' || typeof expected !== 'string') {
    return false;
  }
  return actual.toUpperCase() === expected.toUpperCase();
}

export function upperFirst(string: string): string {
  if (!string) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const insertStringAtIndex = (text: string, string: string, index: number): string => {
  return text.slice(0, index) + string + text.slice(index);
};
