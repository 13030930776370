import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

/**
 * If message is null, don't show the prompt. Otherwise, attempt to
 * disaply a custom mesage in a yes/no native dialog.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 */
const PromptBeforeUnload = ({ promptMessage }) => {
  /**
   * Custom messages won't work in most browsers
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
   */
  const handleBeforeUnload = (event) => {
    // Ensure 'event.returnValue' property is set for legacy
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    if (promptMessage) {
      event.preventDefault();
      return (event.returnValue = promptMessage);
    }

    return (event.returnValue = undefined);
  };

  useEffect(() => {
    if (promptMessage) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    /**
     * Clean up the listener when this component gets unmounted
     */
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [promptMessage]);

  return <></>;
};

PromptBeforeUnload.propTypes = {
  promptMessage: PropTypes.string,
};

export default PromptBeforeUnload;
