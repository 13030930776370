export const scaleToRotation = (position: number) => {
  if (position >= 0.95) {
    return 155;
  } else if (position === 0.75) {
    return 115;
  } else if (position === 0.55) {
    return 80;
  } else if (position >= 0.35) {
    return 30;
  } else {
    return 0;
  }
};
