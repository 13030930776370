import PropTypes from 'prop-types';
import React from 'react';
import { SuperCardsHighlightTypes } from 'vivino-js/helpers/highlights';
import Magic from 'vivino-ui/icons/Magic/36';
import Wishlisted from 'vivino-ui/icons/Wishlisted/36';
import YourStyle from 'vivino-ui/icons/YourStyle/36';

const SUPERCARDS_HIGHLIGHT_ICON_BY_TYPE = {
  [SuperCardsHighlightTypes.BestFromYourWishList]: <Wishlisted />,
  [SuperCardsHighlightTypes.MLRecommendation]: <Magic />,
  [SuperCardsHighlightTypes.FavoriteRegionalStyle]: <YourStyle />,
};

const HIGHLIGHT_ICON_BY_TYPE = {
  award_winning: (
    <>
      <defs>
        <linearGradient
          id="award_winning_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#1d41a6" />
      <circle cx="18" cy="18" r="17" fill="url(#award_winning_36pt_a)" />
      <path
        d="M24.6087,14.4146c-.1324-.7265.2606-1.6617-.0965-2.3251-.3442-.64-1.33-.8227-1.8819-1.3511s-.7831-1.51-1.4351-1.8245c-.6756-.3255-1.5839.1167-2.3108.02-.7344-.0976-1.4978-.76-2.2261-.6252-.7216.1333-1.201,1.0223-1.8593,1.3814-.6358.3466-1.64.2576-2.1648.8135s-.3863,1.561-.6981,2.2173c-.3234.6805-1.181,1.2069-1.2769,1.939-.0969.7394.5982,1.4735.7317,2.2073.1324.7262-.2606,1.6614.0964,2.3248.3442.64,1.33.8227,1.8819,1.3514s.7832,1.51,1.4351,1.8245c.6756.3255,1.5839-.1168,2.3111-.02.7344.0977,1.4975.7594,2.2261.6249.7216-.1335,1.2007-1.0219,1.8593-1.3814.6358-.3462,1.64-.2576,2.1651-.8132s.3863-1.561.6979-2.2173c.3233-.68,1.1812-1.2069,1.2771-1.939C25.4373,15.8822,24.7422,15.1478,24.6087,14.4146Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18"
        cy="15.641"
        r="3.675"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="17.128 24.617 15.837 27.084 13.756 24.392 10.125 24.66 12.16 20.77"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="23.366 20.778 25.875 24.017 22.241 24.246 20.635 27.191 16.779 22.337"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  best_user_rated: (
    <>
      <defs>
        <linearGradient
          id="best_user_rated_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#cb8f60" />
      <circle cx="18" cy="18" r="17" fill="url(#best_user_rated_36pt_a)" />
      <path
        d="M19.1114,14.0153V9.2927a.4872.4872,0,0,0-.491-.48H17.38a.4872.4872,0,0,0-.491.48v4.7226"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8886,14.0153a3.0753,3.0753,0,0,0-1.9164,2.8485v9.3716a.9462.9462,0,0,0,.94.9521h4.1754a.9462.9462,0,0,0,.94-.9521V16.8638a3.0753,3.0753,0,0,0-1.9164-2.8485"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3419,14.8122v-3.778a.39.39,0,0,0-.3928-.3842h-.9926a.39.39,0,0,0-.3928.3842v3.778"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5637,14.8122a2.46,2.46,0,0,0-1.5331,2.2788v7.4973a.7569.7569,0,0,0,.752.7617h3.34a.7569.7569,0,0,0,.752-.7617V17.091a2.46,2.46,0,0,0-1.5331-2.2788"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4363,14.8122v-3.778a.39.39,0,0,0-.3928-.3842h-.9926a.39.39,0,0,0-.3928.3842v3.778"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6581,14.8122A2.46,2.46,0,0,0,10.125,17.091v7.4973a.7569.7569,0,0,0,.752.7617h3.34a.7569.7569,0,0,0,.752-.7617V17.091a2.46,2.46,0,0,0-1.5331-2.2788"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  cheapest_available: (
    <>
      <defs>
        <linearGradient
          id="cheapest_available_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#046057" />
      <circle cx="18" cy="18" r="17" fill="url(#cheapest_available_36pt_a)" />
      <path
        d="M26.3621,15.1479a1.2373,1.2373,0,0,0-.5756.1469,6.9122,6.9122,0,0,0-1.9523-2.91l.6248-2.1722a.4087.4087,0,0,0-.5768-.48l-2.33,1.1924A9.6624,9.6624,0,0,0,17.68,10.13c-4.6557,0-8.43,3.185-8.43,7.1138a6.4037,6.4037,0,0,0,1.7319,4.3178l.8567,3.3064a.5694.5694,0,0,0,.566.5694h2.2146a.5694.5694,0,0,0,.5661-.5694v-.8272a9.9773,9.9773,0,0,0,4.9885,0v.8272a.5694.5694,0,0,0,.5661.5694h2.2145a.5694.5694,0,0,0,.5661-.5694l.8565-3.3058a6.7764,6.7764,0,0,0,1.3553-2.2084,1.24,1.24,0,0,0,.63.1766,1.27,1.27,0,0,0,1.2629-1.27v-1.842A1.27,1.27,0,0,0,26.3621,15.1479Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7,18.799a2.4844,2.4844,0,0,0,2.0215,1.1446,2.1164,2.1164,0,0,0,1.4225-.4451c1.019-.8654.599-2.4118-1.2088-2.4118-.0687,0-.14-.0045-.2137-.0045a2.116,2.116,0,0,1-1.4226-.4451c-1.0189-.8654-.23-2.35,1.2088-2.4117q.1029-.0045.2138-.0045a2.4846,2.4846,0,0,1,2.0215,1.1445"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="17.7213"
        y1="12.9894"
        x2="17.7213"
        y2="21.175"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  critic_favorite: (
    <>
      <defs>
        <linearGradient
          id="critic_favorite_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#1d41a6" />
      <circle cx="18" cy="18" r="17" fill="url(#critic_favorite_36pt_a)" />
      <path
        d="M19.1406,18.1709a3.4205,3.4205,0,0,0-2.5634,1.0438,3.4207,3.4207,0,0,0-2.5634-1.0438c-2.386,0-2.0107,3.9985-6.2961,2.35.4875,1.6482,2.6248,2.9,5.0107,2.9a4.8567,4.8567,0,0,0,3.8488-1.5872,4.8564,4.8564,0,0,0,3.8487,1.5872c2.386,0,4.5233-1.2515,5.0107-2.9C21.1512,22.1694,21.5265,18.1709,19.1406,18.1709Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <line
        x1="27.4073"
        y1="23.7575"
        x2="27.4073"
        y2="13.6801"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="23.4698"
        cy="13.6973"
        r="3.9375"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.36,13.2664a1.6471,1.6471,0,0,1,1.68-1.6126"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  latest_available: (
    <>
      <defs>
        <linearGradient
          id="latest_available_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#3c9dff" />
      <circle cx="18" cy="18" r="17" fill="url(#latest_available_36pt_a)" />
      <path
        d="M19.1114,13.9916V9.2691a.4872.4872,0,0,0-.491-.48H17.38a.4872.4872,0,0,0-.491.48v4.7225"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8886,13.9916A3.0754,3.0754,0,0,0,14.9722,16.84v9.3716a.9462.9462,0,0,0,.94.9521h4.1754a.9462.9462,0,0,0,.94-.9521V16.84a3.0754,3.0754,0,0,0-1.9164-2.8486"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="12.4254"
        y1="18.3241"
        x2="8.8124"
        y2="18.3241"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="12.3123"
        y1="22.8017"
        x2="9.8149"
        y2="24.0601"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="13.6889"
        y1="14.4946"
        x2="11.1342"
        y2="11.9399"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="23.5746"
        y1="18.3241"
        x2="27.1876"
        y2="18.3241"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="23.6877"
        y1="22.8017"
        x2="26.1851"
        y2="24.0601"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="22.3111"
        y1="14.4946"
        x2="24.8658"
        y2="11.9399"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  most_user_rated: (
    <>
      <defs>
        <linearGradient
          id="most_user_rated_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#ff9550" />
      <circle cx="18" cy="18" r="17" fill="url(#most_user_rated_36pt_a)" />
      <path
        d="M14.6928,27.405a3.5567,3.5567,0,0,1,.3946-4.0259,9.2,9.2,0,0,0,1.3864-3.2119s2.2936,1.6361,1.947,3.4011c1.5309-1.7072.3927-4.9349.1617-5.9765,3.46,2.4225,4.9393,5.9254,2.9461,9.8132,10.6007-6.0088,2.6369-15,1.25-16.0124.4622,1.0128-.5729,2.1213-1.5066,2.9535C19.6919,8.3423,16.907,7.7175,16.907,7.7175a13.0268,13.0268,0,0,1-2.5207,9.5332c-.0725-1.2346-1.365-2.6079-2.0131-3.7894-.1454,2.2429-1.8563,4.0711-2.32,6.3183-.6274,3.0431.47,5.2714,4.6394,7.6254Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  oldest_available: (
    <>
      <defs>
        <linearGradient
          id="oldest_available_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#cb8f60" />
      <circle cx="18" cy="18" r="17" fill="url(#oldest_available_36pt_a)" />
      <path
        d="M20.2435,14.1424V10.621a.741.741,0,0,0-.7883-.6762h-2.91a.7409.7409,0,0,0-.7883.6762v3.5214c-1.478.3611-2.4822,1.4229-2.4822,4.5211,0,4.4977,3.6994,9.6177,4.7257,9.6177,1.0144,0,4.7257-5.12,4.7257-9.6177C22.7257,15.5653,21.7215,14.5035,20.2435,14.1424Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <path
        d="M15.7565,11.04s-4.4484-.2764-4.7349.43,2.3638,5.2816,2.3638,5.2816"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <path
        d="M20.2435,11.04s4.4484-.2764,4.7349.43-2.3638,5.2816-2.3638,5.2816"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <line
        x1="16.4698"
        y1="8.5938"
        x2="19.5302"
        y2="8.5938"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  price_discounted: (
    <>
      <defs>
        <linearGradient
          id="price_discounted_36pt_a"
          x1="6.2839"
          y1="4.9882"
          x2="29.716"
          y2="31.0122"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <path
        d="M34.9343,19.9787a4.0024,4.0024,0,0,1,0-3.9567l.7127-1.112a2.1058,2.1058,0,0,0-.819-3.0572l-1.1734-.6064A4.0027,4.0027,0,0,1,31.6763,7.82l.0613-1.32a2.1061,2.1061,0,0,0-2.2382-2.2382l-1.319.0613a4.0012,4.0012,0,0,1-3.4262-1.9783l-.6064-1.1733A2.1064,2.1064,0,0,0,21.09.3531l-1.1115.7132a4.0052,4.0052,0,0,1-3.9566,0L14.91.3531a2.1058,2.1058,0,0,0-3.0572.819l-.6064,1.1733A4.0021,4.0021,0,0,1,7.82,4.3237L6.5005,4.2624A2.1062,2.1062,0,0,0,4.2623,6.5006l.0614,1.32a4.0015,4.0015,0,0,1-1.9784,3.4262l-1.1728.6064A2.1056,2.1056,0,0,0,.353,14.91l.7132,1.112a4.0024,4.0024,0,0,1,0,3.9567L.353,21.09a2.1057,2.1057,0,0,0,.82,3.0572l1.1728.6063a4.0032,4.0032,0,0,1,1.9784,3.4268L4.2623,29.5a2.1057,2.1057,0,0,0,2.2382,2.2376L7.82,31.6764a4.0023,4.0023,0,0,1,3.4267,1.9783l.6064,1.1728a2.106,2.106,0,0,0,3.0572.82l1.1121-.7127a4.0022,4.0022,0,0,1,3.9566,0l1.1115.7127a2.1065,2.1065,0,0,0,3.0577-.82l.6064-1.1728A4.0014,4.0014,0,0,1,28.18,31.6764l1.319.0613A2.1056,2.1056,0,0,0,31.7376,29.5l-.0613-1.32a4.0044,4.0044,0,0,1,1.9783-3.4268l1.1734-.6063a2.1059,2.1059,0,0,0,.819-3.0572Z"
        fill="#007ac4"
        fillRule="evenodd"
      />
      <path
        d="M33.9935,19.8687a3.78,3.78,0,0,1,0-3.7368l.6731-1.05a1.9889,1.9889,0,0,0-.7735-2.8874l-1.1082-.5727a3.78,3.78,0,0,1-1.8684-3.2358L30.9744,7.14a1.9891,1.9891,0,0,0-2.1138-2.1139l-1.2458.0579A3.7788,3.7788,0,0,1,24.379,3.2151l-.5727-1.1082a1.99,1.99,0,0,0-2.8879-.7735l-1.05.6737a3.7826,3.7826,0,0,1-3.7368,0l-1.05-.6737a1.989,1.989,0,0,0-2.8874.7735l-.5727,1.1082A3.78,3.78,0,0,1,8.3852,5.0835L7.1394,5.0256A1.9892,1.9892,0,0,0,5.0255,7.14l.058,1.2463a3.7792,3.7792,0,0,1-1.8684,3.2358l-1.1077.5727a1.9887,1.9887,0,0,0-.774,2.8874l.6736,1.05a3.78,3.78,0,0,1,0,3.7368l-.6736,1.05a1.9885,1.9885,0,0,0,.774,2.8873l1.1077.5727a3.781,3.781,0,0,1,1.8684,3.2364l-.058,1.2463a1.9887,1.9887,0,0,0,2.1139,2.1133l1.2458-.0579a3.78,3.78,0,0,1,3.2363,1.8684l.5727,1.1077a1.9891,1.9891,0,0,0,2.8874.774l1.05-.6731a3.78,3.78,0,0,1,3.7368,0l1.05.6731a1.99,1.99,0,0,0,2.8879-.774l.5727-1.1077a3.779,3.779,0,0,1,3.2358-1.8684l1.2458.0579a1.9886,1.9886,0,0,0,2.1138-2.1133l-.0579-1.2463a3.7818,3.7818,0,0,1,1.8684-3.2364l1.1082-.5727a1.9888,1.9888,0,0,0,.7735-2.8873Z"
        fillRule="evenodd"
        fill="url(#price_discounted_36pt_a)"
      />
      <circle
        cx="22.3726"
        cy="21.1484"
        r="2.625"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13.6274"
        cy="14.6053"
        r="2.625"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="11.8916"
        y1="25.1007"
        x2="23.7298"
        y2="11.1008"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  price_qpr: (
    <>
      <defs>
        <linearGradient
          id="price_qpr_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#046057" />
      <circle cx="18" cy="18" r="17" fill="url(#price_qpr_36pt_a)" />
      <path
        d="M26.3621,15.1479a1.2373,1.2373,0,0,0-.5756.1469,6.9122,6.9122,0,0,0-1.9523-2.91l.6248-2.1722a.4087.4087,0,0,0-.5768-.48l-2.33,1.1924A9.6624,9.6624,0,0,0,17.68,10.13c-4.6557,0-8.43,3.185-8.43,7.1138a6.4037,6.4037,0,0,0,1.7319,4.3178l.8567,3.3064a.5694.5694,0,0,0,.566.5694h2.2146a.5694.5694,0,0,0,.5661-.5694v-.8272a9.9773,9.9773,0,0,0,4.9885,0v.8272a.5694.5694,0,0,0,.5661.5694h2.2145a.5694.5694,0,0,0,.5661-.5694l.8565-3.3058a6.7764,6.7764,0,0,0,1.3553-2.2084,1.24,1.24,0,0,0,.63.1766,1.27,1.27,0,0,0,1.2629-1.27v-1.842A1.27,1.27,0,0,0,26.3621,15.1479Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7,18.799a2.4844,2.4844,0,0,0,2.0215,1.1446,2.1164,2.1164,0,0,0,1.4225-.4451c1.019-.8654.599-2.4118-1.2088-2.4118-.0687,0-.14-.0045-.2137-.0045a2.116,2.116,0,0,1-1.4226-.4451c-1.0189-.8654-.23-2.35,1.2088-2.4117q.1029-.0045.2138-.0045a2.4846,2.4846,0,0,1,2.0215,1.1445"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="17.7213"
        y1="12.9894"
        x2="17.7213"
        y2="21.175"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  top_listed: (
    <>
      <defs>
        <linearGradient
          id="top_listed_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#ff7a90" />
      <circle cx="18" cy="18" r="17" fill="url(#top_listed_36pt_a)" />
      <path
        d="M19.1815,10.5625H11.0368A1.5551,1.5551,0,0,0,9.5125,12.138v9.333a1.5857,1.5857,0,0,0,.7254,1.3347h-.0055l4.8767,3.5068,4.8768-3.5068H19.98a1.5856,1.5856,0,0,0,.7255-1.3347V12.138A1.5551,1.5551,0,0,0,19.1815,10.5625Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <path
        d="M21.2548,23.55l2.0325,1.34,3.5893-2.3664h-.0037a1.07,1.07,0,0,0,.49-.9007V14.4105a1.0494,1.0494,0,0,0-1.0286-1.0632H22.0286"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <polyline
        points="15.291 19.809 15.291 13.865 13.827 15"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
      <line
        x1="13.4608"
        y1="19.8092"
        x2="17.1216"
        y2="19.8092"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.64,17.7612a.9267.9267,0,0,1,1.8432.1933c-.0453.4089-.3616.69-.7373,1.078a5.4779,5.4779,0,0,1-1.1059.7767h2.2118"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
    </>
  ),
  top_ranked: (
    <>
      <defs>
        <linearGradient
          id="top_ranked_36pt_a"
          x1="6.828"
          y1="5.5922"
          x2="29.5682"
          y2="30.8478"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <circle cx="18" cy="18" r="18" fill="#f1a90d" />
      <circle cx="18" cy="18" r="17" fill="url(#top_ranked_36pt_a)" />
      <path
        d="M23.27,15.8194a.6142.6142,0,0,0,.144-.6114.5672.5672,0,0,0-.4548-.4057l-4.5032-.6868a.4931.4931,0,0,1-.3729-.2852l-2.0128-4.275a.5678.5678,0,0,0-1.0071,0l-2.0128,4.278a.4984.4984,0,0,1-.3721.2818l-4.5032.6868a.5676.5676,0,0,0-.4549.405.6133.6133,0,0,0,.144.6113l3.2584,3.33a.5291.5291,0,0,1,.1388.4573l-.7672,4.6966a.6052.6052,0,0,0,.223.582.5313.5313,0,0,0,.5907.0478l4.0277-2.2205a.4752.4752,0,0,1,.4667.0023l4.024,2.2174a.5363.5363,0,0,0,.5922-.047.6079.6079,0,0,0,.2223-.582L19.8716,19.6a.5222.5222,0,0,1,.1373-.4473Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0043,24.2028l1.3646-.7548a.3125.3125,0,0,1,.3079.0015l2.6557,1.4683a.3529.3529,0,0,0,.3908-.0311.4033.4033,0,0,0,.1467-.3854l-.5078-3.114a.3465.3465,0,0,1,.0907-.2962l2.1523-2.2071a.4075.4075,0,0,0,.095-.4048.3745.3745,0,0,0-.3-.2686l-2.972-.4548a.3257.3257,0,0,1-.2461-.1889l-.6453-1.3753"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  wsa_winning: (
    <>
      <defs>
        <linearGradient
          id="wsa_winning_36pt_a"
          x1="6.2839"
          y1="4.9882"
          x2="29.716"
          y2="31.0122"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity="0.2" />
        </linearGradient>
      </defs>
      <path
        d="M34.9343,19.9787a4.0022,4.0022,0,0,1,0-3.9566l.7126-1.1121a2.1058,2.1058,0,0,0-.819-3.0572l-1.1733-.6064A4.0022,4.0022,0,0,1,31.6763,7.82l.0613-1.32a2.1061,2.1061,0,0,0-2.2382-2.2382l-1.319.0613a4.0014,4.0014,0,0,1-3.4262-1.9783l-.6064-1.1734A2.1064,2.1064,0,0,0,21.09.3531l-1.1115.7133a4.0052,4.0052,0,0,1-3.9566,0L14.91.3531a2.1059,2.1059,0,0,0-3.0572.819l-.6063,1.1734A4.0025,4.0025,0,0,1,7.82,4.3238l-1.319-.0613A2.106,2.106,0,0,0,4.2624,6.5007l.0613,1.32a4.0011,4.0011,0,0,1-1.9783,3.4261l-1.1728.6064A2.1057,2.1057,0,0,0,.353,14.91l.7133,1.1121a4.0022,4.0022,0,0,1,0,3.9566L.353,21.09a2.1056,2.1056,0,0,0,.82,3.0571l1.1728.6064A4.0029,4.0029,0,0,1,4.3237,28.18L4.2624,29.5a2.1056,2.1056,0,0,0,2.2382,2.2376l1.319-.0613a4.0023,4.0023,0,0,1,3.4268,1.9783l.6063,1.1728a2.1061,2.1061,0,0,0,3.0572.8195l1.1121-.7127a4.0022,4.0022,0,0,1,3.9566,0l1.1115.7127a2.1066,2.1066,0,0,0,3.0577-.8195l.6064-1.1728A4.0012,4.0012,0,0,1,28.18,31.6764l1.319.0613A2.1056,2.1056,0,0,0,31.7376,29.5l-.0613-1.32a4.0039,4.0039,0,0,1,1.9783-3.4267l1.1733-.6064a2.1057,2.1057,0,0,0,.819-3.0571Z"
        fill="#ba1628"
        fillRule="evenodd"
      />
      <path
        d="M33.9935,19.8687a3.78,3.78,0,0,1,0-3.7368l.6731-1.05a1.989,1.989,0,0,0-.7735-2.8874l-1.1082-.5727a3.78,3.78,0,0,1-1.8684-3.2358L30.9744,7.14a1.989,1.989,0,0,0-2.1139-2.1138l-1.2457.0579a3.7793,3.7793,0,0,1-3.2359-1.8684L23.8063,2.107a1.99,1.99,0,0,0-2.8879-.7735l-1.05.6736a3.7826,3.7826,0,0,1-3.7368,0l-1.05-.6736a1.9888,1.9888,0,0,0-2.8873.7735l-.5727,1.1082A3.78,3.78,0,0,1,8.3852,5.0836L7.1394,5.0257A1.989,1.989,0,0,0,5.0256,7.14l.0579,1.2463a3.779,3.779,0,0,1-1.8684,3.2358l-1.1076.5727a1.9887,1.9887,0,0,0-.7741,2.8874l.6736,1.05a3.78,3.78,0,0,1,0,3.7368l-.6736,1.05a1.9886,1.9886,0,0,0,.7741,2.8873l1.1076.5727a3.7806,3.7806,0,0,1,1.8684,3.2364l-.0579,1.2463a1.9887,1.9887,0,0,0,2.1138,2.1133l1.2458-.0579a3.78,3.78,0,0,1,3.2364,1.8684l.5727,1.1076a1.9889,1.9889,0,0,0,2.8873.774l1.05-.6731a3.78,3.78,0,0,1,3.7368,0l1.05.6731a1.99,1.99,0,0,0,2.8879-.774l.5726-1.1076a3.7791,3.7791,0,0,1,3.2359-1.8684l1.2457.0579a1.9887,1.9887,0,0,0,2.1139-2.1133l-.0579-1.2463a3.7817,3.7817,0,0,1,1.8684-3.2364l1.1082-.5727a1.9889,1.9889,0,0,0,.7735-2.8873Z"
        fillRule="evenodd"
        fill="url(#wsa_winning_36pt_a)"
      />
      <path
        d="M19.55,12.0835A1.4138,1.4138,0,1,0,18.1362,10.67,1.4185,1.4185,0,0,0,19.55,12.0835Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M17.82,14.876a1.4138,1.4138,0,1,0-1.4138-1.4138A1.4185,1.4185,0,0,0,17.82,14.876Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M17.8728,23.4906a1.4139,1.4139,0,1,0,1.4138,1.4138A1.413,1.413,0,0,0,17.8728,23.4906Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M20.99,16.4391a1.4138,1.4138,0,1,0-1.4138,1.4051A1.4186,1.4186,0,0,0,20.99,16.4391Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M19.594,20.663a1.4138,1.4138,0,1,0,1.4138,1.4138A1.4186,1.4186,0,0,0,19.594,20.663Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M16.459,19.2141A1.4138,1.4138,0,1,0,17.8728,17.8,1.4129,1.4129,0,0,0,16.459,19.2141Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M21.2624,17.8a1.4138,1.4138,0,1,0,1.4139,1.4138A1.4185,1.4185,0,0,0,21.2624,17.8Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M16.1253,17.8442a1.4095,1.4095,0,1,0-1.4138-1.4051A1.4055,1.4055,0,0,0,16.1253,17.8442Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M17.5567,22.0768a1.4139,1.4139,0,1,0-1.4138,1.4138A1.4186,1.4186,0,0,0,17.5567,22.0768Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M15.8355,19.2141a1.4138,1.4138,0,1,0-1.4138,1.4138A1.4242,1.4242,0,0,0,15.8355,19.2141Z"
        fill="#fff"
        fillRule="evenodd"
      />
    </>
  ),
  ...SUPERCARDS_HIGHLIGHT_ICON_BY_TYPE,
};

const Highlights = ({ className, highlightType }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    className={className}
  >
    {HIGHLIGHT_ICON_BY_TYPE[highlightType]}
  </svg>
);

Highlights.propTypes = {
  className: PropTypes.string,
  highlightType: PropTypes.string,
};

export default Highlights;
