/* eslint-disable no-undef */
export function csrfToken() {
  const metaElement = document.querySelector('meta[name="csrf-token"]');
  return metaElement ? metaElement.getAttribute('content') : null;
}

export function loadAppleSdk({ clientId }) {
  const initAppleSdk = function () {
    globalThis.AppleID.auth.init({
      clientId,
      scope: 'name email',
      usePopup: true,
      redirectURI: globalThis.location.origin,
    });
  };
  (function () {
    const src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    addScriptToDocument({ src, onload: initAppleSdk });
  })();
}

export function loadFacebookSdk({ api_key }) {
  globalThis.fbAsyncInit = function () {
    globalThis.FB?.init({
      appId: api_key,
      cookie: true,
      xfbml: true,
      version: 'v21.0',
    });
  };
  (function () {
    addScriptToDocument({
      src: 'https://connect.facebook.net/en_US/sdk.js',
      onload: globalThis.fbAsyncInit,
      id: 'facebook-jssdk',
    });
  })();
}

export function loadGoogleSdk({ client_id, handleCredentialResponse }) {
  const init_google_client = function () {
    globalThis.google.accounts.id.initialize({
      client_id: client_id,
      callback: handleCredentialResponse,
    });
  };

  (function () {
    addScriptToDocument({
      src: 'https://accounts.google.com/gsi/client',
      onload: init_google_client,
    });
  })();
}

export function addScriptToDocument({ src, onload, id = undefined }) {
  const tagType = 'script';

  const fjs = document.getElementsByTagName(tagType)[0];
  const js = document.createElement(tagType);
  js.src = src;
  if (onload) {
    js.onload = onload;
  }
  if (id) {
    js.id = id;
  }
  fjs?.parentNode?.insertBefore(js, fjs);
}

export function decodeJWT(token) {
  const tokenParts = String(token).split('.');

  if (tokenParts.length < 3) {
    throw new Error('Token is not a JWT token');
  }

  return JSON.parse(atob(tokenParts[1]));
}
