import t from 'vivino-js/translationString';

export enum WineTypes {
  Red = 1,
  White = 2,
  Sparkling = 3,
  Rose = 4,
  Dessert = 7,
  Fortified = 24,
  Unknown = 25,
}

const WineTypesLabels = {
  [WineTypes.Red]: {
    i18nKeys: {
      plural: 'wine_types.red_wines',
      singular: 'wine_types.red_wine',
      short: 'wine_types.red',
    },
    names_en: {
      plural: 'Red Wines',
      singular: 'Red Wine',
      short: 'Red',
    },
  },
  [WineTypes.White]: {
    i18nKeys: {
      plural: 'wine_types.white_wines',
      singular: 'wine_types.white_wine',
      short: 'wine_types.white',
    },
    names_en: {
      plural: 'White Wines',
      singular: 'White Wine',
      short: 'White',
    },
  },
  [WineTypes.Sparkling]: {
    i18nKeys: {
      plural: 'wine_types.sparkling_wines',
      singular: 'wine_types.sparkling_wine',
      short: 'wine_types.sparkling',
    },
    names_en: {
      plural: 'Sparkling Wines',
      singular: 'Sparkling Wine',
      short: 'Sparkling',
    },
  },
  [WineTypes.Rose]: {
    i18nKeys: {
      plural: 'wine_types.rose_wines',
      singular: 'wine_types.rose_wine',
      short: 'wine_types.rose',
    },
    names_en: {
      plural: 'Rosé Wines',
      singular: 'Rosé Wine',
      short: 'Rosé',
    },
  },
  [WineTypes.Dessert]: {
    i18nKeys: {
      plural: 'wine_types.dessert_wines',
      singular: 'wine_types.dessert_wine',
      short: 'wine_types.dessert',
    },
    names_en: {
      plural: 'Dessert Wines',
      singular: 'Dessert Wine',
      short: 'Dessert',
    },
  },
  [WineTypes.Fortified]: {
    i18nKeys: {
      plural: 'wine_types.fortified_wines',
      singular: 'wine_types.fortified_wine',
      short: 'wine_types.fortified',
    },
    names_en: {
      plural: 'Fortified Wines',
      singular: 'Fortified Wine',
      short: 'Fortified',
    },
  },
};

export enum WineVintageTypes {
  VINTAGE = 0,
  NON_VINTAGE = 1,
  MIXED_VINTAGE = 2,
}

export const getWineTypeName = (wineTypeId: number, amount = 1, translatorFunc = t) => {
  const translationKey =
    amount > 1
      ? WineTypesLabels[wineTypeId]?.i18nKeys?.plural || 'wine_types.wines'
      : WineTypesLabels[wineTypeId]?.i18nKeys?.singular || 'wine_types.wine';

  return translatorFunc?.(translationKey);
};

export const getSingularWineTypeNameEn = (wineTypeId: number): string => {
  return WineTypesLabels[wineTypeId]?.names_en?.singular || '';
};

export const getShortWineTypeName = (wineTypeId: number, translatorFunc = t) =>
  translatorFunc(WineTypesLabels[wineTypeId]?.i18nKeys?.short || '');

export const getShortWineTypeNameEn = (wineTypeId: number): string => {
  return WineTypesLabels[wineTypeId]?.names_en?.short || '';
};

export const getWineName = (wine) => {
  const wineryName = wine?.winery?.name || '';

  return wineryName ? `${wineryName} ${wine.name}` : wine.name;
};

export const getPluralWineTypeName = (wineTypeId: number, translatorFunc = t) =>
  translatorFunc(WineTypesLabels[wineTypeId]?.i18nKeys?.plural || '');

export const getPluralWineTypeNameEn = (wineTypeId: number): string => {
  return WineTypesLabels[wineTypeId]?.names_en?.plural || '';
};

export const isUnknownWineType = (wine) => wine.type_id === WineTypes.Unknown;

/*
 * For wine.vintage_type=2 (mixed vintage), there's no distinction between Non-vintage vs. Master
 * So currently it will be is_nv?=true AND is_uv?=true for "default" vintages in such case
 * But in the future, such distinction may be introduced
 * https://vivino.slack.com/archives/C01J0KJE9EW/p1611918011052600
 */
export const isVintageWine = (wine) => wine?.vintage_type === WineVintageTypes.VINTAGE;

export const isNonVintageWine = (wine) => wine?.vintage_type === WineVintageTypes.NON_VINTAGE;

export const isMixedVintageWine = (wine) => wine?.vintage_type === WineVintageTypes.MIXED_VINTAGE;

export const visibleWineTypeIds: Array<string> = Object.keys(WineTypesLabels);
