import { Button, ButtonTheme, TextArea, useBreakpoint } from '@vivino/js-react-common-ui';
import { Loader, LoaderType, UserAvatar, UserAvatarSizes, useI18N } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'vivino-js/helpers/user';

import { BaseModal } from '@components/BaseModal/BaseModal';

import ReviewRatingStars from 'vivino-ui/components/CommunityReviews/components/ReviewRatingStars';
import { Col, Row } from 'vivino-ui/components/Grid';

import styles from './ratingReviewEditor.scss';

const TRANSLATIONS_PATH = 'components.shared.rating_card';
const TRANSLATIONS = {
  yourReview: `${TRANSLATIONS_PATH}.your_review`,
  sayAfewWords: `${TRANSLATIONS_PATH}.say_afew_words`,
  youAreRatingThisWine: `${TRANSLATIONS_PATH}.you_are_rating`,
  cancel: `${TRANSLATIONS_PATH}.cancel`,
  allDone: `${TRANSLATIONS_PATH}.all_done`,
};

const RatingReviewEditor = ({
  initialRating,
  initialNote = '',
  isLoading = false,
  show,
  vintage,
  onClose,
  onSubmit,
}) => {
  const { t } = useI18N();

  const [rating, setRating] = useState(initialRating);
  const [note, setNote] = useState(initialNote);
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);

  const wineName = vintage?.name;

  const handleChange = (e) => {
    const { value } = e.currentTarget;
    setNote(value);
  };

  const handleRatingClick = ({ rating }) => {
    setRating(rating);
  };

  const handleSubmit = () => {
    onSubmit({ vintageId: vintage.id, rating, note });
  };

  return (
    <BaseModal show={show} onClose={onClose}>
      <div>
        {isLoading && <Loader alignTop type={LoaderType.Linear} />}
        <div className={styles.title}>
          {t(TRANSLATIONS.yourReview)}
          <div className={styles.subTitle}>
            {t(TRANSLATIONS.youAreRatingThisWine, { wine: wineName })}
          </div>
        </div>

        <div>
          <div className={styles.rating}>
            <UserAvatar
              user={getCurrentUser()}
              size={UserAvatarSizes.Medium}
              className={styles.avatar}
            />
            <ReviewRatingStars
              initialRating={rating}
              margin="0 8px"
              size={isMobile ? 24 : 40}
              onRatingClick={handleRatingClick}
            />
          </div>

          <TextArea
            fullWidth
            value={note}
            onChange={handleChange}
            className={styles.textArea}
            placeholder={t(TRANSLATIONS.sayAfewWords)}
            maxLength={520}
          />
        </div>
        <Row className={styles.lowerSection}>
          <Col className={styles.buttons} tablet={8} desktop={6}>
            <div className={styles.cancelButtonContainer}>
              <Button onClick={onClose} fullWidth>
                {t(TRANSLATIONS.cancel)}
              </Button>
            </div>
            <div className={styles.allDoneButtonContainer}>
              <Button onClick={handleSubmit} theme={ButtonTheme.Accent} fullWidth>
                {t(TRANSLATIONS.allDone)}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </BaseModal>
  );
};

RatingReviewEditor.propTypes = {
  isLoading: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialRating: PropTypes.number,
  initialNote: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  vintage: PropTypes.object.isRequired,
};

export default RatingReviewEditor;
