/* eslint-disable react/display-name, react/prop-types */
import React from 'react';
import t from 'vivino-js/translationString';

export function withHeader(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.renderLocation = () => {
        if (typeof this.props.merchantState !== 'undefined' && this.props.merchantState !== null) {
          return (
            this.props.merchantState + ' · ' + t(`countries.${this.props.merchantCountryCode}`)
          );
        }
        return t(`countries.${this.props.merchantCountryCode}`);
      };
      this.renderToplistLink = () => {
        const { merchantLink } = this.props;
        if (merchantLink) {
          return (
            <a
              href={merchantLink}
              className="market-band__header__main__lead-link"
              data-item-type="view-all"
              data-js-track-link-category="Band"
              data-js-track-link-name={`Go to merchant - ${this.props.trackingName} on ${this.props.trackingSource}`}
            >
              {t('components.market.with_header.go_to_merchant')}
            </a>
          );
        }
        return null;
      };
      this.renderHeaderElement = () => {
        const listType = this.props.listType;
        if (typeof listType !== 'undefined') {
          switch (listType) {
            case 'similar-taste':
              return (
                <div
                  className="market-band__header__image"
                  style={{ backgroundImage: `url(${this.props.image})` }}
                />
              );
            case 'promo':
              return (
                <div
                  className="market-band__header__author"
                  style={{ backgroundImage: `url(${this.props.image})` }}
                />
              );
            case 'recommended':
              return (
                <div
                  className="market-band__header__user"
                  style={{ backgroundImage: `url(${this.props.image})` }}
                />
              );
            case 'branded-merchant':
              return (
                <a
                  href={this.props.merchantLink}
                  className="market-band__header__image"
                  style={{ backgroundImage: `url(${this.props.image})` }}
                />
              );
            default:
              return null;
          }
        }
      };
      this.renderHeadlineElement = () => {
        const listType = this.props.listType;
        if (typeof listType !== 'undefined' && listType === 'branded-merchant') {
          return (
            <div className="market-band__header__main">
              <a href={this.props.merchantLink}>{this.props.headline}</a>
              {this.renderToplistLink()}
            </div>
          );
        }
        return (
          <div className="market-band__header__main">
            <span>{this.props.headline}</span>
            {this.renderToplistLink()}
          </div>
        );
      };
      this.renderDescriptionElement = () => {
        const listType = this.props.listType;
        if (typeof listType !== 'undefined' && listType === 'branded-merchant') {
          return (
            <div className="market-band__header__description">
              <i className={`icon-flag-${this.props.merchantCountryCode}`} />
              {this.renderLocation()}
            </div>
          );
        }
        return <div className="market-band__header__description">{this.props.description}</div>;
      };
    }
    render() {
      if (!this.props.headline) {
        return (
          <div>
            <WrappedComponent {...this.props} />
          </div>
        );
      }

      return (
        <div>
          <div className="market-band__header">
            {this.renderHeaderElement()}
            <div className="market-band__header__title">
              {this.renderHeadlineElement()}
              {this.renderDescriptionElement()}
            </div>
          </div>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
}
