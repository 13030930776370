import { TypographyType } from '@vivino/js-react-common-ui';
import React from 'react';

import VFMBadge from '@components/VFMScale/components/VFMBadge/VFMBadge';
import { scaleToRotation } from '@components/VFMScale/helpers';
import VFMGauge from '@components/icons/VFMGauge';

import styles from './vfmscore.module.scss';

interface VFMCategory {
  id: number;
  barometer_position: number;
  title: string;
}
interface VFMScoreProps {
  category: VFMCategory;
}
const VFMScore = ({ category }: VFMScoreProps) => {
  if (!category || category.id === 0) {
    return null;
  }
  const rotation = scaleToRotation(category.barometer_position);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.categoryContainer}>
          <div className={styles.gauge}>
            <VFMGauge rotation={rotation} unAvailable={false} />
          </div>
          <div className={styles.badge}>
            <VFMBadge
              text={category.title}
              position={category.barometer_position}
              labelType={TypographyType.BodyMedium}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VFMScore;
