import { PaymentOptionsPropType, PlusPlan, PlusSubscription, isUS } from '@vivino/js-web-common';

export enum VivinoPlusToggleStatus {
  ON = 'on',
  OFF = 'off',
}

export enum VivinoPlusTrackingEvents {
  SUBSCRIPTION_BAND_TOGGLE = 'Plus Subscription Band - Toggle',
  SUBSCRIPTION_BAND_SHOW = 'Plus Subscription Band - Show',
  LEARN_MORE_SHOW = 'Vivino Plus - Learn more - Show',
}

export enum SubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

const TRANSLATION_PATH = 'components.vivino_plus';
const TRANSLATIONS = {
  createAnAccountOrLogInToJoinVivinoPlus: `${TRANSLATION_PATH}.create_an_account_or_log_in_to_join_vivino_plus`,
};

export const getVivinoPlusLogInMessage = (t) =>
  t(TRANSLATIONS.createAnAccountOrLogInToJoinVivinoPlus);

const PAYMENT_OPTIONS_ACCEPTED_BY_PLUS = [PaymentOptionsPropType.Stripe_CreditCard];

export const getFirstMonthAmount = (plan: PlusPlan) => plan.subtotal_amount - plan.discount_amount;

export const getFirstMonthTotalAmount = (plan: PlusPlan) =>
  getFirstMonthAmount(plan) + plan.tax_amount;

export const getTotalAmount = (plan: PlusPlan) => {
  // we charge no taxes on top of the monthly subscription price, so total amount coincides with subtotal amount
  return plan.subtotal_amount + plan.tax_amount;
};

export const getActiveSubscription = (subscriptions: PlusSubscription[]) => {
  const activeSubscriptions = subscriptions.filter((subscription) =>
    isActiveSubscription(subscription)
  );
  // there is one and one only active subscription at any given time for now, so we take the first element from array
  return activeSubscriptions[0] || null;
};

export const isActiveSubscription = (subscription: PlusSubscription) =>
  subscription.status === SubscriptionStatus.Active;

export const isPaymentOptionValidForPlus = (paymentOption: string) =>
  PAYMENT_OPTIONS_ACCEPTED_BY_PLUS.includes(paymentOption);

export const isCountryWithVivinoPlus = (countryCode: string) => isUS(countryCode);

export const getPlan = (plans: PlusPlan[]): PlusPlan => {
  // currently, we have only one plan globally, so just take the first in the list
  return plans[0];
};
