/**
 * Wait enough for other components to render first then scroll to the first input with
 * an error
 */
const DETAULT_TIMEOUT_BEFORE_SCROLL = 300;

const scrollIntoView = (domElement, timeout = DETAULT_TIMEOUT_BEFORE_SCROLL) => {
  if (domElement) {
    setTimeout(() => {
      try {
        domElement.scrollIntoView({ behavior: 'smooth' });
      } catch (e) {
        // Fail silently
      }
    }, timeout);

    return true;
  }

  return false;
};

export const scrollElementIntoViewById = (fieldId, timeout = DETAULT_TIMEOUT_BEFORE_SCROLL) => {
  // If the scroll is called right as a change to an element happens it might
  // not have the correct position for the element, therfore we set a timeout
  // to scroll once the element has rerendered.
  setTimeout(() => {
    const domElement = document.getElementById(fieldId);
    scrollIntoView(domElement, timeout);
  }, 0);
};
