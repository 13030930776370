import { shortDateFormat } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';

import ReviewAnchor from '../ReviewAnchor';
import styles from './reviewDate.scss';

const ReviewDate = ({ review, size = ComponentSizes.Base, className }) => {
  return (
    <ReviewAnchor
      review={review}
      className={cx(
        styles.reviewDate,
        {
          [styles.large]: size === ComponentSizes.Large,
        },
        className
      )}
    >
      {shortDateFormat({ date: review.created_at })}
    </ReviewAnchor>
  );
};

ReviewDate.propTypes = {
  review: PropTypes.object.isRequired,
  className: PropTypes.string,
  size: CommonPropTypes.componentSize,
};

export default ReviewDate;
