const SCALING = 0.5;

export const getSliderValue = ({ position, defaultMax, rangeMax }) => {
  //if the logic is updated in this method, make sure to update the logic in getSliderPosition as well
  if (defaultMax !== null || rangeMax !== null) {
    if (position >= rangeMax) {
      return rangeMax;
    }

    const b = Math.pow(defaultMax / rangeMax, 1 / ((SCALING - 1) * rangeMax));
    const a = rangeMax / Math.pow(b, rangeMax);
    let r = Math.round(rangeMax / 500);
    if (r < 1) {
      r = 1;
    }

    if (position < SCALING * rangeMax) {
      const returnValue = (defaultMax * position) / (SCALING * rangeMax);
      const roundedValue = Math.round(returnValue / r) * r;
      return roundedValue;
    } else {
      const returnValue = a * Math.pow(b, position);
      let roundedValue;
      if (Math.round(returnValue / 1000) > 0) {
        roundedValue = Math.round(returnValue / 100) * 100;
      } else if (Math.round(returnValue / 500) > 0) {
        roundedValue = Math.round(returnValue / 50) * 50;
      } else if (Math.round(returnValue / 100) > 0) {
        roundedValue = Math.round(returnValue / 10) * 10;
      } else if (Math.round(returnValue / 50) > 0) {
        roundedValue = Math.round(returnValue / 5) * 5;
      } else {
        roundedValue = Math.round(returnValue / r) * r;
      }

      return roundedValue;
    }
  }

  return position;
};

export const getSliderPosition = ({ value, defaultMax, rangeMax }) => {
  //this should be updated if logic changes in getSliderValue method changes
  if (value >= rangeMax) {
    return rangeMax;
  }

  const b = Math.pow(defaultMax / rangeMax, 1 / ((SCALING - 1) * rangeMax));
  const a = rangeMax / Math.pow(b, rangeMax);
  const r = Math.round(rangeMax / 500);
  if (value < getSliderValue({ position: SCALING * rangeMax, defaultMax, rangeMax })) {
    const returnValue = (value * SCALING * rangeMax) / defaultMax;
    return Math.round(returnValue);
  } else {
    const returnValue = Math.log(value / a) / Math.log(b);
    let roundedValue;
    if (Math.round(1000 / returnValue) > 0) {
      roundedValue = Math.round(returnValue * 100) / 100;
    } else if (Math.round(500 / returnValue) > 0) {
      roundedValue = Math.round(returnValue * 50) / 50;
    } else if (Math.round(100 / returnValue) > 0) {
      roundedValue = Math.round(returnValue * 10) / 10;
    } else if (Math.round(50 / returnValue) > 0) {
      roundedValue = Math.round(returnValue * 5) / 5;
    } else {
      roundedValue = Math.round(returnValue * r) / r;
    }
    return Math.round(roundedValue);
  }
};
