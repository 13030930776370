export function pointerPositionInElement(event) {
  const rect = event.currentTarget.getBoundingClientRect();
  const elementWidth = event.currentTarget.offsetWidth;
  const globalPosition = event.clientX || event.touches?.[0]?.clientX;
  const pointerPositionInElement = globalPosition - rect.left;

  const minDistanceFromBounds = 5;
  const maxPositionInDiv = elementWidth - 5;

  return Math.min(Math.max(minDistanceFromBounds, pointerPositionInElement), maxPositionInDiv);
}
