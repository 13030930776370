import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { highlight } from 'vivino-js/apiPropTypes';
import Anchor from 'vivino-js/atoms/Anchor';
import { getHighlightUrl } from 'vivino-js/helpers/highlights';
import t from 'vivino-js/translationString';
import HighlightIcons from 'vivino-ui/icons/Highlights/36';

import styles from './highlight.scss';

const TRANSLATIONS = {
  vintage: 'components.shared.highlight.vintage',
};

export const HIGHLIGHT_SIZE = {
  Base: 'base',
  Large: 'large',
};

const Highlight = ({
  highlight,
  className,
  highlightSize = HIGHLIGHT_SIZE.Base,
  shouldLink = true,
}) => {
  const highlightUrl = getHighlightUrl(highlight);

  const content = (
    <div
      className={cx(styles.highlight, className, styles[highlightSize])}
      data-testid={`highlight-${highlight.highlight_type}`}
    >
      <HighlightIcons className={styles.icon} highlightType={highlight.highlight_type} />

      <div className={styles.rightColumn}>
        {highlight.message}
        {highlight.vintage_year > 0 &&
          ` (${t(TRANSLATIONS.vintage, { year: highlight.vintage_year })})`}
      </div>
    </div>
  );

  if (highlightUrl && shouldLink) {
    return (
      <Anchor href={highlightUrl} target="_blank">
        {content}
      </Anchor>
    );
  }

  return content;
};

export const HighlightSkeleton = ({ className }) => (
  <div className={cx(styles.skeleton, className)} data-testid="highlightSkeleton">
    <div className={styles.skeletonBadge}> </div>
    <div className={styles.skeletonDescription}> </div>
  </div>
);
HighlightSkeleton.propTypes = {
  className: PropTypes.string,
};

Highlight.propTypes = {
  highlight: highlight.isRequired,
  className: PropTypes.string,
  shownVintageId: PropTypes.number,
  highlightSize: PropTypes.oneOf(Object.values(HIGHLIGHT_SIZE)),
  shouldLink: PropTypes.bool,
};

export default Highlight;
