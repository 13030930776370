import { Chevron, ChevronDirections, WineTypes } from '@vivino/js-web-common';
import cx from 'classnames';
import React, { useState } from 'react';
import t from 'vivino-js/translationString';

import styles from './wineSelector.scss';

const WINE_TYPES = [
  { id: 1, translation: 'components.explorer.wine_selector.red_wine' },
  { id: 2, translation: 'components.explorer.wine_selector.white_wine' },
  { id: 3, translation: 'components.explorer.wine_selector.sparkling_wine' },
  { id: 4, translation: 'components.explorer.wine_selector.rose_wine' },
  { id: 7, translation: 'components.explorer.wine_selector.dessert_wine' },
  { id: 24, translation: 'components.explorer.wine_selector.port_wine' },
];

const getTypeNameById = (id) => {
  const wineTypeObj = WINE_TYPES.find((obj) => obj.id === id);
  return t(wineTypeObj.translation);
};

interface WineSelectorProps {
  wineTypeId: WineTypes;
  updateWineTypeId: (wineTypeId: number) => void;
}

const WineSelector = ({ wineTypeId, updateWineTypeId }: WineSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleWineStylesList = () => {
    setIsOpen((prevState) => !prevState);
  };

  const setWineTypeId = (e) => {
    const value = parseInt(e.currentTarget.dataset.wineTypeId);

    updateWineTypeId(value);
    setIsOpen(false);
  };

  return (
    <div className={cx(styles.container)}>
      <div className={styles.selected}>{getTypeNameById(wineTypeId)}</div>
      <div onClick={toggleWineStylesList} className={styles.toggle}>
        <Chevron direction={isOpen ? ChevronDirections.UP : ChevronDirections.DOWN} />
      </div>
      {isOpen && (
        <div className={styles.list}>
          {WINE_TYPES.map((item) => (
            <div
              className={styles.listItem}
              key={item.id}
              onClick={setWineTypeId}
              data-wine-type-id={item.id}
            >
              {t(item.translation)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WineSelector;
