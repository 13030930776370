import { DeferredImage } from '@vivino/js-react-common-ui';
import { BasicVintage } from '@vivino/js-web-common';
import cx from 'classnames';
import React from 'react';

import styles from './labelShot.scss';

interface LabelShotProps {
  vintage: BasicVintage;
  small?: boolean;
  className?: string;
  alt: string;
}

const getImageUrl = (vintage, small) =>
  small ? vintage?.image?.variations.medium : vintage?.image?.variations.large;

const LabelShot = ({ vintage, small = false, className = '', alt }: LabelShotProps) => (
  <DeferredImage src={getImageUrl(vintage, small)}>
    <div role="img" aria-label={alt} className={cx(className, styles.labelShot)} />
  </DeferredImage>
);

export default LabelShot;
