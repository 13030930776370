import { useBreakpoint } from '@vivino/js-react-common-ui';
import { BasicVintageWithBasicWine } from '@webtypes/goApi';
import { RubyLibVintagePrice } from '@webtypes/rubyLibApi';
import React from 'react';

import BaseWineBand from './BaseWineBand';

export interface WineBandProps {
  vintages: BasicVintageWithBasicWine[];
  prices: { [id: number]: RubyLibVintagePrice };
  headlineText?: string;
  showOutOfStock?: boolean;
  showAllUrl?: string;
  offersTitles?: { [vintageId: number]: string };
}

const WineBand = ({ vintages = [], prices = {}, ...rest }: WineBandProps) => {
  const { isMobile } = useBreakpoint();

  let visibleVintages, isShowAllVisible;

  if (isMobile) {
    // on mobile, show 2 wines only and show showAllButton instead
    visibleVintages = vintages.slice(0, 2);
    isShowAllVisible = true;
  } else {
    // on tablet/desktop, show all wines so no need to show showAllButton
    visibleVintages = vintages;
    isShowAllVisible = false;
  }

  return (
    <BaseWineBand
      vintages={visibleVintages}
      totalCount={vintages.length}
      isShowAllVisible={isShowAllVisible}
      prices={prices}
      {...rest}
    />
  );
};

export default WineBand;
