import { ArrowBorder, ArrowBorderDirections, CrossIcon } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Anchor from 'vivino-js/atoms/Anchor/Anchor.presentational';

import styles from './toolTip.scss';
import transitions from './transitions.scss';

export const DEFAULT_DURATION = 2000;
export const ALERT = 'alert';

export const ToolTipPosition = {
  Left: 'arrowLeft',
  Center: 'arrowCenter',
  Right: 'arrowRight',
};

const ToolTip = ({
  className,
  show,
  onClose,
  closeBtnClassName,
  children,
  position = ToolTipPosition.Right,
  mode,
  duration,
}) => {
  const timeoutId = useRef(null);

  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose?.();
  };

  useEffect(() => {
    if (duration && show) {
      timeoutId.current = setTimeout(() => {
        clearTimeout(timeoutId.current);
        onClose?.();
      }, duration);
    }
  }, [show]);

  return (
    <CSSTransition in={show} timeout={300} classNames={{ ...transitions }} unmountOnExit>
      <div
        data-testid="tooltip"
        className={cx(styles.toolTip, className, {
          [styles.alert]: mode === ALERT,
        })}
      >
        <div className={styles.window}>
          {onClose && (
            <Anchor
              href="#"
              onClick={handleCloseClick}
              className={cx(styles.close, closeBtnClassName)}
            >
              <CrossIcon />
            </Anchor>
          )}
          {children}
        </div>
        <ArrowBorder
          direction={ArrowBorderDirections.DOWN}
          className={cx(styles.arrow, styles[position])}
        />
      </div>
    </CSSTransition>
  );
};

ToolTip.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  closeBtnClassName: PropTypes.string,
  position: PropTypes.oneOf([ToolTipPosition.Left, ToolTipPosition.Center, ToolTipPosition.Right]),
  mode: PropTypes.string,
  duration: PropTypes.number,
};

export default ToolTip;
