export class APIError extends Error {
  status: number;
  code: string;

  constructor(message: string, status: number, code: string) {
    super(message);

    this.name = 'CustomError';
    this.status = status;
    this.code = code;
  }
}

export class AddressVerificationError extends Error {
  status: number;
  errors: any;

  constructor(errors, status: number) {
    super('AddressVerificationError');

    this.name = 'AddressVerificationError';
    this.status = status;
    this.errors = errors;
  }
}
