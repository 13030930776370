import { apiDelete, apiGet, apiPost, apiPut } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export function fetchComments({ activityId, page, perPage }) {
  const url = generateQueryUrl({
    baseUrl: `/api/activities/${activityId}/comments`,
    params: [
      { name: 'page', value: page },
      { name: 'per_page', value: perPage },
    ],
  });

  return apiGet({ url });
}

export function createComment({ activityId, comment }) {
  return apiPost({
    url: `/api/activities/${activityId}/comments`,
    body: { comment },
  });
}

export function updateComment({ activityId, commentId, comment }) {
  return apiPut({
    url: `/api/activities/${activityId}/comments/${commentId}`,
    body: { comment },
  });
}

export function deleteComment({ activityId, commentId }) {
  return apiDelete({
    url: `/api/activities/${activityId}/comments/${commentId}`,
  });
}
