import { apiGet } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export function fetchMerchants(vintageId) {
  return apiGet({ url: `/api/vintages/${vintageId}/merchants` });
}

export function fetchMerchant({ merchantId }) {
  const url = `/api/merchants/${merchantId}`;
  return apiGet({ url });
}

/**
 * Retrieve the merchant shipping states for the provided country. If country code is not
 * provided, the current shipping country from the session will be used.
 */
export function fetchMerchantShippingLocations({ merchantId, countryCode = null }) {
  const url = generateQueryUrl({
    baseUrl: `/api/merchants/${merchantId}/merchant_shipping_locations`,
    params: [{ name: 'country_code', value: countryCode }],
  });

  return apiGet({ url });
}

export function getTemperatureControlInformation({ merchantId, countryCode, stateCode }) {
  const url = generateQueryUrl({
    baseUrl: `/api/merchants/${merchantId}/temperature_control`,
    params: [
      { name: 'country_code', value: countryCode },
      { name: 'state_code', value: stateCode },
    ],
  });

  return apiGet({ url });
}

export const getAvailabilityResults = ({
  countryCodes,
  currencyCode,
  discountedOnly,
  foodIds,
  grapeIds,
  maxRating,
  merchantId,
  minRating,
  priceRangeMax,
  priceRangeMin,
  regionIds,
  vcOnly,
  wineStyleIds,
  wineTypeIds,
  wineYears,
}) => {
  const vintagePricesUrl = generateQueryUrl({
    baseUrl: `/api/merchants/${merchantId}/availability_result`,
    params: [
      { name: 'country_codes', value: countryCodes },
      { name: 'currency_code', value: currencyCode },
      { name: 'discount_prices', value: discountedOnly },
      { name: 'food_ids', value: foodIds },
      { name: 'grape_ids', value: grapeIds },
      { name: 'max_rating', value: maxRating },
      { name: 'min_rating', value: minRating },
      { name: 'price_range_max', value: priceRangeMax },
      { name: 'price_range_min', value: priceRangeMin },
      { name: 'region_ids', value: regionIds },
      { name: 'vc_only', value: vcOnly },
      { name: 'wine_style_ids', value: wineStyleIds },
      { name: 'wine_type_ids', value: wineTypeIds },
      { name: 'wine_years', value: wineYears },
    ],
  });

  return apiGet({ url: vintagePricesUrl });
};

export const getPayPalOnboardingURL = ({ merchantId }) =>
  apiGet({ url: `/api/merchants/${merchantId}/paypal_onboarding_url` });

export const getShippingPolicies = ({
  merchantId,
  countryCode,
  stateCode,
  zipCode,
  bottleType,
  bottleQuantity,
  totalAmount,
  currencyCode,
}) => {
  const shippingPoliciesUrl = generateQueryUrl({
    baseUrl: `/api/merchants/${merchantId}/shipping_policies`,
    params: [
      { name: 'country', value: String(countryCode).toLocaleLowerCase() },
      { name: 'state', value: stateCode },
      { name: 'bottle_type', value: bottleType },
      { name: 'bottle_quantity', value: bottleQuantity },
      { name: 'total_amount', value: totalAmount },
      { name: 'currency_code', value: currencyCode },
      { ...(zipCode && { name: 'zip', value: zipCode }) },
    ],
  });

  return apiGet({ url: shippingPoliciesUrl });
};
