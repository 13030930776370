import { apiDelete, apiGet, apiPost, apiPut } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

const getReviewsParams = ({ vintageYear, ratings, perPage, page }) => {
  const params = [
    { name: 'ratings', value: ratings },
    { name: 'per_page', value: perPage },
    { name: 'page', value: page },
  ];
  // year param is used for "prioritizing" sort order only, not for filtering
  if (vintageYear) {
    params.push({ name: 'year', value: vintageYear });
  }
  return params;
};

// "Helpful" sorting order personalized for each user (user identified by Authorization token)
export function fetchWineReviews({
  wineId,
  vintageYear,
  ratings = undefined,
  perPage,
  page,
  options = {},
}) {
  const url = generateQueryUrl({
    baseUrl: `/api/wines/${wineId}/reviews`,
    params: getReviewsParams({ vintageYear, ratings, perPage, page }),
  });
  return apiGet({
    url,
    options,
  });
}

export function fetchLatestWineReviews({ wineId, vintageYear, ratings, perPage, page, options }) {
  const url = generateQueryUrl({
    baseUrl: `/api/wines/${wineId}/latest_reviews`,
    params: getReviewsParams({ vintageYear, ratings, perPage, page }),
  });
  return apiGet({
    url,
    options,
  });
}

export function fetchFollowingWineReviews({
  wineId,
  vintageYear,
  ratings,
  perPage,
  page,
  options,
}) {
  const url = generateQueryUrl({
    baseUrl: `/api/wines/${wineId}/following_reviews`,
    params: getReviewsParams({ vintageYear, ratings, perPage, page }),
  });
  return apiGet({
    url,
    options,
  });
}

export function fetchWineReviewsByTaste({ wineId, flavorIds, categoryId, perPage, page, options }) {
  const url = generateQueryUrl({
    baseUrl: `/api/wines/${wineId}/reviews_with_flavors`,
    params: [
      { name: 'flavor_ids', value: flavorIds },
      { name: 'category_id', value: categoryId },
      { name: 'per_page', value: perPage },
      { name: 'page', value: page },
    ],
  });
  return apiGet({
    url,
    options,
  });
}

export function createReview({ vintageId, rating, note }) {
  return apiPost({
    url: `/api/vintages/${vintageId}/reviews`,
    body: { rating, note },
  });
}

export function fetchUserWineReviews({ userId, wineId, ratings, options }) {
  const args = {
    baseUrl: `/api/users/${userId}/wines/${wineId}/reviews`,
    params: [],
  };
  if (ratings) {
    args.params.push({ name: 'ratings', value: ratings });
  }
  const url = generateQueryUrl(args);
  return apiGet({ url, options });
}

export function fetchUserVintageReviews({ userId, vintageId, ratings, options }) {
  const args = {
    baseUrl: `/api/users/${userId}/vintages/${vintageId}/reviews`,
    params: [],
  };
  if (ratings) {
    args.params.push({ name: 'ratings', value: ratings });
  }
  const url = generateQueryUrl(args);
  return apiGet({ url, options });
}

export function updateReview({ reviewId, rating, note }) {
  return apiPut({
    url: `/api/reviews/${reviewId}`,
    body: { rating, note },
  });
}

export function deleteReview({ reviewId }) {
  return apiDelete({
    url: `/api/reviews/${reviewId}`,
  });
}
