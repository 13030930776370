import React, { useEffect, useState } from 'react';

interface VFMGaugeProps {
  rotation?: number;
  unAvailable?: boolean;
  id?: number;
}

const VFMGauge = ({ rotation = 0, unAvailable = false, id = Date.now() }: VFMGaugeProps) => {
  const [rotationDeg, setRotationDeg] = useState(rotation);

  const MAX_ROTATION = 160;
  const MIN_ROTATION = -10;

  useEffect(() => {
    if (rotation > MAX_ROTATION || rotation < MIN_ROTATION) {
      return;
    }
    setRotationDeg(rotation);
  }, [rotation]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 40">
      <defs>
        <radialGradient
          id={`c${id}`}
          cx="27.6"
          cy="26.2"
          fx="-2.9"
          fy="26.2"
          r="30.7"
          gradientTransform="rotate(-39.9 -8.503 39.317) scale(1 2)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ba1628" />
          <stop offset=".2" stopColor="#ee803e" />
          <stop offset=".4" stopColor="#f5d267" />
          <stop offset=".7" stopColor="#5aaf89" />
          <stop offset=".8" stopColor="#00845f" />
        </radialGradient>
        <linearGradient id={`a${id}`} x1="28" y1="40" x2="28" y2="0" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff" stopOpacity="0" />
          <stop offset="0" stopColor="rgba(255, 255, 255, .3)" stopOpacity=".3" />
          <stop offset="0" stopColor="rgba(255, 255, 255, .5)" stopOpacity=".5" />
          <stop offset="0" stopColor="rgba(255, 255, 255, .7)" stopOpacity=".7" />
          <stop offset="0" stopColor="rgba(255, 255, 255, .9)" stopOpacity=".9" />
          <stop offset=".1" stopColor="rgba(255, 255, 255, 1)" />
          <stop offset=".1" stopColor="#fff" />
          <stop offset=".2" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <mask id={`b${id}`} x="0" y="0" width="56" height="40" maskUnits="userSpaceOnUse">
          <path style={{ strokeWidth: 0, fill: `url(#a${id})` }} d="M0 0h56v40H0z" />
        </mask>
      </defs>
      <g style={{ mask: `url(#b${id})` }}>
        <path
          d="M28 12C12.5 12 0 24.5 0 40h4c0-13.2 10.8-24 24-24s24 10.8 24 24h4c0-15.5-12.5-28-28-28Z"
          style={{ fill: `url(#c${id})`, strokeWidth: 0 }}
        />
      </g>
      {unAvailable ? (
        <path
          d="M26.6 32.1v-.7c0-1.4 1-2.3 1.8-3.1.7-.7 1.3-1.3 1.3-2s-.5-1.5-1.6-1.5-1.9.8-2 2H24c.1-2.4 1.8-3.8 4.2-3.8s3.8 1.4 3.8 3.1-.8 2.1-1.6 2.9c-.8.8-1.7 1.5-1.8 2.6v.6h-2Zm1 4c-.8 0-1.5-.6-1.5-1.4s.7-1.4 1.5-1.4 1.4.6 1.4 1.4-.6 1.4-1.4 1.4Z"
          style={{ fill: '#575757', strokeWidth: 0 }}
        />
      ) : (
        <path
          d="m.6 27.7 26.3 7.2c.6.2 1.2-.4 1-1.1 0-.3-.3-.5-.6-.6L.6 27.7Z"
          style={{
            fill: '#575757',
            fillRule: 'evenodd',
            stroke: '#575757',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
            transformOrigin: '50% 90%',
            transform: `rotate(${rotationDeg}deg)`,
            transition: 'transform 0.5s ease',
          }}
        />
      )}
    </svg>
  );
};

export default VFMGauge;
