import PropTypes from 'prop-types';
import React from 'react';

export const TrackingContext = React.createContext(null);

export const TrackingProvider = ({
  children,
  screen = '',
  defaultTrackingProps = {},
  ...restProps
}) => (
  <TrackingContext.Provider value={{ screen, defaultTrackingProps, ...restProps }}>
    {children}
  </TrackingContext.Provider>
);

TrackingProvider.propTypes = {
  screen: PropTypes.string,
  defaultTrackingProps: PropTypes.object,
  children: PropTypes.node,
};

export const TrackingConsumer = TrackingContext.Consumer;
