import { isSE, isUS } from 'vivino-js/helpers/countryHelpers';
import t from 'vivino-js/translationString';

const TRANSLATION_PATH = 'components.shared.offers_subscription';
const TRANSLATIONS = {
  getAccess: `${TRANSLATION_PATH}.get_access`,
};

const MAX_DISCOUNT_RATES = {
  DEFAULT: 60,
  NL: 25,
  FR: 34,
};

export const getUserHasOfferSubscription = () =>
  !!window.__PRELOADED_STATE__?.userHasOfferSubscription;

export const shouldSubscribeToOffersByDefault = (countryCode) => isUS(countryCode);

export const getOfferSubscriptionDescription = ({ countryCode }) => {
  if (isSE(countryCode)) {
    return null;
  }

  return t(TRANSLATIONS.getAccess, {
    discount_rate: MAX_DISCOUNT_RATES[countryCode?.toUpperCase()] || MAX_DISCOUNT_RATES.DEFAULT,
  });
};
