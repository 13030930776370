import { isEmpty, isUnknownVintage } from '@vivino/js-web-common';
import {
  fetchFollowingWineReviews,
  fetchLatestWineReviews,
  fetchUserVintageReviews,
  fetchUserWineReviews,
  fetchWineReviews,
} from 'vivino-js/api/reviews';
import { getCurrentUser, isSignedIn } from 'vivino-js/helpers/user';

export function fetchFirstHelpfulWineReview({ vintage, options = {} }) {
  return fetchWineReviews({
    wineId: vintage.wine.id,
    vintageYear: vintage.year,
    perPage: 1,
    page: 1,
    options,
  }).then(({ reviews }) => ({ review: reviews[0] }));
}

export const getFilteredCriticReviewsByCountry = ({ reviews = [], countryCode }) =>
  (reviews || []).filter(
    (review) =>
      isEmpty(review?.wine_critic?.display_countries) ||
      review?.wine_critic?.display_countries.includes(countryCode.toLowerCase())
  );

// Values are index of that tab
export const ViewModes = {
  HELPFUL: 0,
  LATEST: 1,
  FOLLOWING: 2,
  OWN: 3,
};

export const fetchUserWineOrVintageReviews = async (vintage, params = {}) => {
  if (!isSignedIn()) {
    // treat same as no results for now
    // in the future, we may want prompt users to log in
    return { reviews: [] };
  }

  const currentUser = getCurrentUser();
  const decorateWithUser = (review) => ({
    ...review,
    // response doesn't contain user information, so decorate it with user
    // so that data structure become consistent with other end-points (HELPFUL/LATEST/FOLLOWING)
    user: { ...currentUser, alias: currentUser.short_name },
  });

  const userId = currentUser.id;

  if (isUnknownVintage(vintage)) {
    const data = await fetchUserWineReviews({
      userId,
      wineId: vintage.wine.id,
      ...params,
    });
    return {
      reviews: data.reviews.map(decorateWithUser),
    };
  }

  const data = await fetchUserVintageReviews({
    userId,
    vintageId: vintage.id,
    ...params,
  });
  return {
    reviews: data.reviews.map((review) => {
      const decoratedReview = decorateWithUser(review);
      // fetchUserVintageReviews doesn't contain vintage object, so decorate it with vintage
      // so that data structure become consistent with other end-points (HELPFUL/LATEST/FOLLOWING)
      decoratedReview.vintage = vintage;
      return decoratedReview;
    }),
  };
};

export const fetchWineReviewsByViewMode = (viewMode, vintage, params = {}) => {
  if (viewMode === ViewModes.OWN) {
    return fetchUserWineOrVintageReviews(vintage);
  } else {
    const fetchFunc = {
      [ViewModes.HELPFUL]: fetchWineReviews,
      [ViewModes.LATEST]: fetchLatestWineReviews,
      [ViewModes.FOLLOWING]: fetchFollowingWineReviews,
    }[viewMode];
    return fetchFunc({
      wineId: vintage?.wine?.id,
      vintageYear: vintage?.year,
      ...params,
    });
  }
};
