import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const Cross = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <g stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
      <path d="M18 6L6 18M6 6l12 12" />
    </g>
  </SvgIcon>
);

export default Cross;
