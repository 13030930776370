import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './bottle.scss';

const Bottle = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.11 563.46">
    <defs />
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          className={cx(styles.bottle, className)}
          d="M1.35,497.87c0-12.16.13-24.32,0-36.48-.27-21.39,0-42.79-.23-64.19-.27-24.83,0-49.67-.25-74.51-.17-15.63,0-31.27-.24-46.91-.16-9.6,0-19.2-.24-28.79-.18-7.56,0-15.12-.23-22.67C0,220,0,215.76,0,211.48A92.65,92.65,0,0,1,2.08,192.2a71.18,71.18,0,0,1,4.16-12.13,68,68,0,0,1,10.85-17,83.7,83.7,0,0,1,12.4-11.62,28.2,28.2,0,0,0,9.7-13.7,33.75,33.75,0,0,0,1.09-5.89c.65-5,1.07-10,1.36-15,.22-3.63.2-7.28.36-10.91.2-4.52.12-9,.29-13.55.13-3.28.1-6.56.17-9.84.1-5.23.12-10.47.28-15.71q.27-8.33.49-16.66c.14-4.87.35-9.75.46-14.62a7.16,7.16,0,0,0-1-4.24,4.9,4.9,0,0,1-.7-2.75c0-3.4,0-6.8.05-10.2a5.11,5.11,0,0,1,.9-3.18,4.48,4.48,0,0,0,.74-2.73c0-1.52.13-3,.21-4.55a5,5,0,0,1,1.46-3.57,3.78,3.78,0,0,0,.56-.64A6.55,6.55,0,0,1,51.18.8C53.09.63,55,.44,56.92.35,64.07,0,71.23-.12,78.39.11a80.2,80.2,0,0,1,9.9.89,5.31,5.31,0,0,1,4,2.74,10.69,10.69,0,0,1,1.35,4.93c0,1,0,1.91.18,2.87A5.75,5.75,0,0,0,95,14.64a4.47,4.47,0,0,1,.76,3c0,3.44.3,6.87.36,10.31a4.83,4.83,0,0,1-.86,2.93,7.23,7.23,0,0,0-1.31,4.53c.21,5.59.31,11.19.63,16.78.11,1.79.12,3.59.23,5.39.26,3.91.38,7.82.58,11.74.32,6.14.51,12.3.76,18.45.17,4.31.37,8.62.46,12.94.1,4.72.2,9.44.49,14.14.4,6.71,1,13.39,2,20a23,23,0,0,0,6.06,12,47.54,47.54,0,0,0,6.67,5.85,62.14,62.14,0,0,1,11.16,11,77.51,77.51,0,0,1,9.85,15.57,67.18,67.18,0,0,1,5.66,19.14c.61,4.32.9,8.67,1.25,13,.58,7.31.46,14.62.47,21.94,0,18.76-.11,37.52.06,56.28.15,17.19,0,34.39.22,51.59.26,22.95.05,45.91.26,68.87.12,13.12,0,26.23.23,39.35.17,9.4,0,18.8.24,28.19.2,9.12,0,18.24.23,27.35.17,7.44.35,14.87.49,22.31,0,2,.17,4,.16,6a45.89,45.89,0,0,1-3.59,17.87,23.44,23.44,0,0,1-1.82,3.38,10.47,10.47,0,0,1-5.72,4.31,47.88,47.88,0,0,1-8.17,1.88c-5.65.9-11.35,1.35-17.06,1.73-3.39.23-6.78.33-10.18.49-4,.2-8.07.07-12.11.22-9,.34-18.07.29-27.11.15-4.12-.06-8.24-.11-12.35-.25-6.11-.2-12.22-.58-18.29-1.31-3-.36-6-.61-8.9-1.26A24.08,24.08,0,0,1,10,558a10.64,10.64,0,0,1-4.43-4.74,27.38,27.38,0,0,1-1.66-4.62,64.78,64.78,0,0,1-2.11-9.72,59.56,59.56,0,0,1-.4-7.06q0-17,0-34Z"
        />
      </g>
    </g>
  </svg>
);
Bottle.propTypes = {
  className: PropTypes.string,
};
export default Bottle;
