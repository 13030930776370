import { useBreakpoint } from '@vivino/js-react-common-ui';
import React from 'react';

import { Col, Row } from 'vivino-ui/components/Grid';
import WineCardSkeleton, {
  WineCardSkeletonProps,
} from 'vivino-ui/components/WineCard/WineCard.skeleton';

import styles from './wineBand.skeleton.scss';

interface WineBandSkeletonProps {
  isSuperCard?: boolean;
  isWithHeaderText?: boolean;
}

const WineBandSkeleton = ({ isSuperCard, isWithHeaderText = false }: WineBandSkeletonProps) => {
  const { isMobile } = useBreakpoint();

  const wineCardSkeletonProps: WineCardSkeletonProps = {
    isSuperCard: isSuperCard,
    isLoadingReview: isSuperCard,
    isLoadingHighlight: isSuperCard,
  };

  return (
    <div className={styles.skeletons} data-testid="wineBandSkeleton">
      {isWithHeaderText && <div className={styles.wineBandHeaderPlaceholder} />}

      {isMobile ? (
        Array(2)
          .fill(null)
          .map((a, i) => <WineCardSkeleton key={i} {...wineCardSkeletonProps} />)
      ) : (
        <Row>
          {Array(4)
            .fill(null)
            .map((a, i) => (
              <Col key={i} desktop={3}>
                <WineCardSkeleton {...wineCardSkeletonProps} />
              </Col>
            ))}
        </Row>
      )}
    </div>
  );
};

export default WineBandSkeleton;
