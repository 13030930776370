import { getActiveExperimentVariant } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './layout.scss';

const Layout = (props) => {
  const { marginSmall = false, overflow = false, className = '', dataTestId = '' } = props;

  //For A/B testing of Explorer card
  const explorerCardVariant = getActiveExperimentVariant('explorer_card');
  //For A/B testing of Explorer card
  if (explorerCardVariant?.id === 'control') {
    return (
      <>
        <div
          className={cx(className, styles.outer, {
            [styles.marginSmall]: marginSmall,
            [styles.overflow]: overflow,
          })}
          data-testid={dataTestId}
        >
          <div className={cx(styles.inner, 'layout__inner--explore_card-control')}>
            {props.children}
          </div>
        </div>
        <style>
          {`
        .layout__inner--explore_card-control {
          @media (min-width: 768px) {
            width: 660px!important;
            padding: 0!important;
          }

          @media (min-width: 1024px) {
            width: 992px!important;
            padding: 0!important;
          }
          @media (min-width: 1280px) {
            width: 1216px!important;
            padding: 0!important;
          }
        }
      `}
        </style>
      </>
    );
  }

  return (
    <div
      className={cx(className, styles.outer, {
        [styles.marginSmall]: marginSmall,
        [styles.overflow]: overflow,
      })}
      data-testid={dataTestId}
    >
      <div className={styles.inner}>{props.children}</div>
    </div>
  );
};

Layout.propTypes = {
  marginSmall: PropTypes.bool,
  overflow: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  dataTestId: PropTypes.string,
};

export default Layout;
