import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { node } from 'vivino-js/commonPropTypes';
import Backdrop from 'vivino-ui/atoms/Backdrop';

import styles from './popupContainer.scss';

export const PopupVerticalAlign = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const PopupHorizontalAlign = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

const PopupContainer = ({
  children,
  popup,
  isShowPopup = false,
  onOpen,
  onClose,
  verticalAlign = PopupVerticalAlign.TOP,
  horizontalAlign = PopupHorizontalAlign.CENTER,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(isShowPopup);

  useEffect(() => {
    setIsPopupVisible(isShowPopup);
  }, [isShowPopup]);

  const openPopup = () => {
    setIsPopupVisible(true);
    onOpen?.();
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    onClose?.();
  };

  const animateProps = {
    duration: 100,
    height: isPopupVisible ? 'auto' : 0,
  };

  const popupBubble = (
    <div className={cx(styles.popupBubble, styles[verticalAlign], styles[horizontalAlign])}>
      <div className={styles.popupWrapper}>
        <AnimateHeight {...animateProps}>{popup}</AnimateHeight>
      </div>
      <AnimateHeight {...animateProps}>
        <div className={styles.popupArrow}></div>
      </AnimateHeight>
    </div>
  );

  return (
    <>
      {isPopupVisible && <Backdrop onClose={closePopup} />}
      <div className={styles.container}>
        {verticalAlign === PopupVerticalAlign.TOP && popupBubble}
        <div onClick={openPopup}>{children}</div>
        {verticalAlign === PopupVerticalAlign.BOTTOM && popupBubble}
      </div>
    </>
  );
};

PopupContainer.propTypes = {
  children: node.isRequired,
  popup: node.isRequired,
  isShowPopup: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  verticalAlign: PropTypes.oneOf(Object.values(PopupVerticalAlign)),
  horizontalAlign: PropTypes.oneOf(Object.values(PopupHorizontalAlign)),
};

export default PopupContainer;
