import { getCurrentLocale } from '@vivino/js-web-common';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export const KnowledgeBaseUrls = {
  MerchantSupport: 'https://help.vivino.com/s/merchant/',
  TemperatureControlledShipping:
    'https://help.vivino.com/s/article/What-is-temperature-controlled-shipping',
  SupportForFulfilment:
    'https://help.vivino.com/s/article/What-do-I-do-if-I-don-t-have-inventory-to-fulfill-an-order',
  CheckoutFAQ: '/terms-of-sale',
  How2AddVivinoRatings:
    'https://help.vivino.com/s/article/How-can-I-have-Vivino-Ratings-appear-on-my-website',
};

/**
 * Centralize all knowledge base links to make switch knowledge base service easier
 * making this a function instead of constants so that
 * URL can be updated programatically (Salesforce requires passing locale as query param)
 **/
export const getKnowledgeBaseFullUrl = (baseUrl) => {
  const locale = getCurrentLocale();
  const params = locale ? [{ name: 'language', value: locale }] : null;
  return generateQueryUrl({ baseUrl, params });
};
