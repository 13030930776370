/* eslint-disable promise/no-nesting */
import { isDevelopment, isPrDeploy } from '@helpers/env';
import { captureException, setExtraContext } from '@helpers/errorTracking';
import { getCurrentLocale } from '@helpers/locale';
import { getUrlSearchParams } from '@helpers/urlSearchParams';
import { csrfToken } from './authentication';

import { APIError, AddressVerificationError } from './errors';

export const METHOD_GET = 'GET';
export const METHOD_PATCH = 'PATCH';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_DELETE = 'DELETE';

export const NO_CONTENT = 204;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const UNPROCESSABLE_ENTITY = 422;
export const INTERNAL_SERVER_ERROR = 500;
export const BAD_GATEWAY = 502;
export const SERVICE_UNAVAILABLE = 503;

export const ALLOWED_METHODS = [METHOD_GET, METHOD_POST, METHOD_PUT, METHOD_PATCH, METHOD_DELETE];

export const CONTENT_TYPE_JSON = 'application/json';
export const CONTENT_TYPE_FORM = 'multipart/form-data';

type HttpMethod =
  | typeof METHOD_GET
  | typeof METHOD_POST
  | typeof METHOD_PUT
  | typeof METHOD_PATCH
  | typeof METHOD_DELETE;

interface ApiFetchArgs {
  url: string;
  method?: HttpMethod;
  body?: any;
  contentType?: typeof CONTENT_TYPE_FORM | typeof CONTENT_TYPE_JSON;
  options?: any;
}

const containsHttp = (url) => url.includes('http');

function apiFetch<T>({
  url,
  method = METHOD_GET,
  body = undefined,
  contentType = CONTENT_TYPE_JSON,
  options = {},
}: ApiFetchArgs): Promise<T> {
  if (globalThis.__WEB_API_HOST__ && !containsHttp(url)) {
    url = `${globalThis.__WEB_API_HOST__}${url}`;
  }

  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: CONTENT_TYPE_JSON,
    'Content-Type': contentType,
  };

  if (method !== METHOD_GET) {
    headers['X-CSRF-Token'] = csrfToken();
  }

  const init = {
    ...options,
    method: ALLOWED_METHODS.includes(method) ? method : METHOD_GET,
    headers,
    cache: 'default',
    // With credentials='same-origin', cookie won't be sent if host or port is different
    // see https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
    // For NextJS, we must relax this to 'include' in the following cases
    // - Development: different port (localhost:3005 to localhost:3000)
    // - PR deploy: different host (prXX-js-web.testing.vivino.com to testing.vivino.com)
    credentials: isDevelopment() || isPrDeploy() ? 'include' : 'same-origin',
  };

  let formData;
  switch (contentType) {
    case CONTENT_TYPE_FORM:
      formData = new FormData();
      Object.keys(body).forEach((k) => {
        formData.append(k, body[k]);
      });
      init['body'] = formData;
      break;
    case CONTENT_TYPE_JSON:
    default:
      init['body'] = JSON.stringify(body);
      break;
  }

  return new Promise((resolve, reject) => {
    fetch(url, init)
      .then((response) => {
        if (response.ok) {
          if (response.status === NO_CONTENT) {
            return resolve(null);
          }
          return resolve(response.json());
        }

        switch (response.status) {
          case UNAUTHORIZED:
          case FORBIDDEN:
          case NOT_FOUND:
          case INTERNAL_SERVER_ERROR:
          case BAD_GATEWAY:
          case SERVICE_UNAVAILABLE:
            setExtraContext({ url, requestBody: body });
            return response
              .json()
              .then((json) => {
                // extract the error message from our API JSON response if there is any
                return reject(
                  new APIError(
                    json?.error || `${response.status} ${response.statusText}`,
                    response.status,
                    json?.error_code
                  )
                );
              })
              .catch(() => {
                // this happens when the body is empty and has no error json
                return reject(
                  new APIError(`${response.status} ${response.statusText}`, response.status, null)
                );
              });

          case UNPROCESSABLE_ENTITY:
            // eslint-disable-next-line promise/no-nesting
            return response.json().then((json) => {
              // we are using Unprocessable Entity for server-side address validation, extract the JSON validation from the API response
              reject(new AddressVerificationError(json.error, response.status));
            });

          default:
            setExtraContext({ url, requestBody: body });

            return response.json().then((json) => {
              // extract the error message from our API JSON response
              reject(new APIError(json.error, response.status, json.error_code));
            });
        }
      })
      .catch((error) => {
        // fetch rejects with a TypeError when a network error is encountered - we don't want to capture these
        if (error instanceof TypeError) {
          error.message = 'There was a problem connecting to Vivino';
          reject(error);
          return;
        }

        // handle aborted requests (https://developers.google.com/web/updates/2017/09/abortable-fetch#reacting_to_an_aborted_fetch)
        if (error.name === 'AbortError') {
          reject(error);
          return;
        }

        captureException(error, { url, body });
        reject(error);
      });
  });
}

const PARAM_LANG = 'language';

export const apiGet = <T>({ url, ...restArgs }: Omit<ApiFetchArgs, 'body'>) => {
  /**
   * Make sure to pass language parameter for all GET requests
   * to avoid relying on cookies for language settings (won't work with Google crawlers)
   * See https://tickets.vivino.com/issues/45307 for more details
   */
  const [originWithPath, searchStr] = url.split('?');
  const locale = getCurrentLocale();
  if (!locale) {
    return apiFetch<T>({ url, ...restArgs });
  }

  if (searchStr) {
    const searchParams = getUrlSearchParams(searchStr);
    // not using `searchParams.has` because that will return true even if value is empty
    if (!searchParams.get(PARAM_LANG)) {
      searchParams.set(PARAM_LANG, locale);
      url = `${originWithPath}?${searchParams.toString()}`;
    }
  } else {
    url = `${originWithPath}?${PARAM_LANG}=${locale}`;
  }

  return apiFetch<T>({ url, ...restArgs });
};

export const apiPostForm = <T>({ url, body }: Pick<ApiFetchArgs, 'url' | 'body'>) =>
  apiFetch<T>({
    url,
    body,
    method: METHOD_POST,
    contentType: CONTENT_TYPE_FORM,
  });

export const apiPut = <T>({ url, body = undefined }: Pick<ApiFetchArgs, 'url' | 'body'>) =>
  apiFetch<T>({ url, body, method: METHOD_PUT });

export const apiPatch = <T>({ url, body = undefined }: Pick<ApiFetchArgs, 'url' | 'body'>) =>
  apiFetch<T>({ url, body, method: METHOD_PATCH });

export const apiPost = <T>({ url, body = undefined }: Pick<ApiFetchArgs, 'url' | 'body'>) =>
  apiFetch<T>({ url, body, method: METHOD_POST });

export const apiDelete = <T>({ url, body = undefined }: Pick<ApiFetchArgs, 'url' | 'body'>) =>
  apiFetch<T>({ url, body, method: METHOD_DELETE });
