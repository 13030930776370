import { useMemo, useState } from 'react';
import { insertStringAtIndex } from 'vivino-js/helpers/text';

const USER_INPUT_CHARACTER = 'X';

const applyMaskToText = (
  text: string,
  uniqueMaskCharactersRegex: RegExp,
  maskCharacterIndexes: number[],
  mask: string
): string => {
  // Remove each separator
  const unmaskedInput = text.replace(uniqueMaskCharactersRegex, '');

  // Apply each separator at the correct position.
  return maskCharacterIndexes.reduce((memo, maskCharIndex) => {
    if (memo.length >= maskCharIndex) {
      return insertStringAtIndex(memo, mask[maskCharIndex], maskCharIndex);
    }
    return memo;
  }, unmaskedInput);
};

const getMaskRegexAndIndexes = (
  mask: string
): { uniqueMaskCharactersRegex: RegExp; maskCharacterIndexes: number[] } => {
  if (mask) {
    const uniqueMaskCharacters: string[] = [];
    const maskCharacterIndexes: number[] = [];

    mask.split('').forEach((char, index) => {
      if (char !== USER_INPUT_CHARACTER) {
        if (!uniqueMaskCharacters.includes(`\\${char}`)) {
          uniqueMaskCharacters.push(`\\${char}`);
        }
        maskCharacterIndexes.push(index);
      }
    });

    const uniqueMaskCharactersRegex = new RegExp(`(${uniqueMaskCharacters.join('|')})`, 'g');

    return { uniqueMaskCharactersRegex, maskCharacterIndexes };
  }

  return { uniqueMaskCharactersRegex: new RegExp(''), maskCharacterIndexes: [] };
};

// Mask is a string with X's and other characters, the X's mean any character the other characters
// are automatically added e.g. "XXX-XXX-(XX).XX"
function useMaskedCallback({ onChange, mask }: { onChange: Function; mask: string }) {
  const [inputValue, setInputValue] = useState('');
  const { uniqueMaskCharactersRegex, maskCharacterIndexes } = useMemo(
    () => getMaskRegexAndIndexes(mask),
    [mask]
  );

  return (input: string) => {
    let newValue = input;
    if (mask && maskCharacterIndexes?.length > 0 && input.length > 0) {
      const currentLength = input.length;
      const previousLength = inputValue.length;
      const isDeleting = previousLength > currentLength;
      const currentMaskCharacter = mask[currentLength - 1];

      if (isDeleting) {
        if (currentMaskCharacter && currentMaskCharacter !== USER_INPUT_CHARACTER) {
          // If user is deleting from 123.4 to 123. it also removes the dot
          newValue = input.slice(0, currentLength - 1);
        }
      } else {
        newValue = applyMaskToText(input, uniqueMaskCharactersRegex, maskCharacterIndexes, mask);
      }
    }

    setInputValue(newValue);
    onChange(newValue);
  };
}

export default useMaskedCallback;
