import { getCurrentLocale } from '@vivino/js-web-common';
import t from 'vivino-js/translationString';

const TRANSLATIONS_PATH = 'components.shared.vivino_rating';

const TRANSLATIONS = {
  basedOnAllVintages: `${TRANSLATIONS_PATH}.based_on_all_vintages`,
  countRatings: `${TRANSLATIONS_PATH}.count_ratings`,
  notEnoughRatings: `${TRANSLATIONS_PATH}.not_enough_ratings`,
};

export const VivinoRatingType = {
  Vintage: 'vintage',
  Wine: 'wine',
};

export const RatingThresholds = {
  RARE: 4.2,
  GOOD: 3.8,
  COMMON: 3.6,
};

export const RatingsRange = {
  MIN: 1,
  DEFAULT: RatingThresholds.GOOD, // Good Stuff
  MAX: 5,
};

export const showRatingFor = ({ vintage }) => {
  const vintageWine = vintage?.wine;

  if (!vintage?.has_valid_ratings && vintageWine) {
    return [VivinoRatingType.Wine, vintageWine];
  }

  return [VivinoRatingType.Vintage, vintage];
};

export const getRatingText = ({ ratingType, count }) => {
  if (ratingType === VivinoRatingType.Vintage) {
    // vintage has enough ratings by itself
    return t(TRANSLATIONS.countRatings, { count });
  }

  // falling back to wine ratings
  return t(TRANSLATIONS.basedOnAllVintages);
};

export const getNotEnoughRatingsText = () => {
  return t(TRANSLATIONS.notEnoughRatings);
};

export const getAverageAndCount = (statistics) => [
  statistics?.ratings_average || 0.0,
  statistics?.ratings_count || 0,
];

export const formatRating = (rating) => {
  if (!rating) {
    return '-';
  }

  return Intl.NumberFormat(getCurrentLocale(), {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(rating);
};

// fallback - if fallback is true it uses the wine ratings
// when the vintage rating is below threshold
export const getRatingForVintage = ({ vintage, fallback = true }) => {
  let ratingsAvg;

  if (vintage.has_valid_ratings) {
    ratingsAvg = vintage?.statistics?.ratings_average;
  } else {
    if (fallback && vintage?.wine?.has_valid_ratings) {
      ratingsAvg = vintage?.wine?.statistics?.ratings_average;
    }
  }

  return ratingsAvg || 0.0;
};
