import { ComponentSize, FormSize } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import React, { ChangeEventHandler, Fragment, MutableRefObject, useEffect, useState } from 'react';

import styles from './Input.scss';

export interface InputProps {
  autoComplete?: string;
  ariaLabel?: string;
  dataTestId?: string;
  defaultValue?: string | number;
  name?: string;
  errorMessage?: string;
  errorMessageClassName?: string;
  type?: string;
  id?: string;
  inputRef?: MutableRefObject<HTMLInputElement>;
  value?: string;
  maxLength?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  required?: boolean;
  onChange?: (value: string, { isValid }: { isValid: boolean }) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  size?: FormSize;
  // Introducing component sizes (applied only to FormSizes.Large)
  // Base keeps the component fonts / spaces fixed for all devices, Large scales it respectively
  componentSize?: ComponentSize;

  // Placeholder should not be used with FormSizes.Large
  placeholder?: string;

  // Currently these values are only supported for FormSizes.Large
  // FormSize.Medium is a more simply styled element whereas FormSizes.Large is a newer larger
  // design that is styled more and the label can fit inside the element.
  // The label also takes the role of the placeholder for FormSizes.Large
  // These attributes will not be supported before it makes sense in a design for a medium
  // sized input to include them
  label?: string;
  isInvalid?: boolean;
  optionalLabel?: string;
  // This is a number, which when changed value will
  // cause the input field to animate green for a short while
  successUpdater?: number;
}

const Input = ({
  autoComplete,
  className,
  defaultValue,
  errorMessageClassName,
  id,
  errorMessage,
  placeholder,
  dataTestId,
  value = '',
  maxLength,
  name,
  optionalLabel,
  label,
  type = 'text',
  isInvalid,
  disabled = false,
  required = false,
  onBlur,
  size = FormSize.Medium,
  componentSize = ComponentSize.Large,
  onChange,
  onFocus,
  autoFocus,
  ariaLabel,
  successUpdater,
  inputRef,
}: InputProps) => {
  const [successClass, setSuccessClass] = useState('');

  // It's necessary to keep the value in the state to be able to minimize the label/placeholder
  const [valueState, setValueState] = useState(defaultValue || value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const handleChange: ChangeEventHandler = (e) => {
    const { value, validity } = e.currentTarget as HTMLInputElement;
    setValueState(value);
    onChange?.(value, { isValid: validity.valid });
  };

  useEffect(() => {
    if (successUpdater) {
      // Apply the success animation for the field when successUpdater has increased
      setSuccessClass('success');
      // Remove the class again to prepare for another update
      setTimeout(() => setSuccessClass(''), 400);
    }
  }, [successUpdater]);

  const inputElement = (
    <input
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      aria-label={ariaLabel}
      className={cx(styles.input, className, styles[size])}
      data-testid={dataTestId || id}
      disabled={!!disabled}
      id={id}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={valueState}
      placeholder={placeholder}
      ref={inputRef}
      required={!!required}
      type={type}
    />
  );

  return (
    <Fragment>
      {size === FormSize.Large && (
        <div
          className={cx(styles.formFieldGroup, styles[successClass], styles[componentSize], {
            [styles.error]: isInvalid,
            [styles.disabled]: disabled,
          })}
        >
          {inputElement}
          {label && (
            <label
              htmlFor={id}
              className={cx(styles.label, {
                [styles.minimizedLabel]: !!valueState,
              })}
            >
              {label}
            </label>
          )}
          {!required && optionalLabel && <div className={styles.optional}>{optionalLabel}</div>}
        </div>
      )}
      {size === FormSize.Medium && inputElement}
      {errorMessage && (
        <span role="status" className={cx(styles.errorMessage, errorMessageClassName)}>
          {errorMessage}
        </span>
      )}
    </Fragment>
  );
};

export default Input;
