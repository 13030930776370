import * as Sentry from '@sentry/react';
import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactElement;
}

export function ErrorBoundary({ children, fallback }: ErrorBoundaryProps) {
  return <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>;
}
