import * as Sentry from '@sentry/browser';

const TRANSACTION_ID_REGEX = /\W*\[TxID:\w+\]/i;

const flattenExtras = (value) => (typeof value === 'object' ? JSON.stringify(value) : value);

export function setExtraContext(extra) {
  const scope = Sentry.getCurrentScope();

  if (typeof extra === 'object') {
    Object.keys(extra).forEach((key) => {
      scope.setExtra(key, flattenExtras(extra[key]));
    });
  }

  scope.setExtra('extra', extra);
}

export function captureException(error, extra = undefined) {
  extra && setExtraContext(extra);

  // Sometimes the thrown exceptions or rejected promises are not error objects but plain strings.
  // This needs to be handled.
  if (typeof error === 'string') {
    Sentry.captureMessage(error, 'error');
  } else if (error instanceof Error) {
    Sentry.captureException(error);
  } else {
    setExtraContext(error);
    Sentry.captureMessage('captureException', 'error');
  }
}

export function captureMessage(message) {
  const scope = Sentry.getCurrentScope();
  scope.setLevel('info');

  message &&
    scope.setFingerprint([message.replace(TRANSACTION_ID_REGEX, '').trim().replace(/\s+/g, '_')]);

  Sentry.captureMessage(message);
}
