import { AddressFormat, BasicAddress, FullAddress, Prefill } from '@vivino/js-web-common';
import { AddressFormType } from '@webtypes/address';
import React, { ReactElement, createContext, useReducer, useState } from 'react';
import { fetchAddressFormat, fetchAddressUserPrefill } from 'vivino-js/api/address';
import { AddressFormTypes } from 'vivino-js/apiPropTypes';
import { isSignedIn } from 'vivino-js/helpers/user';
import t from 'vivino-js/translationString';

import { AddressError } from 'vivino-ui/components/AddressForm/components/AddressFormatField';

import addressContextReducer, {
  AddressActionTypes,
  UpdateAddressReducerAction,
  UpdateCurrentAddressReducerAction,
  UpdateErrorAddressReducerAction,
} from './addressContextReducer';
import getCurrentAddressFromPrefill from './getCurrentAddressFromPrefill';

const TRANSLATIONS = {
  invalidField: 'checkout.address_form.invalid_field',
};

export const AddressContext = createContext({
  addressFormatByCountry: {},
  addressUserPrefillByCountry: {},
  currentAddress: {},
  errorsByFormType: {},
  fetchOrUpdateAddressByCountry: undefined,
  updateAddresses: undefined,
  updateCurrentAddressFields: undefined,
  updateErrorFieldsByFormType: undefined,
  isBillingSameAsShipping: true,
  setIsBillingSameAsShipping: undefined,
  getFieldErrorsForEmptyRequiredFields: undefined,
  currentShippingCountryCode: undefined,
  prefillType: undefined,
  setPrefillType: undefined,
});

export type AddressFormatByCountry = Record<string, AddressFormat> | {};
export type AddressUserPrefillByCountry = Record<string, Record<AddressFormType, FullAddress>> | {};
export type CurrentAddress = Record<AddressFormType, FullAddress> | {};
export type ErrorsByFormType = Record<AddressFormType, AddressError> | {};

interface AddressContextProviderProps {
  addressFormatByCountry?: AddressFormatByCountry;
  addressUserPrefillByCountry?: AddressUserPrefillByCountry;
  currentAddress?: CurrentAddress;
  errorsByFormType?: ErrorsByFormType;
  isBillingSameAsShipping?: boolean;
  children: ReactElement;
}

export const AddressContextProvider = ({
  addressFormatByCountry = {},
  addressUserPrefillByCountry = {},
  currentAddress = {},
  errorsByFormType = {},
  isBillingSameAsShipping = true,
  children,
}: AddressContextProviderProps) => {
  const [state, dispatch] = useReducer(addressContextReducer, {
    addressFormatByCountry,
    addressUserPrefillByCountry,
    currentAddress,
    errorsByFormType,
  });
  const [isBillingSameAsShippingState, setIsBillingSameAsShipping] =
    useState(isBillingSameAsShipping);
  const [prefillTypeState, setPrefillTypeState] = useState(PREFILL_TYPES.NONE);

  // addressFormType, should be either shipping or billing
  const updateCurrentAddressFields = ({
    addressFormType,
    updateFields = {},
  }: Omit<UpdateCurrentAddressReducerAction, 'type'>) => {
    // don't update state unless value has changed
    // otherwise it will cause "Can't perform a React state update on an unmounted component" error
    const currentAddressForType = state.currentAddress?.[addressFormType];

    if (updateFields['state']) {
      /**
       * Our state dropdown has lower case keys so an upper case prefill will break selection
       */
      updateFields['state'] = updateFields['state'].toLowerCase();
    }

    const isChanged = Object.entries(updateFields).some(
      ([field, value]) => currentAddressForType?.[field] !== value
    );
    if (!isChanged) {
      return;
    }

    dispatch({
      type: AddressActionTypes.UPDATE_CURRENT_ADDRESS_FIELDS,
      addressFormType,
      updateFields,
    });
  };

  // addressFormType, should be either shipping or billing
  const updateErrorFieldsByFormType = ({
    addressFormType,
    updateFields,
  }: Omit<UpdateErrorAddressReducerAction, 'type'>) => {
    // don't update state unless value has changed
    // otherwise it will cause "Can't perform a React state update on an unmounted component" error
    const errorFieldsForType = state.errorsByFormType?.[addressFormType];
    const isChanged = Object.entries(updateFields).some(
      ([field, error]) =>
        errorFieldsForType?.[field]?.fieldValue !== error?.fieldValue ||
        errorFieldsForType?.[field]?.message !== error?.message
    );
    if (!isChanged) {
      return;
    }

    dispatch({
      type: AddressActionTypes.UPDATE_ERROR_FIELDS,
      addressFormType,
      updateFields,
    });
  };

  /**
   * Updates `addressFormat`, `addressUserPrefill` for the specified `countryCode`
   * as well as `currentAddress` all at once
   */
  const updateAddresses = ({
    countryCode,
    addressFormat,
    addressUserPrefill,
    currentAddress,
  }: Omit<UpdateAddressReducerAction, 'type'>) => {
    dispatch({
      type: AddressActionTypes.UPDATE,
      countryCode,
      addressFormat,
      addressUserPrefill,
      currentAddress,
    });
  };

  const fetchOrUpdateAddressByCountry = async ({
    countryCode,
    addressFormType,
    preloadedAddressFormat = null,
    preloadedAddressUserPrefill = null,
    shouldFetchUserPrefill = isSignedIn(),
  }) => {
    countryCode = countryCode.toLowerCase();
    const addressFormTypes = addressFormType ? [addressFormType] : Object.values(AddressFormTypes);
    const action: Partial<UpdateAddressReducerAction> = {};

    /**
     * Most of the time when we initialise the context, we will already have preloaded the
     * format.
     */
    if (preloadedAddressFormat) {
      action.addressFormat = preloadedAddressFormat;
    }

    /**
     * The address format will be missing if the user changes the country in the form
     * dynamically - i.e. if in the billing form a new country is selected. Otherwise,
     * the format should be passed in from the Checkout page and we don't need to fetch
     * it again.
     */
    const shouldFetchAddressFormat =
      !state.addressFormatByCountry?.[countryCode] && !action.addressFormat;

    /**
     * If the user has a prefill, it will most likely be passed from the Checkout page.
     * However, there is an edge case - when a merchant ships to multiple countries and
     * a cart is created in the country the user does not have a prefill in (NE & BE). In this
     * case we need to dynamically fetch the prefill when the user changes the country in
     * shipping.
     */
    const addressUserPrefill = state.addressUserPrefillByCountry?.[countryCode];

    /**
     * Only fetch the user prefill if we have a logged in user or if the prefill has not
     * been fetched or applied yet!
     */
    const shouldLoadAddressUserPrefill =
      shouldFetchUserPrefill &&
      (!addressUserPrefill ||
        addressFormTypes.some((addressFormType) => {
          return Object.keys(addressUserPrefill?.[addressFormType] || {}).length === 0;
        }));

    if (shouldFetchAddressFormat) {
      const { address_format } = await fetchAddressFormat({ countryCode });
      action.addressFormat = address_format;
    }

    if (shouldLoadAddressUserPrefill) {
      /**
       * Most of the time, if the user has a prefill we initialise the context, we will already have
       * preloaded the user prefill.
       */
      let userPrefill = preloadedAddressUserPrefill;

      try {
        if (!userPrefill) {
          const { address_user_prefill } = await fetchAddressUserPrefill({ countryCode });
          userPrefill = address_user_prefill;
        }

        action.addressUserPrefill = userPrefill;
      } catch {
        // In case of error, initialise the current address with only
        // the country to avoid issues with the dropdown set properly
        // (done inside getCurrentAddressFromPrefill)
      }

      action.currentAddress = getCurrentAddressFromPrefill({
        countryCode,
        addressFormTypes,
        addressUserPrefill: userPrefill,
        currentAddress: state.currentAddress,
      });
    } else {
      const addressFormTypesToUpdate = addressFormTypes.filter(
        (addressFormType) => state.currentAddress?.[addressFormType]?.country !== countryCode
      );
      if (addressFormTypesToUpdate.length > 0) {
        const newCurrentAddress = getCurrentAddressFromPrefill({
          countryCode,
          addressFormTypes: addressFormTypesToUpdate,
          addressUserPrefill,
          currentAddress: state.currentAddress,
        });

        // don't update state unless value has changed
        // otherwise it will cause "Can't perform a React state update on an unmounted component" error
        if (JSON.stringify(state.currentAddress) !== JSON.stringify(newCurrentAddress)) {
          action.currentAddress = newCurrentAddress;
        }
      }
    }

    if (Object.keys(action).length > 0) {
      updateAddresses({ countryCode, ...(action as UpdateAddressReducerAction) });
    }
  };

  const getFieldErrorsForEmptyRequiredFields = (addressFormType: AddressFormType) => {
    const currentAddressForType = state.currentAddress?.[addressFormType];
    if (!currentAddressForType) {
      return {};
    }

    const countryCode = currentAddressForType.country;
    const addressFormat = state.addressFormatByCountry?.[countryCode];
    const displayFieldGroups = addressFormat?.display_fields?.[addressFormType].groups;

    if (!displayFieldGroups) {
      return {};
    }

    return displayFieldGroups.reduce((memo, group) => {
      group.fields.forEach((field) => {
        const addressFormatField = addressFormat.fields[field];
        if (addressFormatField.required && !currentAddressForType[field]) {
          memo[field] = {
            fieldValue: currentAddressForType[field],
            message: t(TRANSLATIONS.invalidField, { field_label: addressFormatField.title }),
          };
        }
      });

      return memo;
    }, {});
  };

  const currentShippingCountryCode =
    state.currentAddress?.[AddressFormTypes.Shipping]?.country?.toUpperCase();

  return (
    <AddressContext.Provider
      value={{
        addressFormatByCountry: state.addressFormatByCountry,
        addressUserPrefillByCountry: state.addressUserPrefillByCountry,
        currentAddress: state.currentAddress,
        errorsByFormType: state.errorsByFormType,
        updateAddresses,
        fetchOrUpdateAddressByCountry,
        updateCurrentAddressFields,
        updateErrorFieldsByFormType,
        getFieldErrorsForEmptyRequiredFields,
        isBillingSameAsShipping: isBillingSameAsShippingState,
        setIsBillingSameAsShipping,
        currentShippingCountryCode,
        prefillType: prefillTypeState,
        setPrefillType: setPrefillTypeState,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export const AddressContextConsumer = AddressContext.Consumer;

interface IsAddressValidArgs {
  addressFormType: AddressFormType;
  address: FullAddress | BasicAddress;
  addressFormat: AddressFormat;
}

export const isAddressValid = ({ addressFormType, address, addressFormat }: IsAddressValidArgs) => {
  if (!address) {
    return false;
  }

  const displayFieldGroups = addressFormat?.display_fields?.[addressFormType]?.groups;
  if (!displayFieldGroups) {
    return false;
  }
  return displayFieldGroups.every((group) => {
    return group.fields.every((fieldName) => {
      if (!addressFormat.fields[fieldName].required) {
        return true;
      }

      return address[fieldName];
    });
  });
};

interface IsCurrentAddressValidArgs {
  addressFormat: AddressFormat;
  addressFormType: AddressFormType;
  currentAddress: Record<AddressFormType, FullAddress> | {};
}

export const isCurrentAddressValid = ({
  addressFormType,
  currentAddress,
  addressFormat,
}: IsCurrentAddressValidArgs) => {
  const address = currentAddress?.[addressFormType];
  return isAddressValid({ addressFormType, address, addressFormat });
};

export const PREFILL_TYPES = {
  NONE: 'None',
  PARTIAL_SHIPPING_ONLY: 'Partial-Shipping-Only',
  FULL: 'Full',
};

interface GetPrefillTypeArgs {
  addressFormat: AddressFormat;
  addressUserPrefill: Record<AddressFormType, Prefill>;
  currentAddress: Record<AddressFormType, FullAddress> | {};
}

export const getPrefillType = ({
  addressFormat,
  addressUserPrefill,
  currentAddress,
}: GetPrefillTypeArgs) => {
  const isShippingAddressValid = isCurrentAddressValid({
    addressFormType: AddressFormTypes.Shipping,
    addressFormat,
    currentAddress,
  } as IsCurrentAddressValidArgs);

  if (isShippingAddressValid) {
    const isBillingAddressValid = isCurrentAddressValid({
      addressFormType: AddressFormTypes.Billing,
      addressFormat,
      currentAddress,
    } as IsCurrentAddressValidArgs);
    const hasPrefilledPaymentInformation =
      addressUserPrefill?.billing?.customer?.stripe_customer_id;

    return isBillingAddressValid && hasPrefilledPaymentInformation
      ? PREFILL_TYPES.FULL
      : PREFILL_TYPES.PARTIAL_SHIPPING_ONLY;
  }
  return PREFILL_TYPES.NONE;
};
