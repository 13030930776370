import { isMatch } from './text';
import { getCurrentCountryCode } from './countryHelpers';

export enum LanguageCode {
  English = 'en',
  French = 'fr',
  Chinese = 'zh',
  PortuguesePt = 'pt-PT',
}

export function getCurrentLocale(): string {
  return globalThis.__PRELOADED_LOCALE__ || LanguageCode.English;
}

export function getCurrentLocaleWithRegion(): string {
  const locale = getCurrentLocale();
  if (locale.includes('-')) {
    return locale;
  } else {
    return `${locale?.toLowerCase()}-${getCurrentCountryCode()?.toUpperCase()}`;
  }
}

export function isEnglish(): boolean {
  return isMatch(getCurrentLocale(), LanguageCode.English);
}

export function isFrench(): boolean {
  return isMatch(getCurrentLocale(), LanguageCode.French);
}

export function isChinese(): boolean {
  return isMatch(getCurrentLocale(), LanguageCode.Chinese);
}
export function isPortuguesePt(): boolean {
  return isMatch(getCurrentLocale(), LanguageCode.PortuguesePt);
}
