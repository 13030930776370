import { Alert, AlertTheme } from '@vivino/js-react-common-ui';
import { CustomContentProps, SnackbarProvider as Provider } from 'notistack';
import React, { forwardRef } from 'react';

interface AlertProp extends CustomContentProps {
  onClose?: () => void;
}

const AlertDefault = forwardRef<HTMLDivElement, AlertProp>(
  ({ style, message, action, onClose }, ref) => (
    <div style={style} ref={ref}>
      <Alert action={action} onClose={onClose} theme={AlertTheme.Default}>
        {message}
      </Alert>
    </div>
  )
);
const AlertSuccess = forwardRef<HTMLDivElement, AlertProp>(
  ({ style, message, action, onClose }, ref) => (
    <div style={style} ref={ref}>
      <Alert action={action} onClose={onClose} theme={AlertTheme.Positive}>
        {message}
      </Alert>
    </div>
  )
);
const AlertError = forwardRef<HTMLDivElement, AlertProp>(
  ({ style, message, action, onClose }, ref) => (
    <div style={style} ref={ref}>
      <Alert action={action} onClose={onClose} theme={AlertTheme.Critical}>
        {message}
      </Alert>
    </div>
  )
);
const AlertInfo = forwardRef<HTMLDivElement, AlertProp>(
  ({ style, message, action, onClose }, ref) => (
    <div style={style} ref={ref}>
      <Alert action={action} onClose={onClose} theme={AlertTheme.Info}>
        {message}
      </Alert>
    </div>
  )
);
const AlertWarning = forwardRef<HTMLDivElement, AlertProp>(
  ({ style, message, action, onClose }, ref) => (
    <div style={style} ref={ref}>
      <Alert action={action} onClose={onClose} theme={AlertTheme.Warning}>
        {message}
      </Alert>
    </div>
  )
);

interface SnackBarProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider = ({ children }: SnackBarProviderProps) => (
  <Provider
    preventDuplicate
    autoHideDuration={6000}
    Components={{
      default: AlertDefault,
      error: AlertError,
      info: AlertInfo,
      success: AlertSuccess,
      warning: AlertWarning,
    }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    {children}
  </Provider>
);
export default SnackBarProvider;
