import { BasicRegion } from '@vivino/js-web-common';
import React from 'react';

interface GoogleMapsProps {
  apiKey?: string;
  className?: string;
  latitude: number;
  longitude: number;
  region: BasicRegion;
  language: string;
  height?: number;
  zoom?: number;
}

const regionQuery = ({ region, zoom }) => {
  return `q=${region.name.split(' ').join('+')},${region?.country?.name
    ?.split(' ')
    ?.join('+')}&zoom=${zoom}`;
};

const googleApiLink = ({ apiKey, latitude, longitude, region, language, zoom }) => {
  const q =
    latitude && longitude
      ? `q=${latitude},${longitude}&zoom=${zoom}`
      : regionQuery({ region, zoom });

  return `https://www.google.com/maps/embed/v1/place?${q}&key=${apiKey}&language=${language}`;
};

export const googleMapsLink = ({ latitude, longitude, region, zoom }) => {
  const q =
    latitude && longitude
      ? `q=${latitude},${longitude}&zoom=${zoom}`
      : regionQuery({ region, zoom });

  return `https://maps.google.com/?${q}`;
};

export default function GoogleMaps({
  apiKey = global.__GOOGLE_MAPS_API_KEY__,
  className,
  latitude,
  longitude,
  region,
  language,
  height = 340,
  zoom = 5,
}: GoogleMapsProps) {
  return (
    <iframe
      width="100%"
      height={height}
      className={className}
      style={{ border: 0 }}
      allowFullScreen={false}
      src={googleApiLink({ apiKey, latitude, longitude, region, language, zoom })}
      data-testid="googleMaps"
    />
  );
}
