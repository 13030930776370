import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import cx from 'classnames';
import React from 'react';

import styles from './vfmbadge.module.scss';

const VFMBadge = ({
  text,
  position,
  labelType,
}: {
  text: string;
  position: number;
  labelType?: TypographyType;
}) => {
  if (!labelType) {
    labelType = TypographyType.LabelLarge;
  }
  function getBadgeTheme(position) {
    if (position >= 0.95) {
      return cx(styles.amazingValue);
    } else if (position === 0.75) {
      return cx(styles.greatValue);
    } else if (position === 0.55) {
      return cx(styles.goodValue);
    } else if (position === 0.35) {
      return cx(styles.fairValue);
    } else if (position === 0.15) {
      return cx(styles.betterValue);
    } else {
      return cx(styles.noValue);
    }
  }

  return (
    <div className={cx(styles.badge, getBadgeTheme(position))}>
      <Typography type={labelType}>{text}</Typography>
    </div>
  );
};

export default VFMBadge;
